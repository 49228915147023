import axios, { AxiosInstance } from "axios";

export const unauthClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-type': 'application/json' },
});

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-type': 'application/json' },
});

const exportClient = axios.create({
    headers: { 'Content-type': 'application/json' },
});

const referentialClient = axios.create({
    headers: { 'Content-type': 'application/json' },
})

export type ClientHandler = {
    client: AxiosInstance;
    exportClient: AxiosInstance;
    referentialClient: AxiosInstance;
}

export const clientHandler: ClientHandler = {
    client,
    exportClient,
    referentialClient,
}