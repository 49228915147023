import React from 'react';

import Tick from 'assets/svg/Tick';
import PartialChecked from 'assets/svg/PartialChecked';

import Container from './style';

export enum State {
	Unchecked,
	Checked,
	PartiallyChecked,
}

export type Props = {
	state: State;
	disabled?: boolean;
	onClick?: () => unknown;
};

const buildStateWidget = (state: State): JSX.Element => {
	switch (state) {
		case State.Checked:
			return <Tick color="#000000" />;
		case State.PartiallyChecked:
			return <PartialChecked color="#000000" />;
		case State.Unchecked:
			return <></>;
	}
};

const Checkbox: React.FC<Props> = ({ state, onClick, disabled }) => {
	return (
		<Container data-disabled={disabled} aria-disabled={disabled} onClick={() => onClick && !disabled && onClick()}>
			{buildStateWidget(state)}
		</Container>
	);
};

export default Checkbox;
