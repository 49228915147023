import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import Fuse from 'fuse.js';

import { SourceType } from 'api/types/source';

import { ToggleableFilterType, getFilterColor } from "../filter";
import { useReportDataSource } from '.';

type SearchAndFilterContextType = {
	filteredSources: SourceType[];
	availableFilters: ToggleableFilterType[];
	searchText: string;
	setSearchText: (s: string) => unknown;
};

export const emptyFilterTranslationKey = '__EMPTY_FILTER__';

const SearchAndFilterContext = createContext<SearchAndFilterContextType>({
	filteredSources: [],
	availableFilters: [],
	searchText: '',
	setSearchText: () => { },
});

export const SearchAndFilterProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [availableFilters, setAvailableFilters] = useState<ToggleableFilterType[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const debouncedSearchText = useDebounce(searchText, 250);

	const { sources } = useReportDataSource();

	useEffect(() => {
		setAvailableFilters(() =>
			Array.from(new Set(sources.flatMap((s) => s.counters?.map((c) => c.comeFrom) ?? [])))
				.map(
					(name, idx): ToggleableFilterType => ({
						idx,
						name: name,
						color: getFilterColor(name),
						active: false,
						toggle: () => {
							setAvailableFilters((filters) => filters.map((f) => (f.idx !== idx ? f : { ...f, active: !f.active })));
						},
					}),
				)
				.sort((f1, f2) => (f1.name == null || f1.name === '' ? 1 : f1.name < (f2.name ?? '') ? 1 : -1)),
		);
	}, [sources]);

	const activeFilters = useMemo(() => availableFilters.filter((f) => f.active).map((f) => f.name), [availableFilters]);

	// Memoize generated fuses to avoid recreating them each time the search text changes
	const fuses = useMemo(
		() => sources.map((s) => new Fuse(s.counters ?? [], { keys: ['title', 'code', 'name'], threshold: 0.3 })),
		[sources],
	);

	const filteredSources = useMemo(() => {
		if (debouncedSearchText.trim() === '' && activeFilters.length === 0) return sources;

		const searchedSources =
			debouncedSearchText.trim() === ''
				? sources
				: sources
					.map((s, idx) => ({
						...s,
						counters: fuses[idx].search(debouncedSearchText.trim()).map((i) => i.item),
					}))
					.filter((s) => s.counters.length !== 0);

		return activeFilters.length === 0
			? searchedSources
			: searchedSources
				.map((s) => ({
					...s,
					counters: s.counters?.filter((c) => activeFilters.includes(c.comeFrom)) ?? [],
				}))
				.filter((s) => s.counters.length !== 0);
	}, [debouncedSearchText, sources, activeFilters]);

	return (
		<SearchAndFilterContext.Provider
			value={{
				filteredSources,
				availableFilters,
				searchText,
				setSearchText,
			}}
		>
			{children}
		</SearchAndFilterContext.Provider>
	);
};

export const useSearchAndFilter = (): SearchAndFilterContextType => useContext(SearchAndFilterContext);
