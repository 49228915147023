import styled from 'styled-components';

export const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column;

	height: 100vh;
`;


const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
`;

export const CenteredDiv = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default Container;
