import React, { useMemo, useState, useEffect } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import ArrowLeft from 'assets/svg/ArrowLeft';
import Logout from 'assets/svg/Logout';
import SettingSVG from 'assets/svg/Setting';
import VirjinLogo from 'assets/svg/VirjinLogo';
import Dropdown from 'components/Dropdown';
import Search, { SearchType } from 'components/Search';
import { useUser } from 'providers/user';
import i18n from 'utils/lang';
import routes from 'utils/routes';
import { ReportStatus, ReportType } from 'api/types/report';
import { useReports } from 'providers/ReportsProvider';
import { showError } from 'utils/error';
import { useKeycloak } from 'providers/keycloak';
import Move from 'assets/svg/Move';
import { useModal } from 'components/Modal';
import SelectWorksite from 'components/Modal/SelectWorksite';
import { Flex } from 'styles';

import Container, {
	CursorPointer,
	DropdownButton,
	DropdownMenu,
	NavbarTitle,
	ReportComponentContainer,
	Spacer
} from './style';

type ReportProps = {
	navigate: NavigateFunction;
	currentReport: ReportType | null;
	updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>;
}

const ReportComponent: React.FC<ReportProps> = ({ navigate, currentReport, updateReport }) => {
	const user = useUser();
	const [reportName, setReportName] = useState<string>(currentReport?.name ?? "");
	const debouncedReportName = useDebounce(reportName, 250);
	const isDisabled = !currentReport || (currentReport.status !== ReportStatus.Invalidated && (user?.isReader || user?.isLocker));

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setReportName(event.target.value);
	}

	useEffect(() => {
		if (currentReport?.name !== reportName) {
			updateReport('name', reportName).catch(showError(`Unable to update description`));
		}
	}, [debouncedReportName])

	return (
		<ReportComponentContainer>
			<CursorPointer onClick={() => {
				navigate(routes.home)
			}}>
				<ArrowLeft />
			</CursorPointer>
			<Search disabled={isDisabled} type={SearchType.Report} value={reportName} onChange={handleChange} clearText={() => setReportName('')} />
		</ReportComponentContainer>
	)
}


const Navbar: React.FC<{ admin?: boolean }> = ({ admin = false }) => {
	const userInfos = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const { push } = useModal();
	const { roleByWorksite } = useKeycloak();
	const { currentReport, updateReport } = useReports();
	const username = useMemo(() => userInfos?.infos?.name || userInfos?.infos?.preferred_username || '', [userInfos, userInfos?.infos])

	return (
		<Container admin={admin}>
			{(() => {
				if (userInfos) {
					if (location.pathname.includes(routes.report)) {
						return <ReportComponent key={`navbar-report-${currentReport?._id ?? ''}`} navigate={navigate} currentReport={currentReport} updateReport={updateReport} />
					} else if (location.pathname.includes(routes.settings)) {
						return (
							<CursorPointer onClick={() => navigate(routes.home)}>
								<Flex flexDirection='row' gap='12px' alignItems='center'>
									<ArrowLeft />
									<NavbarTitle>{i18n.t('settings.title')}</NavbarTitle>
								</Flex>
							</CursorPointer>
						)
					}
					else if (location.pathname.includes(routes.links)) {
						return (
							<CursorPointer onClick={() => navigate(routes.home)}>
								<VirjinLogo />
							</CursorPointer>
						)
					}
					return (
						<CursorPointer onClick={() => navigate(routes.links)}>
							<VirjinLogo />
						</CursorPointer>
					)
				}
			})()
			}
			<Spacer />
			<Dropdown title={username}>
				<DropdownMenu>
					{roleByWorksite.length > 1 && (
						<DropdownButton onClick={() => push(<SelectWorksite />)}>
							<span>{i18n.t('change_site')}</span>
							<Move />
						</DropdownButton>
					)}
					{userInfos?.logout && (
						<DropdownButton onClick={userInfos.logout}>
							<span>{i18n.t('logout')}</span>
							<Logout />
						</DropdownButton>
					)}
				</DropdownMenu>
			</Dropdown>
			{!admin && (
				<CursorPointer onClick={() => navigate(routes.settings)}>
					<SettingSVG />
				</CursorPointer>
			)}
		</Container>
	);
};

export default Navbar;
