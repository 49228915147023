import React from 'react';

import { KeycloakProvider, useKeycloak } from 'providers/keycloak';

import AuthRouter from './routes/AuthRouter';
import UnauthRouter from './routes/UnauthRouter';

const Router: React.FC = () => {
	const { keycloak, clientId } = useKeycloak();

	return !keycloak || !clientId ? <UnauthRouter /> : <AuthRouter />;
}

const RouterBuilder: React.FC = () => {
	return (
		<KeycloakProvider>
			<Router />
		</KeycloakProvider>
	)
}

export default RouterBuilder;