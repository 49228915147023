import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

export enum AccordionState {
    UnSet,
    AllOpen,
    AllClosed,
}

type AccordionContextProps = {
    accordionState: AccordionState;
    handleCollapse: () => void;
};

const AccordionContext = createContext<AccordionContextProps>({
    accordionState: AccordionState.UnSet,
    handleCollapse: () => { }
});

export const AccordionProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [accordionState, setAccordionState] = useState<AccordionState>(AccordionState.UnSet);

    const handleCollapse = (): void => {
        setAccordionState(
            accordionState === AccordionState.AllOpen
                ? AccordionState.AllClosed
                : AccordionState.AllOpen
        );
    }

    return <AccordionContext.Provider value={{ accordionState, handleCollapse }}>{children}</AccordionContext.Provider>;
};

export const useAccordion: () => AccordionContextProps = () => useContext(AccordionContext);
