import React, { useEffect, useState } from 'react';

import i18n from 'utils/lang';
import CustomTooltip from 'components/Tooltip';
import { useReports } from 'providers/ReportsProvider';
import Button, { Type as ButtonType } from 'components/Button';
import { hasFlags, removeFlags, addFlags } from 'api/utils';

import { WeatherListContainer } from './style';
import WeatherComponent from './createComponents';

type WeatherListProps = {
	time: string;
	weather: number | null;
	onUpdate: (w: number) => unknown;
};

export const WeatherList: React.FC<WeatherListProps> = ({ time, weather, onUpdate }) => {
	const { canEdit } = useReports();
	const [selectedValue, setSelectedValue] = useState<number>(weather ?? -1);

	useEffect(() => {
		if (weather) setSelectedValue(weather);
	}, [weather]);

	function handleUpdate(newWeatherValue: number): void {
		const newValue = hasFlags(selectedValue, newWeatherValue) 
		? removeFlags(selectedValue, newWeatherValue) 
		: addFlags(selectedValue, newWeatherValue);

		onUpdate(newValue);
		setSelectedValue(newValue);
	}

	return (
		<WeatherListContainer>
			{WeatherComponent.map(({component: Element, value}, i) => (
				<div data-tooltip-id={`weather-icon-${value}-tooltip-${time}`} key={`weather-icon-${value}-tooltip-${time}`}>
					<Button
						disabled={!canEdit}
						key={`weather-icon-${i}-${time}`}
						type={hasFlags(selectedValue, value) ? ButtonType.meteoSelected : ButtonType.meteo}
						onClick={() => handleUpdate(value)}
					>
						<Element color={hasFlags(selectedValue, value) ? '#ffffff' : undefined} />
					</Button>
					{canEdit && (
						<CustomTooltip id={`weather-icon-${value}-tooltip-${time}`} place="top">
							{i18n.t(`weather.${i}`)}
						</CustomTooltip>
					)}
				</div>

			))}
		</WeatherListContainer>
	);
};
