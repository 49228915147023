import styled from 'styled-components';

export const LinksPageLayout = styled.main`
	--layout-padding: 1em;

	--section-padding: 2vh;
	--section-title-size: 3vh;
	--typography-weight: 700;

	flex-grow: 1;
	height: 100%;

	padding: var(--layout-padding, unset);

	display: grid;

	grid-template-areas:
		'account admin'
		'ref     ref';
	grid-template-rows: 404fr 273fr; // height values in px from Figma
	grid-template-columns: repeat(2, 1fr);
	grid-gap: var(--layout-padding, unset);

	background-color: #f2f2f2;

	&,
	* {
		box-sizing: border-box;
		margin: 0;
		font-weight: var(--typography-weight, 500);
		font-size: 2vh;
	}

	& > section {
		border-radius: 8px;
	}
`;

const SectionCard = styled.section`
	padding: var(--section-padding, unset);
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	& > h2 {
		width: 100%;
		font-size: var(--section-title-size, 20px);

		& + div {
			flex-grow: 1;
			width: 100%;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: var(--section-padding, unset);

			& > div {
				&:nth-of-type(1) {
					flex-grow: 1;

					display: grid;
					place-content: center;
				}

				&:nth-of-type(2) {
					flex-grow: 1;
					width: 50%;

					display: flex;
					flex-direction: column;
					justify-content: space-between;
					gap: var(--section-padding, unset);
				}
			}
		}
	}
`;

export const AccountSection = styled(SectionCard)`
	grid-area: account;

	background-color: #e0edfa;

	h2 {
		color: #25465f;
	}
`;

export const AdminSection = styled(SectionCard)`
	grid-area: admin;

	background-color: #25465f;

	h2 {
		color: #fff;
	}
`;

export const RefSection = styled(SectionCard)`
	grid-area: ref;

	background-color: #25465f;

	h2 {
		color: #fff;

		& + div > div:nth-of-type(2) {
			flex-grow: 0;
			justify-content: center;
			width: 25%;

			& > a {
				max-height: 75%;
			}
		}
	}
`;

export const LinkButton = styled.a`
	flex-grow: 1;

	color: #fff;
	background-color: #4d9cbf;
	text-decoration: none;

	padding: 10%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	font-size: 16px;

	border-radius: 8px;

	transition: transform ease-out 0.2s;
	&:hover {
		transform: scale(1.01);
	}
`;

type SVGContainerProps = {
	hasLargeWidth?: boolean
}

export const SVGContainer = styled.div<SVGContainerProps>`
	svg {
		width: ${(props) => props.hasLargeWidth ? '60vw' : '20vw'};
		height: ${(props) => props.hasLargeWidth ? '10em' : '20em'};
	}
`