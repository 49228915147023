import React from 'react';

type Props = {
	color?: string;
};

const Trashcan: React.FC<Props> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path
			fill={color ?? '#000'}
			d="M17 22H7a2 2 0 0 1-2-2V7H3V5h4V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1h4v2h-2v13a2 2 0 0 1-2 2ZM7 7v13h10V7H7Zm2-3v1h6V4H9Zm6 14h-2V9h2v9Zm-4 0H9V9h2v9Z"
		/>
	</svg>
);

export default React.memo(Trashcan);
