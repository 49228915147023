import styled from 'styled-components';
import { DropdownType } from '.';

type Props = {
	color?: string;
	type?: DropdownType;
	notInteractive?: boolean;
};

const Container = styled.div<Props>`
	display: flex;
	color: ${({ color }) => color ?? 'white'};
	flex-direction: row;
	align-items: center;
	justify-content: ${({ type }) => type && type === DropdownType.Menu ? 'center' : 'unset'};
	padding: ${({ type }) => type && type === DropdownType.Calendar ? '0 5px 0 15px' : '0 15px'};
	cursor: ${({ type, notInteractive }) => (type && type === DropdownType.Disabled) || notInteractive ? 'default' : 'pointer'};
	position: relative;
`;

export const MenuWrapper = styled.div`
	position: absolute;
	top: 30px;
	right: 1em;
	z-index: 1;

	min-width: 15em;
	min-height: 1em;
`;

export const TitlePadding = styled.div`
	padding-right: 10px;
	padding-left: 10px;
	font-weight: bold;
`;

export default Container;
