import React, { useRef, KeyboardEvent } from 'react';
import { Property } from 'csstype';

import InputStyle from './style';

export enum Style {
	Default,
	Content,
}

export type InputProps = {
	textAlign?: Property.TextAlign;
	style?: Style;
	placeholder?: string;
	value?: string | number;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	id?: string;
	disabled?: boolean;
};

const Input: React.FC<InputProps> = ({ textAlign, style, value, placeholder, onChange, onFocus, onBlur, id, disabled }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			inputRef.current?.blur();
		}
	};

	return (
		<InputStyle
			id={id}
			ref={inputRef}
			disabled={disabled}
			textAlign={textAlign}
			style={style}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			placeholder={placeholder}
			isZero={value === 0 || value === undefined}
			onKeyDown={handleKeyDown}
		/>
	);
};

export default Input;
