import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { showError } from '../utils/error';
import { ExportService, getAvailableExportServices } from '../api/routes/export';
import { useClient } from './client';

type ExportServices = {
	services: ExportService[];
};

const ExportServicesContext = createContext<ExportServices>({
	services: [],
});

export const ExportServicesProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { clientHandler: { exportClient } } = useClient();
	const [services, setServices] = useState<ExportService[]>([]);

	useEffectOnce(() => {
		(async () => {
			const availableServices = await getAvailableExportServices(exportClient);
			setServices(availableServices);
		})().catch(showError('Unable to fetch export services'));
	});

	return <ExportServicesContext.Provider value={{ services }}>{children}</ExportServicesContext.Provider>;
};

export const useExportServices: () => ExportServices = () => useContext(ExportServicesContext);
