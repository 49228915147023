import { ReferentialResponse } from "api/types/ref";
import ArrowDown from "assets/svg/ArrowDown";
import ArrowRight from "assets/svg/ArrowRight";
import Checkbox, { State } from "components/Checkbox";
import * as R from 'ramda';
import { useEffect, useMemo, useState } from "react";
import { Flex, Margin } from "styles";
import { BoldSpan, ContentContainer, CursorPointerFlex, EOTPSelectionContainer } from "./style";
import { RefsSelectionProps, SelectableReferential } from "./type";

type RefHierarchyType = {
    baseId: string,
    children: RefHierarchyType[],
    checkable: boolean,
    isSelected?: boolean,
    title?: string,
    nodeId?: string,
    item?: RefHierarchyType,
    id?: string,
};


const TitleFromCache: React.FC<{
    id: string;
}> = ({
    id
}) => {
        const item = window.localStorage.getItem('labelsEOTP');
        if (!item) return <BoldSpan>{id}</BoldSpan>;
        const eotps: ReferentialResponse[] = JSON.parse(item) as ReferentialResponse[];
        const correspondingEotps = eotps.filter(e => e.id === id);

        if (correspondingEotps.length === 0) return <BoldSpan>{id}</BoldSpan>;
        return <BoldSpan>{correspondingEotps[0].id} - {correspondingEotps[0].label}</BoldSpan>;
    };

const EOTPParent: React.FC<{
    referential: RefHierarchyType; setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isOpen: boolean,
    onClick: (elem?: RefHierarchyType) => unknown;
}> = ({
    referential,
    setIsOpen,
    isOpen,
    onClick
}) => {
        return (
            <>
                <Flex className="report-modal" flexDirection="row">
                    <CursorPointerFlex alignItems="center" justifyContent='center' onClick={() => setIsOpen(!isOpen)}>
                        {(isOpen) ? <ArrowDown color="black" /> : <ArrowRight color="black" />}
                    </CursorPointerFlex>

                    <Margin left={16}>
                        <Checkbox
                            disabled={!referential.checkable || !referential.item}
                            state={referential.checkable && referential.item?.isSelected === true ? State.Checked : State.Unchecked}
                            onClick={() => onClick(referential?.item)}
                        />
                    </Margin>
                    <Margin left={16} centerContent>
                        {referential?.item ?
                            <BoldSpan>{referential.item.title}</BoldSpan>
                            : <TitleFromCache id={referential.baseId} />
                        }
                    </Margin>
                </Flex>
            </>
        )
    };

const EOTPItem: React.FC<{
    referential: RefHierarchyType; onClick: (elem?: RefHierarchyType) => unknown,
    level: number;
}> = ({
    referential,
    onClick,
    level
}) => {
        const [isOpen, setIsOpen] = useState<boolean>(false);
        if (referential.children && referential.children.filter((e: RefHierarchyType) => e.id !== referential.id).length > 0) {
            return (
                <>
                    <EOTPParent referential={referential} setIsOpen={setIsOpen} isOpen={isOpen} onClick={onClick} />
                    <div style={{ marginLeft: '32px' }}>
                        {isOpen && referential.children.filter((e: RefHierarchyType) => e.id !== referential.baseId)
                            .map((ref: RefHierarchyType, idx: number) => (
                                <EOTPItem
                                    level={level + 1}
                                    referential={ref}
                                    key={`report-modal-selection-${ref.baseId}-${idx}`}
                                    onClick={onClick}
                                />
                            ))}
                    </div>
                </>
            )
        } else if (referential.item) {
            return (
                <Margin left={40}>
                    <Flex className="report-modal" flexDirection="row">
                        <Checkbox
                            disabled={!referential.checkable && !referential.isSelected}
                            state={referential.isSelected ? State.Checked : State.Unchecked}
                            onClick={() => onClick(referential)}
                        />
                        <Margin left={16} centerContent>
                            <BoldSpan>{referential.title}</BoldSpan>
                        </Margin>
                    </Flex>
                </Margin>
            );
        }
        return <></>;
    };

const getRefChildren = (refs: RefHierarchyType): RefHierarchyType => {
    if (!refs.children || refs.children.length === 0) return refs;
    const refTree: RefHierarchyType[] = [];
    let cloneRefs = { ...refs };
    let baseId = refs.baseId;

    // Get the first child node id
    const firstChildNodeId = cloneRefs.children[0].nodeId ?? '';
    // Look for the deepest common nodeId
    // For example:
    //  EB6X, EB6X.1 and EB6X.2 are provided => EB6X will be returned
    //  EB6X.ML1APD, EB6X.ML1APD.1 and EB6X.ML1APD.2 are provided => EB6X.ML1APD will be returned
    while (
        // PREVENT INFINITE LOOP if there is no dot in id. For exemple : Unique id can be 'IH - INSTPL'
        cloneRefs.children[0].id?.indexOf('.') !== -1 &&
        cloneRefs.children
            .filter(e => e.nodeId?.split('.')[0] === firstChildNodeId.split('.')[0]).length === cloneRefs.children.length
        && firstChildNodeId.slice(0, firstChildNodeId.indexOf('.')).length > 0) {
        baseId += (baseId.length > 0 && baseId.slice(-1) !== '.' ? '.' : '')
            + (firstChildNodeId.indexOf('.') !== -1 ? firstChildNodeId.slice(0, firstChildNodeId.indexOf('.')) : firstChildNodeId) + '.';
        cloneRefs = {
            ...cloneRefs, baseId: baseId, children: cloneRefs.children
                .map(e => ({ ...e, nodeId: e.nodeId?.slice(e.nodeId?.indexOf('.') + 1) }))
        };
    }
    const cleanBaseId = baseId.slice(-1) === '.' ? baseId.slice(0, -1) : baseId;
    // Populate the tree
    cloneRefs.children.forEach((ref) => {
        // If element is the same as parent => make it the item of the parent and don't store it as child
        if (ref.id === cleanBaseId) {
            cloneRefs = {
                ...cloneRefs, item: { ...ref, baseId: cleanBaseId }
            };
            return;
        }
        const nodeId = ref.nodeId?.split('.')[0];
        const refTreeIndex = refTree.findIndex((e) => e.nodeId === nodeId);
        const newChildNodeId = ref.nodeId && ref.nodeId.split('.').length > 1 ? ref.nodeId?.slice(ref.nodeId.indexOf('.') + 1) : '';
        // If elem doesnt exist in the tree => add it
        if (refTreeIndex === -1 || (nodeId && nodeId.length === 0)) {
            refTree.push({ ...ref, nodeId: nodeId, children: [{ ...ref, nodeId: newChildNodeId }] });
        }
        // If elem exist in the tree => push elem as one of its children
        else {
            refTree[refTreeIndex].children.push({ ...ref, nodeId: newChildNodeId });
        }
    });
    const result = refTree.map((refNode) => {
        const newBaseId = `${baseId}${baseId.length === 0 || baseId.slice(-1) === '.' ? '' : '.'}${refNode.nodeId ?? ''}`;
        if (refNode.children.length > 1) {
            return getRefChildren({
                ...refNode, baseId: newBaseId, children: refNode.children,
                item: refNode.children.find((e: RefHierarchyType) => e.id === cleanBaseId) ?? undefined
            });
        } else {
            return {
                ...refNode, baseId: cleanBaseId,
                item: refNode.children[0]
            }
        }
    });
    return {
        ...cloneRefs, baseId: cleanBaseId, children: result,
    };
};


export const EOTPAccordion: React.FC<RefsSelectionProps> = ({ refs, setRefs, filteredRefs }) => {
    const displayedRefs = useMemo(() => {
        return R.sortWith(
            [R.ascend(R.prop<string>('id'))],
            filteredRefs ?? refs,
        );
    }, [refs, filteredRefs]);
    const [refHierarchy, setRefHierarchy] = useState<RefHierarchyType>({ baseId: '', children: [], checkable: false });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    useEffect(() => {
        if (displayedRefs.length > 0) {
            setRefHierarchy(getRefChildren({
                baseId: '', checkable: false, children: displayedRefs
                    // REMOVE DUPLICATES
                    ?.filter((value: SelectableReferential, index: number, array: SelectableReferential[]) =>
                        array.findIndex((e: ReferentialResponse) => e.id === value.id) === index)
                    // ADDING USEFUL PROPERTIES
                    .map(e => ({ nodeId: e.id, checkable: e.checkable ?? true, id: e.id, title: e.title, baseId: '', children: [], isSelected: e.isSelected }))
            }));
        } else {
            setRefHierarchy({ baseId: '', children: [], checkable: false });
        }
    }, [displayedRefs]);

    return (
        <ContentContainer flexDirection="column">
            <EOTPSelectionContainer flexDirection="column">
                {/* IF base elem is a parent */}
                {!!refHierarchy.item &&
                    <>
                        <EOTPParent referential={refHierarchy} setIsOpen={setIsOpen} isOpen={isOpen} onClick={(e) => {
                            if (e) {
                                setRefs(refs.map((r) => (r.id === e.id ? { ...r, isSelected: !r.isSelected } : r)))
                            }
                        }} />
                        <div style={{ marginLeft: '32px' }}>
                            {isOpen && refHierarchy.children.filter((e: RefHierarchyType) => e.id !== refHierarchy.baseId)
                                .map((ref: RefHierarchyType, idx: number) => (
                                    <EOTPItem
                                        level={1}
                                        referential={ref}
                                        key={`report-modal-selection-${ref.baseId}-${idx}`}
                                        onClick={(e) => {
                                            if (e) {
                                                setRefs(refs.map((r) => (r.id === e.id ? { ...r, isSelected: !r.isSelected } : r)))
                                            }
                                        }}
                                    />
                                ))}
                        </div>
                    </>
                }
                {/* IF base elem is not a parent and has children */}
                {!!!refHierarchy.item && refHierarchy.children.length > 0 && refHierarchy.children.filter((e: RefHierarchyType) => e.id !== refHierarchy.baseId)
                    .map((ref: RefHierarchyType, idx: number) => (
                        <EOTPItem
                            level={0}
                            referential={ref}
                            key={`report-modal-selection-${ref.baseId}-${idx}`}
                            onClick={(e) => {
                                if (e) {
                                    setRefs(refs.map((r) => (r.id === e.id ? { ...r, isSelected: !r.isSelected } : r)))
                                }
                            }
                            }
                        />
                    ))}
            </EOTPSelectionContainer>
        </ContentContainer>
    );
};