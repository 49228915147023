import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ScrollSync } from 'react-scroll-sync';

import { FeaturesFlag, hasFlags } from 'api/utils';
import { usePreferences } from 'providers/preferences';

import Tabs from './components/Tabs';
import Header from './components/Header';
import HeaderResources from './components/Header/Resources';
import Content, { ResourceContent, TaskContent } from './components/Content';
import { ReportDataSourceProvider } from './provider/counters/sources';
import { SearchAndFilterProvider } from './provider/counters/sources/searchAndFilter';
import { ReportDataTasksProvider } from './provider/counters/tasks';
import { ReportDataResourcesProvider } from './provider/counters/resources';
import { SearchAndFilterResourcesProvider } from './provider/counters/resources/searchAndFilter';
import { AccordionProvider } from './provider/accordion';
import { ReportContext } from './provider';
import Container from './style';

const ReportByType: React.FC = () => {
	const { date } = useParams();
	const { viewRessource } = usePreferences();
	const { selectedCategory, labelsEOTP, selectedTab } = useContext(ReportContext);
	const hasTaskView: boolean = !!selectedCategory && hasFlags(selectedCategory.features, FeaturesFlag.TaskView);
	const hasResourceView = !!selectedCategory && hasFlags(selectedCategory.features, FeaturesFlag.ResourceView);

	// Resource View
	if (hasResourceView && viewRessource && selectedCategory != null) {
		return (
			<ScrollSync>
				<ReportDataResourcesProvider date={date} labelsEOTP={labelsEOTP} categoryID={selectedTab}>
					<SearchAndFilterResourcesProvider>
						<HeaderResources category={selectedCategory} />
						<ResourceContent category={selectedCategory} />
					</SearchAndFilterResourcesProvider>
				</ReportDataResourcesProvider>
			</ScrollSync>
		)
	}

	// Source View
	if (!hasTaskView && selectedCategory != null) {
		return (
			<ScrollSync>
				<ReportDataSourceProvider
					date={date}
					selectedCategory={selectedTab}
					features={selectedCategory.features}
					labelsEOTP={labelsEOTP}
				>
					<SearchAndFilterProvider>
						<Header category={selectedCategory} />
						<Content category={selectedCategory} />
					</SearchAndFilterProvider>
				</ReportDataSourceProvider>
			</ScrollSync>
		)
	}

	// Task View
	if (hasTaskView && selectedCategory != null) {
		return (
			<ReportDataTasksProvider date={date} labelsEOTP={labelsEOTP} categoryID={selectedCategory._id}>
				<Header category={selectedCategory} />
				<TaskContent category={selectedCategory} />
			</ReportDataTasksProvider>
		)
	}

	return <></>
};

const ReportBuilder: React.FC = () => {
	const { categories, selectedTab, setSelectedTab } = useContext(ReportContext);

	return (

		<Container>
			<AccordionProvider>
				<Tabs categories={categories} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
				<ReportByType />
			</AccordionProvider>
		</Container>
	)
}


export default ReportBuilder;
