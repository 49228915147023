import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { enUS, fr, hr, zhCN } from 'date-fns/locale';
import { showError } from 'utils/error';
import en_file from './en.json';
import fr_file from './fr.json';
import hr_file from './hr.json';
import zh_file from './zh.json';

export enum Languages {
	FRENCH = 'fr',
	ENGLISH = 'en',
	CROATIAN = 'hr',
	CHINESE = 'zh'
}

export const getCurrentLang: string = navigator.language.slice(0, 2);

export function getLocaleFromCode(code?: Languages): Locale {
	switch(code) {
		case 'en': return enUS;
		case 'hr': return hr;
		case 'zh': return zhCN;
		case 'fr': default : return fr;
	}
}

export function getLanguageFromCode(code: string): Languages {
	switch(code) {
		case 'en': return Languages.ENGLISH;
		case 'hr': return Languages.CROATIAN;
		case 'zh': return Languages.CHINESE;
		case 'fr': return Languages.FRENCH;
		default: return Languages.FRENCH;
	}
}

const resources = {
	en: {
		translation: en_file,
	},
	fr: {
		translation: fr_file,
	},
	hr: {
		translation: hr_file
	},
	zh: {
		translation: zh_file
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: getCurrentLang,
		fallbackLng: 'fr',
		interpolation: {
			escapeValue: false,
		},
	})
	.catch(showError('Failed to init i18n'));

export default i18n;
