import styled from "styled-components";
import { Flex } from "styles";

export const ModalHeaderContainer = styled(Flex)`
	flex-direction: row;
	align-items: center;
    width: 100%;
`

export const CrossContainer = styled(Flex)`
	cursor: pointer;
`;

export const TitleContainer = styled(Flex)`
	width: 100%;
`;

export const Title = styled.b`
	font-size: 28px;
	line-height: 32.81px;
`;