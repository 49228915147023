import React, { useCallback } from 'react';

import Substract from 'assets/svg/Subtract';
import Union from 'assets/svg/Union';
import { ThemeSelection, usePreferences } from 'providers/preferences';
import { EOTPCategory, PRODUCTION_TYPE_REFERENCE } from 'utils/constants';
import { showError } from 'utils/error';
import i18n, { Languages } from 'utils/lang';
import FilterTask from 'widgets/FilterTask';
import { useCategory } from 'widgets/Report/provider/category';
import { Timer, TimerType } from 'widgets/ReportHeader/components/WorkHours';
import { Card, DarkThemeButton, EOTPsContainer, EOTPsRowContainer, LanguageInput, LeftOptionPanel, LightThemeButton, PageContainer, SpanThemeButton, Title } from './style';

// TODO: Translation
const Setting: React.FC = () => {
	const {
		preferences: { theme, startTime, endTime, language },
		setTheme,
		setStartTime,
		setEndTime,
		setLanguage,
	} = usePreferences();
	const languageArray = Object.values(Languages);

	const categories = useCategory().filter(({ typeReference }) => typeReference !== PRODUCTION_TYPE_REFERENCE);

	const onThemeSelected = useCallback((selectedTheme: ThemeSelection) => {
		setTheme(selectedTheme);
	}, []);

	return (
		<PageContainer>
			<LeftOptionPanel gap='16px' flexDirection='column' height='100%' alignItems='center'>
				<Card alignItems='start'>
					<Title>{i18n.t('settings.working_hours')}</Title>
					<Timer
						title={i18n.t('start_working')}
						time={i18n.t('from')}
						value={startTime ?? 0}
						onValueChange={(nb) => {
							setStartTime(nb);
						}}
						settings
						type={TimerType.Setting}
					/>
					<Timer
						title={i18n.t('end_working')}
						time={i18n.t('to')}
						value={endTime ?? 0}
						onValueChange={(nb) => {
							setEndTime(nb);
						}}
						settings
						type={TimerType.Setting}
					/>
				</Card>
				<Card>
					<Title>{i18n.t('settings.language.title')}</Title>
					<LanguageInput value={i18n.t(`settings.language.${language ?? 'fr'}`) ?? ''} onChange={(e) => {
						const selectedLanguage = languageArray[languageArray.findIndex(lng => i18n.t(`settings.language.${lng}`) === e.target.value)]
						i18n.changeLanguage(selectedLanguage).catch(showError(i18n.t('errors.changingTranslation')));
						setLanguage(selectedLanguage)
					}}>
						{languageArray.map((lng, index) => {
							return (<option key={`optionLng-${index}`}>{i18n.t(`settings.language.${lng}`)}</option>)
						}
						)}
					</LanguageInput>
				</Card>
				<Card>
					<Title>{i18n.t('settings.theme.title')}</Title>
					<LightThemeButton theme={theme} disabled onClick={() => onThemeSelected(ThemeSelection.Light)}>
						<Union color={theme === ThemeSelection.Light ? '#F2F2F2' : '#25465F'} />
						<SpanThemeButton color={theme === ThemeSelection.Light ? '#F2F2F2' : '#25465F'}>{i18n.t('settings.theme.light')}</SpanThemeButton>
					</LightThemeButton>
					<DarkThemeButton theme={theme} disabled onClick={() => onThemeSelected(ThemeSelection.Dark)}>
						<Substract color={theme === ThemeSelection.Dark ? '#F2F2F2' : '#25465F'} />
						<SpanThemeButton color={theme === ThemeSelection.Dark ? '#F2F2F2' : '#25465F'}>{i18n.t('settings.theme.dark')}</SpanThemeButton>
					</DarkThemeButton>
				</Card>
			</LeftOptionPanel>
			<EOTPsContainer flexDirection='column'>
				<EOTPsRowContainer>
					{[EOTPCategory, ...categories].slice(0, 2).map((c, idx) => (
						<FilterTask key={`settings-filterTask-${idx}-${c._id}`} category={c} />
					))}
				</EOTPsRowContainer>
				<EOTPsRowContainer>
					{[EOTPCategory, ...categories].slice(2, [EOTPCategory, ...categories].length).map((c, idx) => (
						<FilterTask key={`settings-filterTask-${idx}-${c._id}`} category={c} />
					))}
				</EOTPsRowContainer>
			</EOTPsContainer>
		</PageContainer>
	);
};

export default Setting;
