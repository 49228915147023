import React from 'react';


const LinkImage2: React.FC = () => {
	return (
		<svg width="338" height="341" viewBox="0 0 338 341" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M151.849 251.13H38.1492C36.7177 251.128 35.3454 250.559 34.3332 249.547C33.321 248.535 32.7516 247.162 32.75 245.731V91.3779C32.7516 89.9464 33.321 88.5741 34.3332 87.5619C35.3454 86.5497 36.7177 85.9803 38.1492 85.9787H151.849C153.281 85.9803 154.653 86.5497 155.666 87.5619C156.678 88.5741 157.247 89.9464 157.249 91.3779V245.731C157.247 247.162 156.678 248.535 155.666 249.547C154.653 250.559 153.281 251.128 151.849 251.13Z" fill="white" />
			<path d="M89.8739 126.149C89.8739 130.607 88.4637 134.951 85.845 138.559C83.2263 142.166 79.5335 144.853 75.295 146.234C75.1682 146.275 75.0416 146.315 74.9129 146.353C74.3639 146.522 73.8071 146.666 73.2408 146.789C72.2158 147.012 71.1755 147.156 70.1288 147.221C69.6739 147.252 69.2163 147.267 68.7561 147.267C66.3787 147.27 64.0179 146.87 61.7738 146.085C61.7086 146.062 61.6433 146.039 61.58 146.016C57.944 144.702 54.7383 142.416 52.312 139.407C49.8857 136.397 48.332 132.779 47.8202 128.948C47.3084 125.116 47.8581 121.217 49.4094 117.676C50.9607 114.135 53.4542 111.088 56.618 108.867C59.7818 106.645 63.4946 105.335 67.3519 105.078C71.2092 104.822 75.0629 105.629 78.4931 107.411C81.9234 109.194 84.7985 111.884 86.8053 115.188C88.8121 118.492 89.8735 122.284 89.8739 126.149Z" fill="white" />
			<path d="M68.0834 140.152C74.4443 140.152 79.6008 134.995 79.6008 128.634C79.6008 122.274 74.4443 117.117 68.0834 117.117C61.7224 117.117 56.5659 122.274 56.5659 128.634C56.5659 134.995 61.7224 140.152 68.0834 140.152Z" fill="#FFB6B6" />
			<path d="M71.1694 144.23C71.1967 144.104 71.2108 143.975 71.2117 143.846C71.2109 143.363 71.0185 142.899 70.6767 142.557C70.3348 142.216 69.8714 142.023 69.3879 142.022H66.7002C66.2166 142.023 65.753 142.215 65.411 142.557C65.0691 142.899 64.8768 143.363 64.8764 143.846C64.8763 143.976 64.8904 144.105 64.9185 144.232C63.904 144.552 62.9449 145.026 62.0754 145.639C61.9064 145.756 61.7394 145.883 61.5801 146.016C61.6434 146.039 61.7087 146.062 61.7739 146.085C64.018 146.87 66.3788 147.27 68.7563 147.267C69.217 147.267 69.6745 147.252 70.1289 147.221C71.1756 147.156 72.2159 147.012 73.2409 146.789C73.8072 146.666 74.364 146.522 74.913 146.353C73.8268 145.392 72.5516 144.669 71.1694 144.23Z" fill="#4D9CBF" />
			<path d="M70.8239 146.977C70.5747 146.996 70.3349 147.08 70.1289 147.221C71.1757 147.156 72.216 147.012 73.2409 146.789L70.8239 146.977Z" fill="#2F2E41" />
			<path d="M68.7562 147.843C66.3139 147.846 63.8887 147.435 61.5835 146.628L61.3831 146.557C56.5544 144.812 52.5012 141.411 49.9442 136.958C47.3872 132.506 46.4922 127.291 47.4184 122.241C48.3446 117.191 51.032 112.633 55.0025 109.378C58.9731 106.123 63.9695 104.381 69.1031 104.464C74.2368 104.546 79.175 106.446 83.0394 109.826C86.9038 113.207 89.444 117.848 90.2083 122.925C90.9725 128.003 89.9112 133.186 87.2132 137.554C84.5151 141.923 80.3551 145.192 75.4731 146.782C75.3367 146.826 75.2083 146.866 75.0778 146.905C74.5325 147.073 73.954 147.224 73.3629 147.352C72.3094 147.58 71.2401 147.729 70.1643 147.796C69.702 147.827 69.2271 147.843 68.7562 147.843ZM68.7562 105.608C63.9224 105.608 59.2436 107.313 55.5428 110.423C51.8419 113.533 49.356 117.848 48.5223 122.609C47.6886 127.37 48.5605 132.273 50.9845 136.455C53.4086 140.637 57.2297 143.831 61.7757 145.474L61.9659 145.542C64.1482 146.305 66.4441 146.694 68.7562 146.691C69.2014 146.691 69.6501 146.676 70.09 146.647C71.1089 146.583 72.1214 146.443 73.1191 146.226C73.6782 146.105 74.2248 145.963 74.7436 145.803C74.8721 145.765 74.9941 145.726 75.1161 145.687C79.7659 144.173 83.723 141.049 86.276 136.878C88.8291 132.707 89.8106 127.762 89.0442 122.933C88.2778 118.103 85.8137 113.705 82.0949 110.529C78.3761 107.353 73.6465 105.608 68.7562 105.608Z" fill="#CCCCCC" />
			<path d="M151.849 251.13H38.1492C36.7177 251.128 35.3454 250.559 34.3332 249.547C33.321 248.535 32.7516 247.162 32.75 245.731V91.3779C32.7516 89.9464 33.321 88.5741 34.3332 87.5619C35.3454 86.5497 36.7177 85.9803 38.1492 85.9787H151.849C153.281 85.9803 154.653 86.5497 155.666 87.5619C156.678 88.5741 157.247 89.9464 157.249 91.3779V245.731C157.247 247.162 156.678 248.535 155.666 249.547C154.653 250.559 153.281 251.128 151.849 251.13ZM38.1492 86.6139C36.8861 86.6153 35.6752 87.1177 34.7821 88.0108C33.889 88.9039 33.3866 90.1148 33.3852 91.3779V245.731C33.3866 246.994 33.889 248.205 34.7821 249.098C35.6752 249.991 36.8861 250.493 38.1492 250.495H151.849C153.113 250.493 154.323 249.991 155.217 249.098C156.11 248.205 156.612 246.994 156.613 245.731V91.3779C156.612 90.1148 156.11 88.9039 155.217 88.0108C154.323 87.1177 153.113 86.6153 151.849 86.6139H38.1492Z" fill="#CCCCCC" />
			<path d="M129.838 126.067H97.6652C96.9907 126.067 96.3441 125.798 95.8674 125.321C95.3908 124.844 95.123 124.197 95.123 123.522C95.123 122.848 95.3908 122.201 95.8674 121.723C96.3441 121.246 96.9907 120.978 97.6652 120.977H129.838C130.173 120.976 130.504 121.042 130.813 121.17C131.123 121.297 131.404 121.485 131.64 121.721C131.877 121.958 132.065 122.238 132.193 122.547C132.321 122.856 132.387 123.188 132.387 123.522C132.387 123.857 132.321 124.188 132.193 124.497C132.065 124.806 131.877 125.087 131.64 125.323C131.404 125.559 131.123 125.747 130.813 125.875C130.504 126.002 130.173 126.068 129.838 126.067Z" fill="#E0EDFA" />
			<path d="M140.338 134.658H97.6652C96.9907 134.657 96.3441 134.388 95.8674 133.911C95.3908 133.434 95.123 132.787 95.123 132.112C95.123 131.438 95.3908 130.791 95.8674 130.314C96.3441 129.836 96.9907 129.568 97.6652 129.567H140.338C141.012 129.568 141.659 129.836 142.135 130.314C142.612 130.791 142.88 131.438 142.88 132.112C142.88 132.787 142.612 133.434 142.135 133.911C141.659 134.388 141.012 134.657 140.338 134.658Z" fill="#E0EDFA" />
			<path d="M129.792 167.605H49.6612C49.3267 167.605 48.9954 167.54 48.6862 167.412C48.377 167.284 48.0961 167.097 47.8594 166.861C47.6227 166.624 47.4349 166.343 47.3068 166.034C47.1787 165.725 47.1128 165.394 47.1128 165.06C47.1128 164.725 47.1787 164.394 47.3068 164.085C47.4349 163.776 47.6227 163.495 47.8594 163.259C48.0961 163.022 48.377 162.835 48.6862 162.707C48.9954 162.579 49.3267 162.514 49.6612 162.514H129.792C130.466 162.515 131.113 162.784 131.59 163.261C132.066 163.738 132.334 164.385 132.334 165.06C132.334 165.734 132.066 166.381 131.59 166.858C131.113 167.336 130.466 167.604 129.792 167.605Z" fill="#E0EDFA" />
			<path d="M140.291 176.195H49.6612C49.3267 176.196 48.9954 176.13 48.6862 176.002C48.377 175.875 48.0961 175.687 47.8594 175.451C47.6227 175.214 47.4349 174.934 47.3068 174.625C47.1787 174.316 47.1128 173.984 47.1128 173.65C47.1128 173.315 47.1787 172.984 47.3068 172.675C47.4349 172.366 47.6227 172.085 47.8594 171.849C48.0961 171.613 48.377 171.425 48.6862 171.298C48.9954 171.17 49.3267 171.104 49.6612 171.105H140.291C140.626 171.104 140.957 171.17 141.266 171.298C141.575 171.425 141.856 171.613 142.093 171.849C142.33 172.085 142.517 172.366 142.646 172.675C142.774 172.984 142.84 173.315 142.84 173.65C142.84 173.984 142.774 174.316 142.646 174.625C142.517 174.934 142.33 175.214 142.093 175.451C141.856 175.687 141.575 175.875 141.266 176.002C140.957 176.13 140.626 176.196 140.291 176.195Z" fill="#E0EDFA" />
			<path d="M129.792 195.939H49.661C48.9859 195.939 48.3385 195.671 47.8612 195.193C47.3839 194.716 47.1157 194.069 47.1157 193.394C47.1157 192.719 47.3839 192.071 47.8612 191.594C48.3385 191.117 48.9859 190.848 49.661 190.848H129.792C130.467 190.848 131.114 191.117 131.591 191.594C132.069 192.071 132.337 192.719 132.337 193.394C132.337 194.069 132.069 194.716 131.591 195.193C131.114 195.671 130.467 195.939 129.792 195.939Z" fill="#E0EDFA" />
			<path d="M140.291 204.529H49.661C48.9859 204.529 48.3385 204.261 47.8612 203.784C47.3839 203.306 47.1157 202.659 47.1157 201.984C47.1157 201.309 47.3839 200.662 47.8612 200.184C48.3385 199.707 48.9859 199.439 49.661 199.439H140.291C140.966 199.439 141.613 199.707 142.091 200.184C142.568 200.662 142.836 201.309 142.836 201.984C142.836 202.659 142.568 203.306 142.091 203.784C141.613 204.261 140.966 204.529 140.291 204.529Z" fill="#E0EDFA" />
			<path d="M129.792 224.273H49.661C48.9859 224.273 48.3385 224.005 47.8612 223.528C47.3839 223.05 47.1157 222.403 47.1157 221.728C47.1157 221.053 47.3839 220.405 47.8612 219.928C48.3385 219.451 48.9859 219.183 49.661 219.183H129.792C130.467 219.183 131.114 219.451 131.591 219.928C132.069 220.405 132.337 221.053 132.337 221.728C132.337 222.403 132.069 223.05 131.591 223.528C131.114 224.005 130.467 224.273 129.792 224.273Z" fill="#E0EDFA" />
			<path d="M140.291 232.863H49.661C48.9859 232.863 48.3385 232.595 47.8612 232.118C47.3839 231.64 47.1157 230.993 47.1157 230.318C47.1157 229.643 47.3839 228.996 47.8612 228.518C48.3385 228.041 48.9859 227.773 49.661 227.773H140.291C140.966 227.773 141.613 228.041 142.091 228.518C142.568 228.996 142.836 229.643 142.836 230.318C142.836 230.993 142.568 231.64 142.091 232.118C141.613 232.595 140.966 232.863 140.291 232.863Z" fill="#E0EDFA" />
			<path d="M299.851 212.383L186.15 212.383C184.719 212.381 183.346 211.812 182.334 210.8C181.322 209.788 180.753 208.415 180.751 206.984L180.751 52.6309C180.753 51.1994 181.322 49.8271 182.334 48.8149C183.346 47.8027 184.719 47.2333 186.15 47.2317L299.85 47.2316C301.282 47.2332 302.654 47.8026 303.666 48.8148C304.679 49.827 305.248 51.1993 305.25 52.6308L305.25 206.984C305.248 208.415 304.679 209.788 303.667 210.8C302.654 211.812 301.282 212.381 299.851 212.383Z" fill="white" />
			<path d="M299.851 212.383L186.15 212.383C184.719 212.381 183.346 211.812 182.334 210.8C181.322 209.788 180.753 208.415 180.751 206.984L180.751 52.6309C180.753 51.1994 181.322 49.8271 182.334 48.8149C183.346 47.8027 184.719 47.2333 186.15 47.2317L299.85 47.2316C301.282 47.2332 302.654 47.8026 303.666 48.8148C304.679 49.827 305.248 51.1993 305.25 52.6308L305.25 206.984C305.248 208.415 304.679 209.788 303.667 210.8C302.654 211.812 301.282 212.381 299.851 212.383ZM186.15 47.8669C184.887 47.8683 183.676 48.3707 182.783 49.2638C181.89 50.1569 181.388 51.3679 181.386 52.6309L181.386 206.984C181.388 208.247 181.89 209.458 182.783 210.351C183.676 211.244 184.887 211.746 186.15 211.748L299.851 211.748C301.114 211.746 302.325 211.244 303.218 210.351C304.111 209.458 304.613 208.247 304.615 206.984L304.614 52.6308C304.613 51.3678 304.111 50.1569 303.218 49.2637C302.324 48.3706 301.114 47.8683 299.85 47.8668L186.15 47.8669Z" fill="#CCCCCC" />
			<path d="M237.928 87.1921C237.928 91.3688 236.69 95.4517 234.369 98.9245C232.049 102.397 228.751 105.104 224.892 106.702C221.033 108.301 216.787 108.719 212.691 107.904C208.594 107.089 204.832 105.078 201.878 102.125C198.925 99.1712 196.913 95.4084 196.099 91.312C195.284 87.2155 195.702 82.9695 197.3 79.1107C198.899 75.2519 201.605 71.9538 205.078 69.6333C208.551 67.3129 212.634 66.0743 216.811 66.0743C222.411 66.0761 227.781 68.3016 231.741 72.2615C235.701 76.2215 237.927 81.5918 237.928 87.1921Z" fill="white" />
			<path d="M228.742 88.6163C228.461 105.276 204.033 105.273 203.755 88.6158C204.035 71.9571 228.464 71.9597 228.742 88.6163Z" fill="#FFB6B6" />
			<path d="M219.627 105.171C220.03 102.451 216.845 102.988 215.159 102.961C214.888 102.962 214.619 103.024 214.374 103.141C214.129 103.259 213.914 103.43 213.743 103.641C213.572 103.853 213.451 104.099 213.388 104.364C213.325 104.628 213.321 104.903 213.378 105.169C212.073 105.572 210.865 106.239 209.828 107.127C214.198 108.651 218.947 108.704 223.349 107.277C222.276 106.313 221.006 105.594 219.627 105.171Z" fill="#4D9CBF" />
			<path d="M216.811 108.886C211.357 108.881 206.106 106.821 202.104 103.116C198.102 99.4115 195.644 94.3344 195.219 88.8975C194.795 83.4605 196.435 78.0634 199.814 73.7824C203.192 69.5015 208.06 66.6513 213.447 65.8004C218.833 64.9495 224.343 66.1603 228.876 69.1915C233.41 72.2226 236.634 76.8513 237.906 82.1543C239.178 87.4573 238.405 93.045 235.74 97.8029C233.075 102.561 228.714 106.139 223.527 107.825C221.358 108.53 219.091 108.888 216.811 108.886ZM216.81 66.6502C212.747 66.6497 208.774 67.8542 205.395 70.1114C202.016 72.3687 199.383 75.5773 197.827 79.3315C196.272 83.0857 195.865 87.2168 196.657 91.2023C197.45 95.1879 199.407 98.8489 202.28 101.722C205.154 104.596 208.815 106.552 212.8 107.345C216.786 108.138 220.917 107.731 224.671 106.175C228.425 104.62 231.634 101.986 233.891 98.6072C236.148 95.2282 237.353 91.2557 237.352 87.192C237.346 81.7459 235.18 76.5246 231.329 72.6736C227.478 68.8226 222.257 66.6564 216.81 66.6502Z" fill="#CCCCCC" />
			<path d="M277.84 87.3202L245.666 87.3202C244.991 87.3202 244.344 87.052 243.867 86.5747C243.389 86.0974 243.121 85.45 243.121 84.7749C243.121 84.0999 243.389 83.4525 243.867 82.9751C244.344 82.4978 244.991 82.2296 245.666 82.2296L277.84 82.2296C278.515 82.2296 279.162 82.4978 279.639 82.9751C280.117 83.4524 280.385 84.0998 280.385 84.7749C280.385 85.4499 280.117 86.0973 279.639 86.5747C279.162 87.052 278.515 87.3202 277.84 87.3202Z" fill="#E0EDFA" />
			<path d="M288.339 95.9105L245.667 95.9105C245.332 95.9109 245.001 95.8454 244.692 95.7177C244.382 95.59 244.101 95.4025 243.865 95.1661C243.628 94.9298 243.44 94.649 243.312 94.34C243.184 94.031 243.118 93.6998 243.118 93.3653C243.118 93.0307 243.184 92.6995 243.312 92.3905C243.44 92.0815 243.628 91.8008 243.865 91.5644C244.101 91.328 244.382 91.1405 244.692 91.0128C245.001 90.8851 245.332 90.8196 245.667 90.82L288.339 90.8199C289.014 90.8199 289.662 91.0881 290.139 91.5654C290.616 92.0428 290.884 92.6902 290.884 93.3652C290.884 94.0403 290.616 94.6877 290.139 95.165C289.662 95.6423 289.014 95.9105 288.339 95.9105Z" fill="#E0EDFA" />
			<path d="M277.793 128.858L197.662 128.858C196.988 128.857 196.341 128.589 195.865 128.111C195.388 127.634 195.12 126.987 195.12 126.313C195.12 125.638 195.388 124.991 195.865 124.514C196.341 124.037 196.988 123.768 197.662 123.767L277.793 123.767C278.468 123.767 279.115 124.035 279.593 124.513C280.07 124.99 280.338 125.638 280.338 126.313C280.338 126.988 280.07 127.635 279.593 128.112C279.115 128.59 278.468 128.858 277.793 128.858Z" fill="#E0EDFA" />
			<path d="M288.292 137.448L197.662 137.448C197.328 137.449 196.996 137.383 196.687 137.255C196.378 137.128 196.097 136.94 195.86 136.704C195.624 136.467 195.436 136.187 195.308 135.878C195.18 135.569 195.114 135.237 195.114 134.903C195.114 134.568 195.18 134.237 195.308 133.928C195.436 133.619 195.624 133.338 195.86 133.102C196.097 132.866 196.378 132.678 196.687 132.551C196.996 132.423 197.328 132.357 197.662 132.358L288.292 132.358C288.967 132.358 289.615 132.626 290.092 133.103C290.569 133.58 290.837 134.228 290.837 134.903C290.837 135.578 290.569 136.225 290.092 136.703C289.615 137.18 288.967 137.448 288.292 137.448Z" fill="#E0EDFA" />
			<path d="M277.793 157.192L197.662 157.192C196.988 157.191 196.341 156.923 195.865 156.445C195.388 155.968 195.12 155.321 195.12 154.647C195.12 153.972 195.388 153.325 195.865 152.848C196.341 152.371 196.988 152.102 197.662 152.101L277.793 152.101C278.468 152.101 279.115 152.37 279.593 152.847C280.07 153.324 280.338 153.972 280.338 154.647C280.338 155.322 280.07 155.969 279.593 156.446C279.115 156.924 278.468 157.192 277.793 157.192Z" fill="#E0EDFA" />
			<path d="M288.292 165.782L197.662 165.782C197.328 165.783 196.996 165.717 196.687 165.589C196.378 165.462 196.097 165.274 195.86 165.038C195.624 164.801 195.436 164.521 195.308 164.212C195.18 163.903 195.114 163.572 195.114 163.237C195.114 162.902 195.18 162.571 195.308 162.262C195.436 161.953 195.624 161.672 195.86 161.436C196.097 161.2 196.378 161.012 196.687 160.885C196.996 160.757 197.328 160.691 197.662 160.692L288.292 160.692C288.967 160.692 289.615 160.96 290.092 161.437C290.569 161.914 290.837 162.562 290.837 163.237C290.837 163.912 290.569 164.559 290.092 165.037C289.615 165.514 288.967 165.782 288.292 165.782Z" fill="#E0EDFA" />
			<path d="M277.793 185.526L197.662 185.526C196.987 185.526 196.34 185.258 195.862 184.781C195.385 184.303 195.117 183.656 195.117 182.981C195.117 182.306 195.385 181.658 195.862 181.181C196.34 180.704 196.987 180.435 197.662 180.435L277.793 180.435C278.468 180.435 279.115 180.704 279.592 181.181C280.07 181.658 280.338 182.306 280.338 182.981C280.338 183.656 280.07 184.303 279.592 184.78C279.115 185.258 278.468 185.526 277.793 185.526Z" fill="#E0EDFA" />
			<path d="M288.292 194.116L197.662 194.116C196.987 194.116 196.34 193.848 195.862 193.371C195.385 192.894 195.117 192.246 195.117 191.571C195.117 190.896 195.385 190.249 195.862 189.771C196.34 189.294 196.987 189.026 197.662 189.026L288.292 189.026C288.967 189.026 289.614 189.294 290.092 189.771C290.569 190.249 290.837 190.896 290.837 191.571C290.837 192.246 290.569 192.893 290.092 193.371C289.614 193.848 288.967 194.116 288.292 194.116Z" fill="#E0EDFA" />
			<path d="M231.249 278.761H117.549C116.118 278.759 114.745 278.19 113.733 277.178C112.721 276.166 112.152 274.793 112.15 273.362V119.009C112.152 117.577 112.721 116.205 113.733 115.193C114.745 114.181 116.118 113.611 117.549 113.61H231.249C232.681 113.611 234.053 114.181 235.065 115.193C236.078 116.205 236.647 117.577 236.649 119.009V273.362C236.647 274.793 236.078 276.166 235.065 277.178C234.053 278.19 232.681 278.759 231.249 278.761Z" fill="white" />
			<path d="M127.032 153.346C127.029 156.126 127.575 158.879 128.641 161.446C129.706 164.013 131.27 166.344 133.241 168.304C133.406 168.467 133.575 168.63 133.746 168.789C134.775 169.751 135.898 170.607 137.098 171.344C137.328 171.487 137.559 171.623 137.795 171.755C139.008 172.439 140.287 173.001 141.611 173.431C141.84 173.506 142.072 173.577 142.304 173.642C146.515 174.85 150.996 174.724 155.132 173.282C155.213 173.253 155.294 173.224 155.372 173.195C159.003 171.874 162.202 169.583 164.621 166.57C167.04 163.557 168.586 159.938 169.09 156.108C169.595 152.277 169.04 148.381 167.484 144.845C165.928 141.308 163.432 138.266 160.267 136.049C157.103 133.833 153.391 132.527 149.535 132.274C145.68 132.021 141.829 132.83 138.402 134.614C134.974 136.398 132.102 139.087 130.097 142.39C128.093 145.693 127.033 149.483 127.032 153.346Z" fill="white" />
			<path d="M149.291 167.481C155.652 167.481 160.808 162.238 160.808 155.769C160.808 149.3 155.652 144.056 149.291 144.056C142.93 144.056 137.773 149.3 137.773 155.769C137.773 162.238 142.93 167.481 149.291 167.481Z" fill="#FFB6B6" />
			<path d="M140 173.199C145.478 174.376 151.307 174.253 156.688 172.847C156.793 172.819 156.898 172.791 157 172.763C155.732 172.029 154.298 171.474 152.767 171.123C152.875 170.835 152.873 170.53 152.762 170.243C152.61 169.879 152.306 169.561 151.894 169.337C151.483 169.114 150.985 168.995 150.477 169H147.22C146.712 168.995 146.216 169.113 145.805 169.337C145.394 169.56 145.091 169.878 144.94 170.241C144.828 170.529 144.827 170.834 144.935 171.123C143.586 171.432 142.312 171.901 141.159 172.51C140.927 172.632 140.702 172.759 140.485 172.892C140.32 172.991 140.157 173.094 140 173.199Z" fill="#4D9CBF" />
			<path d="M148.15 175.04C146.119 175.042 144.097 174.758 142.145 174.196C141.915 174.131 141.674 174.058 141.432 173.979C140.072 173.536 138.759 172.959 137.512 172.257C137.278 172.126 137.044 171.988 136.796 171.835C135.563 171.077 134.41 170.197 133.353 169.21C133.178 169.047 133.005 168.88 132.836 168.713C129.511 165.398 127.351 161.093 126.682 156.446C126.012 151.798 126.869 147.059 129.123 142.939C131.377 138.82 134.906 135.544 139.182 133.602C143.457 131.66 148.247 131.158 152.832 132.171C157.417 133.184 161.549 135.658 164.609 139.22C167.668 142.783 169.488 147.241 169.797 151.927C170.105 156.612 168.885 161.271 166.32 165.204C163.754 169.137 159.982 172.131 155.569 173.737C155.49 173.765 155.408 173.795 155.326 173.824C153.02 174.632 150.594 175.043 148.15 175.04ZM148.15 132.805C144.084 132.805 140.11 134.011 136.731 136.272C133.351 138.532 130.719 141.744 129.166 145.501C127.613 149.258 127.21 153.392 128.008 157.379C128.805 161.365 130.768 165.025 133.648 167.896C133.807 168.053 133.972 168.212 134.139 168.368C135.14 169.303 136.232 170.136 137.4 170.854C137.635 170.999 137.856 171.129 138.076 171.253C139.257 171.918 140.501 172.464 141.789 172.884C142.017 172.958 142.242 173.027 142.46 173.088C146.557 174.264 150.917 174.141 154.942 172.738L155.175 172.654C159.713 171.002 163.524 167.804 165.939 163.621C168.354 159.439 169.218 154.539 168.38 149.783C167.541 145.026 165.054 140.717 161.355 137.612C157.655 134.507 152.98 132.805 148.15 132.805Z" fill="#CCCCCC" />
			<path d="M231.249 278.761H117.549C116.118 278.759 114.745 278.19 113.733 277.178C112.721 276.166 112.152 274.793 112.15 273.362V119.009C112.152 117.577 112.721 116.205 113.733 115.193C114.745 114.181 116.118 113.611 117.549 113.61H231.249C232.681 113.611 234.053 114.181 235.065 115.193C236.078 116.205 236.647 117.577 236.649 119.009V273.362C236.647 274.793 236.078 276.166 235.065 277.178C234.053 278.19 232.681 278.759 231.249 278.761ZM117.549 114.245C116.286 114.246 115.075 114.749 114.182 115.642C113.289 116.535 112.787 117.746 112.785 119.009V273.362C112.787 274.625 113.289 275.836 114.182 276.729C115.075 277.622 116.286 278.124 117.549 278.126H231.249C232.512 278.124 233.723 277.622 234.616 276.729C235.51 275.836 236.012 274.625 236.013 273.362V119.009C236.012 117.746 235.51 116.535 234.616 115.642C233.723 114.749 232.512 114.246 231.249 114.245H117.549Z" fill="#3F3D56" />
			<path d="M209.238 153.832H177.065C176.39 153.832 175.742 153.564 175.265 153.086C174.788 152.609 174.52 151.962 174.52 151.286C174.52 150.611 174.788 149.964 175.265 149.487C175.742 149.009 176.39 148.741 177.065 148.741H209.238C209.913 148.741 210.561 149.009 211.038 149.487C211.515 149.964 211.783 150.611 211.783 151.286C211.783 151.962 211.515 152.609 211.038 153.086C210.561 153.564 209.913 153.832 209.238 153.832Z" fill="#4D9CBF" />
			<path d="M219.737 162.422H177.065C176.39 162.422 175.742 162.154 175.265 161.677C174.788 161.199 174.52 160.552 174.52 159.877C174.52 159.202 174.788 158.554 175.265 158.077C175.742 157.6 176.39 157.332 177.065 157.332H219.737C220.412 157.332 221.06 157.6 221.537 158.077C222.014 158.554 222.283 159.202 222.283 159.877C222.283 160.552 222.014 161.199 221.537 161.677C221.06 162.154 220.412 162.422 219.737 162.422Z" fill="#4D9CBF" />
			<path d="M209.192 195.236H129.061C128.386 195.236 127.738 194.968 127.261 194.49C126.784 194.013 126.516 193.366 126.516 192.691C126.516 192.016 126.784 191.368 127.261 190.891C127.738 190.414 128.386 190.145 129.061 190.145H209.192C209.867 190.145 210.514 190.414 210.991 190.891C211.469 191.368 211.737 192.016 211.737 192.691C211.737 193.366 211.469 194.013 210.991 194.49C210.514 194.968 209.867 195.236 209.192 195.236Z" fill="#E0EDFA" />
			<path d="M219.691 203.826H129.061C128.386 203.826 127.738 203.558 127.261 203.081C126.784 202.604 126.516 201.956 126.516 201.281C126.516 200.606 126.784 199.959 127.261 199.481C127.738 199.004 128.386 198.736 129.061 198.736H219.691C220.366 198.736 221.013 199.004 221.491 199.481C221.968 199.959 222.236 200.606 222.236 201.281C222.236 201.956 221.968 202.604 221.491 203.081C221.013 203.558 220.366 203.826 219.691 203.826Z" fill="#E0EDFA" />
			<path d="M209.192 223.57H129.061C128.387 223.569 127.74 223.301 127.263 222.823C126.787 222.346 126.519 221.699 126.519 221.025C126.519 220.35 126.787 219.703 127.263 219.226C127.74 218.749 128.387 218.48 129.061 218.479H209.192C209.867 218.479 210.514 218.748 210.992 219.225C211.469 219.702 211.737 220.35 211.737 221.025C211.737 221.7 211.469 222.347 210.992 222.825C210.514 223.302 209.867 223.57 209.192 223.57Z" fill="#E0EDFA" />
			<path d="M219.691 232.16H129.061C128.387 232.16 127.74 231.891 127.263 231.414C126.787 230.937 126.519 230.29 126.519 229.615C126.519 228.941 126.787 228.294 127.263 227.816C127.74 227.339 128.387 227.071 129.061 227.07H219.691C220.366 227.07 221.014 227.338 221.491 227.815C221.968 228.293 222.236 228.94 222.236 229.615C222.236 230.29 221.968 230.938 221.491 231.415C221.014 231.892 220.366 232.16 219.691 232.16Z" fill="#E0EDFA" />
			<path d="M209.192 251.904H129.061C128.386 251.904 127.738 251.636 127.261 251.159C126.784 250.681 126.516 250.034 126.516 249.359C126.516 248.684 126.784 248.036 127.261 247.559C127.738 247.082 128.386 246.814 129.061 246.814H209.192C209.867 246.814 210.514 247.082 210.991 247.559C211.469 248.036 211.737 248.684 211.737 249.359C211.737 250.034 211.469 250.681 210.991 251.159C210.514 251.636 209.867 251.904 209.192 251.904Z" fill="#E0EDFA" />
			<path d="M219.691 260.494H129.061C128.386 260.494 127.738 260.226 127.261 259.749C126.784 259.272 126.516 258.624 126.516 257.949C126.516 257.274 126.784 256.627 127.261 256.149C127.738 255.672 128.386 255.404 129.061 255.404H219.691C220.366 255.404 221.013 255.672 221.491 256.149C221.968 256.627 222.236 257.274 222.236 257.949C222.236 258.624 221.968 259.272 221.491 259.749C221.013 260.226 220.366 260.494 219.691 260.494Z" fill="#E0EDFA" />
			<path d="M229.93 243.614C226.635 243.61 223.371 244.256 220.325 245.514C217.279 246.773 214.511 248.62 212.18 250.949C209.848 253.278 207.998 256.043 206.736 259.088C205.474 262.132 204.825 265.395 204.825 268.691C204.825 271.987 205.474 275.25 206.736 278.294C207.998 281.339 209.848 284.104 212.18 286.433C214.511 288.762 217.279 290.609 220.325 291.868C223.37 293.126 226.634 293.772 229.93 293.768C236.573 293.757 242.941 291.109 247.634 286.408C252.327 281.706 254.963 275.334 254.963 268.691C254.963 262.048 252.327 255.676 247.634 250.974C242.941 246.273 236.573 243.625 229.93 243.614Z" fill="#4D9CBF" />
			<path d="M227.551 279.106C226.891 279.107 226.248 278.893 225.72 278.497L225.687 278.473L218.791 273.197C218.146 272.703 217.725 271.972 217.619 271.167C217.513 270.361 217.731 269.547 218.225 268.902C218.72 268.258 219.449 267.835 220.255 267.729C221.06 267.622 221.875 267.839 222.52 268.333L226.987 271.758L237.542 257.988C237.787 257.668 238.092 257.4 238.441 257.199C238.789 256.998 239.173 256.867 239.572 256.814C239.971 256.762 240.376 256.788 240.765 256.892C241.154 256.996 241.518 257.176 241.837 257.42L241.838 257.421L241.773 257.512L241.84 257.421C242.484 257.916 242.905 258.646 243.012 259.452C243.118 260.257 242.9 261.072 242.407 261.717L229.991 277.907C229.704 278.28 229.335 278.582 228.912 278.789C228.489 278.996 228.024 279.104 227.553 279.102L227.551 279.106Z" fill="white" />
		</svg>
	);
};

export default LinkImage2;
