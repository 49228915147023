import React, { CSSProperties } from 'react';

import ButtonStyle from './style';

export enum Type {
	primary,
	secondary,
	third,
	first,
	fourth,
	meteo,
	meteoSelected,
	filter,
	filterSelected,
	colorized,
	report,
	reportSelected,
	calendarHeader,
	modalValidator
}

export type Props = {
	children?: React.ReactNode;
	onClick?: () => void;
	disabled?: boolean;
	paddingY?: number;
	paddingX?: number;
	type: Type;
	color?: string;
	style?: CSSProperties;
};

const Button: React.FC<Props> = ({ children, onClick, disabled, type, paddingY, paddingX, color, style }) => {
	return (
		<ButtonStyle
			disabled={disabled}
			onClick={onClick}
			variant={type}
			paddingY={paddingY}
			paddingX={paddingX}
			color={color}
			style={style}
		>
			{children}
		</ButtonStyle>
	);
};

export default Button;
