import React from 'react';


const LinkImage3: React.FC = () => {
	return (
		<svg width="990" height="210" viewBox="0 0 1078 210" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M683.402 71.6198H394.598C391.899 71.624 389.312 73.0174 387.403 75.4943C385.495 77.9713 384.421 81.3295 384.418 84.8325V125.168C384.421 128.671 385.495 132.029 387.403 134.506C389.312 136.983 391.899 138.376 394.598 138.38H683.402C686.101 138.376 688.689 136.983 690.597 134.506C692.505 132.029 693.579 128.671 693.582 125.168V84.8325C693.579 81.3295 692.505 77.9713 690.597 75.4943C688.689 73.0174 686.101 71.624 683.402 71.6198ZM692.511 125.168C692.507 128.301 691.546 131.306 689.839 133.522C688.131 135.738 685.817 136.985 683.402 136.989H394.598C392.184 136.985 389.869 135.738 388.161 133.522C386.454 131.306 385.493 128.301 385.49 125.168V84.8325C385.493 81.6986 386.454 78.6944 388.161 76.4784C389.869 74.2624 392.184 73.0154 394.598 73.0108H683.402C685.817 73.0154 688.131 74.2624 689.839 76.4784C691.546 78.6944 692.507 81.6986 692.511 84.8325V125.168Z" fill="white" />
			<path d="M358.133 138.38H63.3896C60.6351 138.376 57.9944 136.983 56.0467 134.506C54.099 132.029 53.0033 128.67 53 125.167V84.8327C53.0033 81.3297 54.099 77.9714 56.0467 75.4945C57.9944 73.0175 60.6351 71.6241 63.3896 71.6199H358.133C360.887 71.6241 363.528 73.0175 365.476 75.4945C367.423 77.9714 368.519 81.3297 368.522 84.8327V125.167C368.519 128.67 367.423 132.029 365.476 134.506C363.528 136.983 360.887 138.376 358.133 138.38ZM63.3896 73.0109C60.9251 73.0144 58.5623 74.2611 56.8196 76.4773C55.0769 78.6936 54.0966 81.6985 54.0938 84.8327V125.167C54.0961 128.302 55.0761 131.308 56.8189 133.524C58.5617 135.741 60.9248 136.988 63.3896 136.992H358.133C360.598 136.988 362.96 135.742 364.703 133.525C366.446 131.309 367.427 128.304 367.429 125.17V84.8327C367.427 81.6985 366.446 78.6936 364.704 76.4773C362.961 74.2611 360.598 73.0144 358.133 73.0109H63.3896Z" fill="white" />
			<path d="M1014.61 138.38H719.867C717.113 138.376 714.472 136.983 712.524 134.506C710.577 132.029 709.481 128.67 709.478 125.167V84.8327C709.481 81.3297 710.577 77.9714 712.524 75.4944C714.472 73.0174 717.113 71.624 719.867 71.6198H1014.61C1017.36 71.624 1020.01 73.0174 1021.95 75.4944C1023.9 77.9714 1025 81.3297 1025 84.8327V125.167C1025 128.67 1023.9 132.029 1021.95 134.506C1020.01 136.983 1017.36 138.376 1014.61 138.38ZM719.867 73.0108C717.403 73.0144 715.04 74.261 713.297 76.4773C711.554 78.6935 710.574 81.6984 710.571 84.8327V125.167C710.574 128.302 711.554 131.308 713.296 133.524C715.039 135.741 717.402 136.988 719.867 136.992H1014.61C1017.08 136.988 1019.44 135.742 1021.18 133.525C1022.92 131.309 1023.9 128.304 1023.91 125.17V84.8327C1023.9 81.6984 1022.92 78.6935 1021.18 76.4773C1019.44 74.261 1017.08 73.0144 1014.61 73.0108H719.867Z" fill="white" />
			<path d="M448.491 128.185C443.928 128.185 439.467 126.832 435.672 124.296C431.878 121.761 428.92 118.157 427.174 113.941C425.427 109.725 424.971 105.086 425.861 100.61C426.751 96.1339 428.949 92.0226 432.176 88.7957C435.403 85.5688 439.514 83.3712 443.99 82.4809C448.466 81.5906 453.105 82.0475 457.321 83.7939C461.537 85.5403 465.141 88.4977 467.676 92.2922C470.212 96.0866 471.565 100.548 471.565 105.111C471.565 111.231 469.134 117.1 464.807 121.427C460.48 125.754 454.611 128.185 448.491 128.185Z" fill="#4D9CBF" />
			<path d="M656.463 124.074H491.477C490.485 124.074 489.534 123.488 488.833 122.445C488.131 121.402 487.737 119.987 487.737 118.511C487.737 117.036 488.131 115.62 488.833 114.577C489.534 113.534 490.485 112.948 491.477 112.948H656.463C657.454 112.948 658.405 113.534 659.107 114.577C659.808 115.62 660.202 117.036 660.202 118.511C660.202 119.987 659.808 121.402 659.107 122.445C658.405 123.488 657.454 124.074 656.463 124.074Z" fill="#E0EDFA" />
			<path d="M602.428 100.231H491.467C490.478 100.231 489.529 99.6453 488.83 98.6019C488.13 97.5586 487.737 96.1435 487.737 94.668C487.737 93.1925 488.13 91.7775 488.83 90.7341C489.529 89.6908 490.478 89.1047 491.467 89.1047H602.428C603.417 89.1047 604.366 89.6908 605.065 90.7341C605.765 91.7775 606.158 93.1925 606.158 94.668C606.158 96.1435 605.765 97.5586 605.065 98.6019C604.366 99.6453 603.417 100.231 602.428 100.231Z" fill="#E0EDFA" />
			<path d="M117.073 128.184C112.51 128.184 108.049 126.831 104.254 124.295C100.46 121.76 97.5023 118.157 95.7559 113.94C94.0095 109.724 93.5526 105.085 94.4429 100.609C95.3332 96.1331 97.5307 92.0218 100.758 88.7949C103.985 85.568 108.096 83.3704 112.572 82.4801C117.048 81.5898 121.687 82.0467 125.903 83.7931C130.119 85.5395 133.723 88.4969 136.258 92.2914C138.794 96.0858 140.147 100.547 140.147 105.11C140.147 111.23 137.716 117.099 133.389 121.426C129.062 125.753 123.193 128.184 117.073 128.184Z" fill="black" fill-opacity="0.2" />
			<path d="M773.551 128.184C768.987 128.184 764.526 126.831 760.732 124.295C756.937 121.76 753.98 118.157 752.233 113.94C750.487 109.724 750.03 105.085 750.92 100.609C751.811 96.1331 754.008 92.0218 757.235 88.7949C760.462 85.568 764.573 83.3704 769.049 82.4801C773.525 81.5898 778.164 82.0467 782.381 83.7931C786.597 85.5395 790.2 88.4969 792.736 92.2914C795.271 96.0858 796.624 100.547 796.624 105.11C796.624 111.23 794.193 117.099 789.866 121.426C785.539 125.753 779.67 128.184 773.551 128.184Z" fill="black" fill-opacity="0.2" />
			<path d="M333.598 124.074H160.249C159.207 124.074 158.207 123.488 157.471 122.445C156.734 121.402 156.32 119.987 156.32 118.511C156.32 117.036 156.734 115.62 157.471 114.577C158.207 113.534 159.207 112.948 160.249 112.948H333.598C334.64 112.948 335.639 113.534 336.376 114.577C337.113 115.62 337.527 117.036 337.527 118.511C337.527 119.987 337.113 121.402 336.376 122.445C335.639 123.488 334.64 124.074 333.598 124.074Z" fill="black" fill-opacity="0.2" />
			<path d="M990.075 124.074H816.726C815.684 124.074 814.685 123.488 813.948 122.445C813.211 121.402 812.797 119.987 812.797 118.511C812.797 117.036 813.211 115.62 813.948 114.577C814.685 113.534 815.684 112.948 816.726 112.948H990.075C991.117 112.948 992.117 113.534 992.854 114.577C993.59 115.62 994.004 117.036 994.004 118.511C994.004 119.987 993.59 121.402 992.854 122.445C992.117 123.488 991.117 124.074 990.075 124.074Z" fill="black" fill-opacity="0.2" />
			<path d="M310.267 100.231H161.326C159.999 100.231 158.725 99.6453 157.786 98.6019C156.847 97.5586 156.32 96.1435 156.32 94.668C156.32 93.1925 156.847 91.7775 157.786 90.7341C158.725 89.6908 159.999 89.1047 161.326 89.1047H310.267C311.594 89.1047 312.868 89.6908 313.807 90.7341C314.746 91.7775 315.273 93.1925 315.273 94.668C315.273 96.1435 314.746 97.5586 313.807 98.6019C312.868 99.6453 311.594 100.231 310.267 100.231Z" fill="black" fill-opacity="0.2" />
			<path d="M966.744 100.231H817.804C816.476 100.231 815.203 99.6453 814.264 98.6019C813.325 97.5586 812.797 96.1435 812.797 94.668C812.797 93.1925 813.325 91.7775 814.264 90.7341C815.203 89.6908 816.476 89.1047 817.804 89.1047H966.744C968.072 89.1047 969.345 89.6908 970.284 90.7341C971.223 91.7775 971.751 93.1925 971.751 94.668C971.751 96.1435 971.223 97.5586 970.284 98.6019C969.345 99.6453 968.072 100.231 966.744 100.231Z" fill="black" fill-opacity="0.2" />
			<path d="M447.313 113.227C446.74 113.229 446.182 113.044 445.724 112.7L445.695 112.678L439.715 108.104C439.438 107.891 439.205 107.626 439.031 107.324C438.856 107.022 438.742 106.688 438.697 106.342C438.651 105.996 438.674 105.645 438.764 105.308C438.854 104.97 439.01 104.654 439.222 104.377C439.435 104.1 439.699 103.868 440.002 103.693C440.304 103.519 440.637 103.405 440.983 103.359C441.329 103.314 441.681 103.337 442.018 103.427C442.355 103.517 442.672 103.673 442.949 103.885L446.822 106.856L455.976 94.9136C456.404 94.3546 457.037 93.9888 457.736 93.8965C458.434 93.8043 459.141 93.9932 459.7 94.4217L459.643 94.5011L459.701 94.4217C460.26 94.851 460.625 95.4843 460.717 96.1826C460.809 96.881 460.621 97.5873 460.193 98.1467L449.426 112.187C449.176 112.51 448.856 112.771 448.489 112.95C448.123 113.129 447.72 113.222 447.312 113.22L447.313 113.227Z" fill="white" />
		</svg>
	);
};

export default LinkImage3;
