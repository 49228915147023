import React, { useContext } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { ReportDataSourceContext } from 'widgets/Report/provider/counters/sources';
import { useAccordion } from 'widgets/Report/provider/accordion';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CategoryType } from 'api/types/category';
import { FeaturesFlag, hasFlags } from 'api/utils';
import WizardStick from 'assets/svg/WizardStick';
import i18n from 'utils/lang';
import Filter from 'components/Filter';
import Search, { SearchType } from 'components/Search';

import { useSearchAndFilter } from '../../provider/counters/sources/searchAndFilter';
import { Col, ColNotSpecified, TotalCol, ScrollCols, Cols, ColType } from '../../style';
import Container, {
	FilterContainer,
	FiltersContainer,
	ColText,
	WizardStickContainer,
} from './style';


export type Props = {
	category: CategoryType;
};

const Header: React.FC<Props> = ({ category }) => {
	const { searchText, setSearchText } = useSearchAndFilter();
	const { handleCollapse } = useAccordion();
	const { cols } = useContext(ReportDataSourceContext);
	const hasFilter: boolean = !!category.features && hasFlags(category.features, FeaturesFlag.ShowFilter);
	const hasTaskView: boolean = !!category.features && hasFlags(category.features, FeaturesFlag.TaskView);
	const showEOTPs = !!category.features && hasFlags(category.features, FeaturesFlag.AddEOTP);

	return (
		<Container>
			<FiltersContainer minWidth="0">
				<Search
					type={SearchType.FilterCounter}
					clearText={() => setSearchText('')}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
				/>
				<FilterContainer>{hasFilter && <Filter />}</FilterContainer>
			</FiltersContainer>
			<WizardStickContainer justifyContent='center' alignItems='center' onClick={handleCollapse}>
				<WizardStick />
			</WizardStickContainer>
			<ColNotSpecified type={ColType.Header}>{!hasTaskView && i18n.t('undefined')}</ColNotSpecified>
			<Cols>
				{!!cols && !hasTaskView && cols.length > 0 && showEOTPs && (
					<ScrollSyncPane group="horizontal">
						<ScrollCols type={ColType.Header}>
							{cols.map((col, idx) => (
								<Col key={`Header-${idx}-${col.rawLabel}`} type={ColType.Header}>
									<ReactTooltip id={`tooltip-${idx}-${col.rawLabel}`} />
									<ColText data-tooltip-id={`tooltip-${idx}-${col.rawLabel}`} data-tooltip-content={`${col.label} : ${col.rawLabel}`}>
										{col.label}
									</ColText>
								</Col>
							))}
						</ScrollCols>
					</ScrollSyncPane>
				)}
			</Cols>
			<TotalCol type={ColType.Header}>{!hasTaskView && i18n.t('total')}</TotalCol>
		</Container>
	);
};

export default Header;
