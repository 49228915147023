import React from 'react';

export type Props = {
	color?: string;
};

const Tick: React.FC<Props> = ({ color }) => {
	return (
		<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.24707 4.79419L5.45285 9.99997L13.4672 1.98557L11.4816 -0.00012207L5.45285 6.02858L2.23277 2.8085L0.24707 4.79419Z"
				fill={color ?? '#4D9CBF'}
			/>
		</svg>
	);
};

export default Tick;
