import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;

	gap: 32px;
	padding: 4px 34px;
	height: 40px;

	background-color: #4D9CBF;
	color: #ffffff;
	font-weight: bold;
`;

export const Spacer = styled.div`
	display: flex;
	flex-grow: 3;
`;

type PropsContentContainer = {
	open: boolean;
}

export const ContentContainer = styled.div<PropsContentContainer>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;

	gap: 20px;
	padding: ${({ open }) => open ? '8px 24px' : '0 24px'};
	max-height: ${({ open }) => open ? '150px' : '0px'};

	height: 84px;

	background-color: #E0EDFA;

	transition: padding 0.2s ease-out;

	${({ open }) => !open && `
		& > div, section {
			display: none;
		}
	`}
`;

type ArrowAccordionHandlerProps = {
	open: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ArrowAccordionHandler = styled.div<ArrowAccordionHandlerProps>`
	cursor: pointer;
	svg {
		transform: rotate(${({ open }) => open ? '180deg' : '0deg'});
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
	}
`;

export const Separator = styled.div`
	width: 1px;
	height: 100%;
	background-color: #25465F;
`

export default Container;