import React, { useId } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'utils/lang';
import Menu from 'assets/svg/Menu';
import { showError } from 'utils/error';
import { useUser } from 'providers/user';
import Trashcan from 'assets/svg/Trashcan';
import Dropdown, { DropdownType } from 'components/Dropdown';
import { useModal } from 'components/Modal';
import StatusLock from 'assets/svg/StatusLock';
import { ReportStatus } from 'api/types/report';
import StatusCheck from 'assets/svg/StatusCheck';
import { useReports } from 'providers/ReportsProvider';
import StatusDoubleCheck from 'assets/svg/StatusDoubleCheck';
import Button, { Type as ButtonType } from 'components/Button';
import { DropdownButton, DropdownMenu } from 'widgets/Navbar/style';
import CustomTooltip from 'components/Tooltip';
import { useClient } from 'providers/client';
import {
	checkReport,
	invalidateReport,
	lockReport,
	uncheckReport,
	unlockReport,
	validateReport,
} from 'api/routes/report';

import ConfirmDeletionModal from '../ConfirmDeletionModal';

import Container, { ValidationContainer } from './style';
import Loader, { LoadingType } from 'components/Loader';

const ReportStatusActionButton: React.FC<{
	onClick: VoidFunction;
	icon: React.ReactElement;
	enabled: boolean;
	on: boolean;
	tooltip?: string;
}> = ({ onClick, icon, enabled, on, tooltip }) => {
	const id = useId();
	const iconWithColor = React.cloneElement(icon, { color: !on && enabled ? '#25465F' : '#ffffff' });

	return (
		<>
			<div data-tooltip-id={id}>
				<Button
					type={on ? ButtonType.reportSelected : ButtonType.report}
					disabled={!enabled}
					onClick={onClick}
				>
					{iconWithColor}
				</Button>
			</div>

			{enabled && tooltip != null && (
				<CustomTooltip id={id} place="top" backgroundColor='#ffffff' color="#25465F">
					{tooltip}
				</CustomTooltip>
			)}
		</>
	);
};

const Validation: React.FC = () => {
	const { clientHandler: { client } } = useClient();
	const { currentReport, setCurrentReport, deleteCurrentReport } = useReports();
	const user = useUser();
	const { push, pop } = useModal();
	const navigate = useNavigate();

	const reportId = currentReport?._id?.toString() ?? '';

	const reportStatus = currentReport?.status;
	const canDelete =
		currentReport?.createdBy != null &&
		user?.infos?.preferred_username === currentReport?.createdBy &&
		reportStatus === ReportStatus.Invalidated;

	const handleClickWithFn = (fn: () => Promise<ReportStatus | undefined>): VoidFunction => {
		return () => {
			push(<Loader type={LoadingType.Modal} />)
			fn()
				.then((status) => {
					if (!currentReport || status == null) return; // Should never happen, just here for type safety
					pop();
					setCurrentReport({
						...currentReport,
						status,
					});
				})
				.catch(showError(`An error occurred while changing report status`));
		};
	};

	const handleReportDeletion = (): void => {
		deleteCurrentReport();
		pop();
		navigate('/');
	};

	return (
		<Container canDelete={canDelete}>
			<ValidationContainer>
				<ReportStatusActionButton
					icon={<StatusCheck />}
					on={currentReport?.status != null && currentReport?.status === ReportStatus.Validated}
					enabled={
						!!user?.isWriter &&
						currentReport?.createdBy === user.infos?.preferred_username &&
						currentReport?.status != null &&
						[ReportStatus.Invalidated, ReportStatus.Validated].indexOf(currentReport?.status) !== -1
					}
					tooltip={(() => {
						if (currentReport?.status === ReportStatus.Invalidated)
							return i18n.t('status.tooltip.validate') ?? undefined;
						if (currentReport?.status === ReportStatus.Validated)
							return i18n.t('status.tooltip.invalidate') ?? undefined;
					})()}
					onClick={handleClickWithFn(() => {
						if (currentReport?.status === ReportStatus.Invalidated) {
							return validateReport(client, reportId);
						} else if (currentReport?.status === ReportStatus.Validated) {
							return invalidateReport(client, reportId);
						}
						return Promise.resolve(currentReport?.status);
					})}
				/>
				<ReportStatusActionButton
					icon={<StatusDoubleCheck />}
					on={currentReport?.status != null && currentReport?.status === ReportStatus.Checked}
					enabled={
						!!user?.isChecker &&
						currentReport?.status != null &&
						[ReportStatus.Validated, ReportStatus.Checked].indexOf(currentReport?.status) !== -1
					}
					tooltip={(() => {
						if (currentReport?.status === ReportStatus.Validated) return i18n.t('status.tooltip.check') ?? undefined;
						if (currentReport?.status === ReportStatus.Checked) return i18n.t('status.tooltip.uncheck') ?? undefined;
					})()}
					onClick={handleClickWithFn(() => {
						if (currentReport?.status === ReportStatus.Validated) {
							return checkReport(client, reportId);
						} else if (currentReport?.status === ReportStatus.Checked) {
							return uncheckReport(client, reportId);
						}
						return Promise.resolve(currentReport?.status);
					})}
				/>
				<ReportStatusActionButton
					icon={<StatusLock />}
					on={currentReport?.status != null && currentReport?.status === ReportStatus.Locked}
					enabled={
						!!user?.isLocker &&
						currentReport?.status != null &&
						[ReportStatus.Checked, ReportStatus.Locked].indexOf(currentReport?.status) !== -1
					}
					tooltip={(() => {
						if (currentReport?.status === ReportStatus.Checked) return i18n.t('status.tooltip.lock') ?? undefined;
						if (currentReport?.status === ReportStatus.Locked) return i18n.t('status.tooltip.unlock') ?? undefined;
					})()}
					onClick={handleClickWithFn(() => {
						if (currentReport?.status === ReportStatus.Checked) {
							return lockReport(client, reportId);
						} else if (currentReport?.status === ReportStatus.Locked) {
							return unlockReport(client, reportId);
						}
						return Promise.resolve(currentReport?.status);
					})}
				/>
				<Dropdown
					type={!canDelete ? DropdownType.Disabled : DropdownType.Default}
					titleComponent={
						<div style={{ width: '25px', height: '25px' }}>
							{canDelete && (
								<Button type={ButtonType.report} style={{ backgroundColor: 'transparent' }}>
									<Menu color='#ffffff' />
								</Button>
							)}
						</div>
					}>
					<DropdownMenu>
						<DropdownButton onClick={() => push(<ConfirmDeletionModal handleDeletion={handleReportDeletion} />)}>
							<span>{i18n.t('delete')}</span> <Trashcan />
						</DropdownButton>
					</DropdownMenu>
				</Dropdown>
			</ValidationContainer>

		</Container>
	);
};

export default Validation;
