import React from 'react';

export type Props = {
	color?: string;
};

const Rain: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="RainSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color ?? '#25465F'}
				d="M10.8,15.3l-1,3.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.4-0.6l1-3.8
	c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.6-0.1,0.7,0.2C10.8,15,10.8,15.2,10.8,15.3z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color ?? '#25465F'}
				d="M7.4,15.3l-1,3.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.4-0.6l1-3.8
	c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5-0.1,0.7,0.2C7.4,15,7.5,15.2,7.4,15.3z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color ?? '#25465F'}
				d="M14,15.3l-1,3.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.3-0.6l1-3.8
	c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5-0.1,0.7,0.2C14,15,14,15.2,14,15.3z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color ?? '#25465F'}
				d="M17.6,15.3l-1,3.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.4-0.6l1-3.8
	c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.6-0.1,0.7,0.2h0C17.6,15,17.6,15.2,17.6,15.3z"
			/>
			<path
				fill={color ?? '#25465F'}
				d="M20,10.6c0,1.2-1,2.2-2.2,2.2h-11C5.2,12.8,4,11.6,4,10c0-1.5,1.2-2.8,2.8-2.8C6.8,5.5,8.2,4,10,4
	c1.3,0,2.4,0.8,2.9,1.8c0.4-0.2,0.9-0.4,1.3-0.4c1.5,0,2.8,1.2,2.8,2.8c0,0.1,0,0.2,0,0.2c0.2-0.1,0.5-0.1,0.7-0.1
	C19,8.4,20,9.3,20,10.6z"
			/>
		</svg>
	);
};

export default Rain;
