import React, { useEffect, useState } from 'react';
import { addDays, subDays } from 'date-fns';
import { useParams } from 'react-router-dom';

import i18n from 'utils/lang';
import { showError } from 'utils/error';
import ArrowDown from 'assets/svg/ArrowDown';
import { ReportStatus, ReportType } from 'api/types/report';
import { useReports } from 'providers/ReportsProvider';
import { useUser } from 'providers/user';

import SwitchView from './components/SwitchView';
import Validation from './components/Validation';
import Calendar from './components/Calendar';
import Comment from './components/Comment';
import Shift from './components/Shift';
import Temperature from './components/Temperature';
import Container, {
	HeadContainer,
	Spacer,
	ContentContainer,
	ArrowAccordionHandler,
	Separator
} from './style';

const ReportHeader: React.FC = () => {
	const { date } = useParams();
	const [open, setOpen] = useState<boolean>(true);
	const [reports, setReports] = useState<ReportType[]>([]);
	const { currentReport, fetchNewReports, updateReport, setCurrentReport } = useReports();
	const userInfos = useUser();
	const currentDate = new Date(currentReport?.dateReport ?? date ?? 0)

	useEffect(() => {
		(async () => {
			const fetchedReports = await fetchNewReports({
				startDate: subDays(currentDate, 14),
				endDate: addDays(currentDate, 14),
				createdBy: currentReport?.createdBy,
			});
			const filteredReport = fetchedReports.filter(
				({ status, createdBy }) =>
					status === ReportStatus.Validated ||
					status === ReportStatus.Checked ||
					status === ReportStatus.Locked ||
					status === ReportStatus.Invalidated && userInfos?.infos?.preferred_username === createdBy
			);
			setReports(filteredReport);
		})().catch(showError(`Unable to fetch filtered reports`));
	}, [date]);

	return (
		<Container >
			<HeadContainer>
				<Calendar
					key={`report-header-calendar-${date ?? ''}-${reports.length}`}
					reports={reports}
					currentReport={currentReport}
					setCurrentReport={setCurrentReport}
					currentDate={currentDate}
				/>
				<p>{i18n.t('written_by')}: {currentReport?.editor ?? ''}</p>
				<Spacer />
				<SwitchView />
				<Validation />
				<ArrowAccordionHandler open={open} onClick={() => setOpen(!open)}>
					<ArrowDown />
				</ArrowAccordionHandler>
			</HeadContainer>
			<ContentContainer open={open}>
				<Shift
					currentReport={currentReport}
					updateReport={updateReport}
					time="start"
				/>
				<Separator />
				<Shift
					currentReport={currentReport}
					updateReport={updateReport}
					time="end"
				/>
				<Separator />
				<Temperature
					temperature={{ min: currentReport?.tempMin, max: currentReport?.tempMax }}
					updateReport={updateReport}
				/>
				<Separator />
				<Comment
					currentReport={currentReport}
					updateReport={updateReport}
				/>
			</ContentContainer>
		</Container>
	);
};

export default ReportHeader;
