import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Worksite from "widgets/Worksite";
import Loader, { LoadingType } from "components/Loader";
import routes from "utils/routes";

import UnauthHome from "../components/UnauthHome";
import { CenteredDiv } from "../style";

const router = createBrowserRouter([
	{
		path: routes.home,
		element: <UnauthHome />,
	},
	{
		path: `${routes.worksite}/:code`,
		element: <Worksite />,
	},
	{
		path: '*',
		element: <Loader type={LoadingType.Fullscreen} />
	}
]);


const UnauthRouter: React.FC = () => {
	return (
		<CenteredDiv>
			<RouterProvider router={router} />
		</CenteredDiv>
	);
}


export default UnauthRouter;