import React from 'react';

import LinkImage1 from 'assets/svg/LinkImage1';
import LinkImage2 from 'assets/svg/LinkImage2';
import LinkImage3 from 'assets/svg/LinkImage3';
import routes from 'utils/routes';
import i18n from 'utils/lang';
import { useClient } from 'providers/client';

import { AccountSection, AdminSection, LinkButton, LinksPageLayout, RefSection, SVGContainer } from './style';

const Links: React.FC = () => {
	const { worksite } = useClient();

	const urlProfile = process.env.REACT_APP_PROFILE_URL || '#';
	const urlUsers = process.env.REACT_APP_USERS_URL || '#';
	const urlReferential = process.env.REACT_APP_REFERENTIAL_URL || '#';
	const urlPowerBI = worksite.powerbi_url || '#';

	return (
		<LinksPageLayout>
			<AccountSection>
				<h2>{i18n.t('links.account.title')}</h2>
				<div>
					<SVGContainer>
						<LinkImage1 />
					</SVGContainer>

					<div>
						<LinkButton href='/'>
							<span>{i18n.t('links.account.reports')}</span>
							<span>&gt;</span>
						</LinkButton>

						<LinkButton href={urlPowerBI} target='_blank'>
							<span>{i18n.t('links.account.data_analysis')}</span>
							<span>&gt;</span>
						</LinkButton>

						<LinkButton href={urlProfile} target='_blank'>
							<span>{i18n.t('links.account.profile')}</span>
							<span>&gt;</span>
						</LinkButton>
					</div>
				</div>
			</AccountSection>

			<AdminSection>
				<h2>{i18n.t('links.administration.title')}</h2>

				<div>
					<SVGContainer>
						<LinkImage2 />
					</SVGContainer>

					<div>
						<LinkButton href={routes.admin}>
							<span>{i18n.t('links.administration.production_management')}</span>
							<span>&gt;</span>
						</LinkButton>

						<LinkButton href={urlUsers} target='_blank'>
							<span>{i18n.t('links.administration.user_management')}</span>
							<span>&gt;</span>
						</LinkButton>
					</div>
				</div>
			</AdminSection>

			<RefSection>
				<h2>{i18n.t('links.referential.title')}</h2>

				<div>
					<SVGContainer hasLargeWidth>
						<LinkImage3 />
					</SVGContainer>

					<div>
						<LinkButton href={urlReferential}  target='_blank'>
							<span>{i18n.t('links.referential.referentials_management')}</span>
							<span>&gt;</span>
						</LinkButton>
					</div>
				</div>
			</RefSection>
		</LinksPageLayout>
	);
};

export default Links;
