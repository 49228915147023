import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from 'reportWebVitals';
import Router from 'widgets/Router';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'styles/index.css';
import './utils/lang/index';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Router />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
