import styled from "styled-components";

type Props = {
    display: "flex" | "none";
}

const Container = styled.div<Props>`
    display: ${({ display }) => display};
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .react-switch-bg {
        outline: none;
    }
`

export default Container;