import styled from 'styled-components';

export const MasterDetailViewContainer = styled.section`
	height: 100%;

	overflow-y: hidden;

	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-gap: 1em;

	& > * {
		height: 100%;
	}
`;

export const MasterView = styled.ul`
	grid-area: 1/1;

	height: 100%;
	background-color: white;

	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	list-style: none;

	padding: 0;
	margin: 0;

	border-radius: 5px;
`;

export const DetailView = styled.article`
	grid-area: 1/2;

	height: 100%;

	border-radius: 5px;
	background-color: white;

	display: flex;
	flex-direction: column;

	overflow-y: scroll;

	h2 {
		font-size: 20px;
		margin: 0 0 0.33em 0;
	}
`;

export const MasterViewItem = styled.li<{ selected?: boolean }>`
	max-width: 100%;

	background-color: ${(props) => (props.selected ? '#4D9CBF' : 'white')};
	color: ${(props) => (props.selected ? 'white' : 'black')};

	padding: 0.75em 1em;

	font-weight: 500;

	border-bottom: solid 1px #4d9cbf;

	cursor: pointer;

	position: relative;
	&::after {
		position: absolute;
		height: 1em;

		top: calc(50% - 0.5em);
		right: 1em;

		content: ${(props) =>
			props.selected
				? "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%2214%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M7.713%207%201.702.99.288%202.404l4.6%204.6-4.6%204.593%201.414%201.414L7.713%207Z%22%2F%3E%3C%2Fsvg%3E')"
				: "url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%2214%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M7.713%207%201.702.99.288%202.404l4.6%204.6-4.6%204.593%201.414%201.414L7.713%207Z%22%2F%3E%3C%2Fsvg%3E')"};
	}
`;
