import React from 'react';

const Verified: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
		<g clipPath="url(#a)">
			<path fill="#4D9CBF" d="M8.44 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
			<path fill="#fff" d="m4.033 7.86 3.473 3.473 5.34-5.34-1.326-1.327-4.02 4.02L5.353 6.54 4.026 7.867l.007-.007Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.44 0h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);

export default React.memo(Verified);
