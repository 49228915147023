import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

type DivCursorPointerProps = {
    disabled?: boolean; 
}

export const DivCursorPointer = styled.button<DivCursorPointerProps>`
    cursor: pointer;

    &[disabled] {
		cursor: not-allowed;
		filter: opacity(0.3);
	}
`

export default Container;