import { Property } from 'csstype';
import styled from 'styled-components';

import { Style } from '.';

export type Props = {
	textAlign?: Property.TextAlign;
	style?: Style;
	isZero: boolean;
};

const InputStyle = styled.input<Props>`
	min-width: 100px;
	font-weight: bold;
	flex-grow: 2;
	text-align: ${({ textAlign }) => textAlign || 'start'};

	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
	}

	${({ style }) => style === Style.Content && `
		padding: 6px;
		&:focus {
			border: 1px solid #25465F;
		}
	`}

	${({ style, isZero }) =>
		style === Style.Content &&
		(isZero
			? `
        background: #F2F2F2;
        color: rgba(0, 0, 0, 0.2);
    `
			: `
        background: #E0EDFA;
        color: black;
    `)}
	
	&[disabled] {
		cursor: not-allowed;
	}
`;

export default InputStyle;
