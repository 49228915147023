import React from 'react';

export type Props = {
	color?: string;
};

const Wind: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="WindSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<g>
				<path
					fill={color ?? '#25465F'}
					d="M14.9,8.1c0,1.5-1.2,2.7-2.7,2.7H5.9c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h6.3c0.8,0,1.5-0.7,1.5-1.5
		c0-0.8-0.7-1.5-1.5-1.5c-0.7,0-1.2,0.4-1.4,1c0,0.2-0.2,0.3-0.3,0.4s-0.3,0.1-0.5,0C9.7,8,9.5,7.6,9.6,7.3C10,6.2,11,5.4,12.2,5.4
		C13.7,5.4,14.9,6.6,14.9,8.1z"
				/>
			</g>
			<g>
				<path
					fill={color ?? '#25465F'}
					d="M21.2,10.3c0,1.5-1.2,2.7-2.7,2.7H3.3c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h15.2
		c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.7,0-1.2,0.4-1.4,1c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0
		c-0.3-0.1-0.5-0.4-0.4-0.8c0.4-1.1,1.4-1.9,2.6-1.9C20,7.6,21.2,8.8,21.2,10.3z"
				/>
			</g>
			<g>
				<path
					fill={color ?? '#25465F'}
					d="M18.2,16.8c0,1.5-1.2,2.7-2.7,2.7c-1.2,0-2.2-0.8-2.6-1.9c-0.1-0.3,0.1-0.7,0.4-0.8c0.3-0.1,0.7,0.1,0.8,0.4
		c0.2,0.6,0.8,1,1.4,1c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5H5.9c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h9.7
		C17,14,18.2,15.3,18.2,16.8z"
				/>
			</g>
		</svg>
	);
};

export default Wind;
