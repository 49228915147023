import React, { useCallback, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import ModalHeader from 'components/Modal/ModalHeader';
import Button, { Type as ButtonType } from 'components/Button';
import { SourceType } from 'api/types/source';
import { useModal } from 'components/Modal';
import i18n from 'utils/lang';

import Container, { Subtitle } from '../../../Report/components/Modal/style';
import { CounterActionType } from '../Modal/type';

const CopyOrMoveReportLineModalContainer = styled(Container)`
	width: 60rem;
	height: 20rem;
	box-sizing: border-box;
	padding: 1.33rem;

	gap: 2rem;

	& > * {
		box-sizing: border-box;
	}
`;

const RadioContainer = styled.fieldset`
	border: none;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 1.5em;

	overflow-x: auto;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.5em;

		font-size: 12px;
		font-weight: 700;

		accent-color: black;

		& > input {
			height: 2em;
			width: 2em;
		}

		* {
			margin: 0;
		}
	}
`;

type Props = {
	type: CounterActionType;
	sources: SourceType[];
	onValidate: (source: SourceType) => unknown;
	subtitle: string;
};

const CopyOrMoveReportLineModal: React.FC<Props> = ({ type, onValidate, sources, subtitle }) => {
	const modalId = useId();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { pop } = useModal();

	const [selectedSource, setSelectedSource] = useState(sources[0]);

	const hasPendingOperation = useRef(false);

	const attemptClose = useCallback(() => {
		// Prevent closing the modal if an operation (create or duplicate report) is pending
		if (hasPendingOperation.current) return;

		pop();
	}, [pop]);

	useOnClickOutside(modalRef, attemptClose);

	const handleValidate = useCallback(() => {
		onValidate(selectedSource);
	}, [selectedSource, onValidate]);

	return (
		<>
			<CopyOrMoveReportLineModalContainer
				ref={modalRef}
				flexDirection="column"
				justifyContent="space-between"
				alignItems="center"
			>
				<ModalHeader title={i18n.t(`report.copy_move_modal.${type}`)} pop={attemptClose} isTitleCentered/>
				<Subtitle>{subtitle}</Subtitle>

				{type !== 'delete' && 
				<RadioContainer>
					{sources.map((s) => (
						<div>
							<input
								key={s._id}
								type="radio"
								id={`${modalId}-${s._id}`}
								name={s.label}
								checked={selectedSource._id === s._id}
								onChange={() => setSelectedSource(s)}
							/>
							<label htmlFor={`${modalId}-${s._id}`}>{s.label}</label>
						</div>
					))}
				</RadioContainer>
				}
				<Button type={ButtonType.modalValidator} onClick={handleValidate}>
					{i18n.t(`validate`)}
				</Button>
			</CopyOrMoveReportLineModalContainer>
		</>
	);
};

export default React.memo(CopyOrMoveReportLineModal);
