import React from 'react';

const Setting: React.FC = () => {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.82 20.5H8.18004C7.71016 20.5 7.3036 20.173 7.20304 19.714L6.79604 17.83C6.25309 17.5921 5.73827 17.2946 5.26104 16.943L3.42404 17.528C2.97604 17.6709 2.48903 17.4823 2.25404 17.075L0.43004 13.924C0.197628 13.5165 0.277699 13.0025 0.623041 12.685L2.04804 11.385C1.98324 10.7961 1.98324 10.2019 2.04804 9.613L0.623041 8.316C0.27719 7.99837 0.197088 7.48372 0.43004 7.076L2.25004 3.923C2.48503 3.5157 2.97204 3.32714 3.42004 3.47L5.25704 4.055C5.5011 3.87416 5.75517 3.70722 6.01804 3.555C6.27038 3.41269 6.53008 3.28385 6.79604 3.169L7.20404 1.287C7.30411 0.827969 7.71023 0.500494 8.18004 0.5H11.82C12.2899 0.500494 12.696 0.827969 12.796 1.287L13.208 3.17C13.4888 3.29352 13.7623 3.43308 14.027 3.588C14.2743 3.73078 14.5134 3.88736 14.743 4.057L16.581 3.472C17.0287 3.32967 17.5152 3.51816 17.75 3.925L19.57 7.078C19.8025 7.48548 19.7224 7.99951 19.377 8.317L17.952 9.617C18.0168 10.2059 18.0168 10.8001 17.952 11.389L19.377 12.689C19.7224 13.0065 19.8025 13.5205 19.57 13.928L17.75 17.081C17.5152 17.4878 17.0287 17.6763 16.581 17.534L14.743 16.949C14.5105 17.1203 14.2688 17.2789 14.019 17.424C13.7569 17.5759 13.4864 17.7131 13.209 17.835L12.796 19.714C12.6956 20.1726 12.2895 20.4996 11.82 20.5ZM9.99604 6.5C7.7869 6.5 5.99604 8.29086 5.99604 10.5C5.99604 12.7091 7.7869 14.5 9.99604 14.5C12.2052 14.5 13.996 12.7091 13.996 10.5C13.996 8.29086 12.2052 6.5 9.99604 6.5Z"
				fill="#F2F2F2"
			/>
		</svg>
	);
};

export default Setting;
