import { useEffect } from 'react';

import { CategoryType } from 'api/types/category';
import { useLocalStorageState } from 'utils/common';
import { getAllCategory } from 'api/routes/category';
import { showError } from 'utils/error';
import { getRefIDByTypeRef } from 'api/routes/referential';
import { useClient } from 'providers/client';
// Caching system for categories
export const useCategory = (): Readonly<CategoryType[]> => {
	const { clientHandler: { client }, keycloak } = useClient();
	const [categories, setCategories] = useLocalStorageState<CategoryType[]>('categories-with-refID', [], keycloak);

	useEffect(() => {
		if (categories.length === 0) {
			getAllCategory(client)
				.then((e): void => {
					Promise.all(
						e
							?.sort((a, b) => a.order - b.order)
							?.map(async (c) => ({ ...c, refID: await getRefIDByTypeRef(client, c.typeReference.toString()) })),
					)
						.then(setCategories)
						.catch(showError);
				})
				.catch(showError('Failed to get all categories'));
		}
	}, []);

	return categories;
};
