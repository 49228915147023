import { AxiosInstance, AxiosResponse } from 'axios';

import { CommentType } from '../types/comment';
import type { ApiResponse } from './type';

export const getAllCommentType = async (client: AxiosInstance): Promise<CommentType[]> => {
    const {
        data: { data },
    }: AxiosResponse<ApiResponse<CommentType[]>> = await client.get('commenttype');

    return data;
};

export const getCommentTypeById = async (client: AxiosInstance, id: number): Promise<CommentType> => {
    const { data: { data } }: AxiosResponse<ApiResponse<CommentType>> = await client.get(`commenttype/${id}`);

    return data;
};
