import styled from 'styled-components';

const Container = styled.section<{ canDelete: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => (props.canDelete ? 'space-between' : 'end')};
	align-items: flex-end;
`;

export const MenuContainer = styled.div`
	display: flex;
	align-items: flex-end;

	cursor: pointer;
`;

export const ValidationContainer = styled.div`
	display: flex;
	align-items: flex-end;

	cursor: pointer;
`;

export default Container;
