import { AxiosInstance, AxiosResponse } from 'axios';

import { SourceType } from 'api/types/source';
import type { ApiResponse } from './type';

export const updateSource = async (client: AxiosInstance, data: SourceType): Promise<SourceType> => {
	const res: AxiosResponse<ApiResponse<SourceType>> = await client.put(`source/${data._id}`, data);

	return { ...data, ...res.data?.data };
};

export const fetchSource = async (client: AxiosInstance): Promise<SourceType[]> => {
	const { data }: AxiosResponse<ApiResponse<SourceType[]>> = await client.get(`source`);

	return data?.data?.sort((a, b) => a.order - b.order);
};
