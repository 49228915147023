import React from 'react';
import { TailSpin } from 'react-loader-spinner';

import Container, { FixedContainer, Card, ModalContainer } from './style';

export enum LoadingType {
	Fullscreen,
	Modal,
	Default
}


type Props = {
	type?: LoadingType;
}

const Loader: React.FC<Props> = ({ type = LoadingType.Default }) => {
	if (type === LoadingType.Fullscreen) {
		return (
			<Container>
				<TailSpin height="80" width="80" color="#25465F" ariaLabel="tail-spin-loading" radius="1" visible={true} />
			</Container>
		);
	}

	if (type === LoadingType.Modal) {
		return (
			<ModalContainer>
				<TailSpin height="80" width="80" color="#25465F" ariaLabel="tail-spin-loading" radius="1" visible={true} />
			</ModalContainer>
		)
	}

	return (
		<FixedContainer>
			<Card>
				<TailSpin height="80" width="80" color="#25465F" ariaLabel="puff-loading" visible={true} />
			</Card>
		</FixedContainer>
	)
};

export default Loader;
