import styled from 'styled-components';
import { TimerType } from '.';

type TypeProps = {
	type: TimerType;
}

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

export const SectionTitle = styled.span`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;

	margin-bottom: 0.25em;
`;

export const TimerInput = styled.select<Partial<TypeProps>>`
	width: 50px;
	height: 25px;

	background: #ffffff;
	border-radius: 6px;
	margin: ${({ type }) => type && type === TimerType.Report ? '0 6px 0 0' : '0 6px'};
	text-align: center;
	border-color: transparent;

	&[disabled] {
		cursor: not-allowed;
	}
`;

export const TimerContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const TimerChildContainer = styled.div<TypeProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ type }) => {
		switch(type) {
			case TimerType.Report:
			case TimerType.Setting: return 'flex-start'
			case TimerType.Default:
			default: return 'flex-end'; 
		}
	}};
	width: 175px;
	font-weight: bold;
`;
