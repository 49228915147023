import React from 'react';

type Props = {
	color?: string;
}

const CrossSearch: React.FC<Props> = ({ color }) => {
	return (
		<svg width="15" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path 
				d="M9.02994 0L5.43994 3.59L1.84994 0L0.439941 1.41L4.02994 5L0.439941 8.59L1.84994 10L5.43994 6.41L9.02994 10L10.4399 8.59L6.84994 5L10.4399 1.41L9.02994 0Z" 
				fill={color ?? "black"}
			/>
		</svg>
	);
};

export default CrossSearch;
