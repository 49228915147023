import { format } from 'date-fns';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { showError } from '../../utils/error';

export type ExportService = {
	name: string;
	description: string;
	type: string;
};

export type ExportCreationQuery = {
	startDate: Date;
	endDate: Date;
	users: string[];
	WorksiteId: number;
	locale: string;
}

export async function getAvailableExportServices(client: AxiosInstance): Promise<ExportService[]> {
	const { data } = await client.get<ExportService[]>(`/`);
	return data;
}

export async function createExportJob(client: AxiosInstance, type: string, query: ExportCreationQuery): Promise<string> {
	const { data } = await client.post<string, AxiosResponse<string>>(
		`/${type}?startDate=${format(query.startDate, 'yyyy-MM-dd')}&endDate=${format(query.endDate, 'yyyy-MM-dd')}&user=${query.users.join(',')}&WorksiteId=${query.WorksiteId}&locale=${query.locale}`,
	);

	return data.split(' ').at(-1) ?? '';
}

export enum ExportJobStatus {
	UNKNOWN_ID_OR_ALREADY_GOT,
	IN_PROGRESS,
	DONE,
}

export async function getExport(client: AxiosInstance, type: string, id: string): Promise<[ExportJobStatus, string | null]> {
	try {
		const { status, data } = await client.get<string>(`/${type}/${id}`);

		if (status === 202) return [ExportJobStatus.IN_PROGRESS, null];

		return [ExportJobStatus.DONE, data];
	} catch (err) {
		if (err instanceof AxiosError && err.status === 404) {
			return [ExportJobStatus.UNKNOWN_ID_OR_ALREADY_GOT, null];
		}

		showError(`Error while trying to get export`);
		console.error(err);
	}

	return [ExportJobStatus.UNKNOWN_ID_OR_ALREADY_GOT, null];
}
