import { CategoryType } from 'api/types/category';

export type FilterType = {
	name: string | null;
	color?: string;
};

export const PRODUCTION_TYPE_REFERENCE = 5;
export const EOTP_TYPE_REFERENCE = 2;

export const EOTPCategory: CategoryType = {
	_id: -2,
	name: 'EOTP',
	order: 0,
	features: 0b100011,
	typeReference: 2,
	refID: '2',
};
