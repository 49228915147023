import React, { createContext, PropsWithChildren, useEffect, useState, useContext } from "react";

import { Comment } from "api/types/comment";
import { useReports } from "providers/ReportsProvider";
import { useClient } from "providers/client";
import { createComment, getAllComment, updateComment } from "api/routes/comment";
import { showError } from "utils/error";
import Loader, { LoadingType } from "components/Loader";
import { CounterByTask } from "api/types/counter";


type TaskComment = {
    comments: Comment[];
    getCommentByTask: (task: CounterByTask) => Comment | undefined;
    updateCommentByTask: (task: CounterByTask, originalComment: Comment | undefined, newValue: string) => void;
}

const TaskCommentContext = createContext<TaskComment>({
    comments: [],
    getCommentByTask: () => undefined,
    updateCommentByTask: () => { },
})


export const TaskCommentProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { clientHandler: { client } } = useClient();
    const { currentReport } = useReports();
    const [comments, setComments] = useState<Comment[]>();
    const [refresh, setRefresh] = useState(false);

    const forceRefresh = (): void => setRefresh(!refresh);

    useEffect(() => {
        if (currentReport?._id) {
            getAllComment(client, currentReport._id, "byTask")
                .then(setComments)
                .catch(showError("Failed to fetch all comments by task"))
        }
    }, [currentReport?._id, refresh])

    if (!comments) {
        return <Loader type={LoadingType.Default} />
    }

    const getCommentByTask = (task: CounterByTask): Comment | undefined => comments.find((e) => !!e.task && e.task === task.task);
    const updateCommentByTask = (task: CounterByTask, originalComment: Comment | undefined, newValue: string): void => {
        if (!currentReport?._id) return;

        if (!originalComment) {
            const newComment = {
                content: newValue,
                task: task.task ?? undefined
            }

            createComment(client, currentReport._id, newComment)
                .then(forceRefresh)
                .catch(showError(`Failed to update comment`));
            return;
        }
        updateComment(client, currentReport._id, originalComment._id, { content: newValue })
            .then(forceRefresh)
            .catch(showError(`Failed to update comment`));
    }

    return (
        <TaskCommentContext.Provider value={{ comments, getCommentByTask, updateCommentByTask }}>
            {children}
        </TaskCommentContext.Provider>
    );

}

export const useTaskComment: () => TaskComment = () => useContext(TaskCommentContext);