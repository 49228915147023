import React from 'react';

export type Props = {
	color?: string;
};

const Snow: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="SnowSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				fill={color ?? '#25465F'}
				d="M8.6,15.1c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C8.4,14.6,8.6,14.8,8.6,15.1z"
			/>
			<circle fill={color ?? '#25465F'} cx="11.7" cy="15.4" r="0.5" />
			<path
				fill={color ?? '#25465F'}
				d="M11.3,18.1c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C11.1,17.6,11.3,17.8,11.3,18.1
	z"
			/>
			<path
				fill={color ?? '#25465F'}
				d="M14.8,17c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C14.5,16.5,14.8,16.8,14.8,17z"
			/>
			<path
				fill={color ?? '#25465F'}
				d="M16.9,15.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C16.7,14.9,16.9,15.1,16.9,15.4
	z"
			/>
			<path
				fill={color ?? '#25465F'}
				d="M16.4,18.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C16.2,18.4,16.4,18.6,16.4,18.9
	z"
			/>
			<circle fill={color ?? '#25465F'} cx="7.8" cy="18.4" r="0.5" />
			<path
				fill={color ?? '#25465F'}
				d="M20,10.6c0,1.2-1,2.2-2.2,2.2h-11C5.2,12.8,4,11.6,4,10c0-1.5,1.2-2.8,2.8-2.8C6.8,5.5,8.2,4,10,4
	c1.3,0,2.4,0.8,2.9,1.8c0.4-0.2,0.9-0.4,1.3-0.4c1.5,0,2.8,1.2,2.8,2.8c0,0.1,0,0.2,0,0.2c0.2-0.1,0.5-0.1,0.7-0.1
	C19,8.4,20,9.3,20,10.6z"
			/>
		</svg>
	);
};

export default Snow;
