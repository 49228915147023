import styled from 'styled-components';
import { Flex, Margin } from 'styles';

const Container = styled(Flex)`
	min-height: 100%;
	width: 100%;
`;

export const CardAdd = styled(Flex)`
	height: 50px;
	background-color: #ffffff;
	border-radius: 4px;
	flex: 0 0 auto;
`;

export const Card = styled(Flex)`
	max-height: 100%;
	border-radius: 8px;
	box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
	padding: 10px 16px;
	flex-grow: 1;
	overflow-y: hidden;
`;

export const CardContent = styled.div`
	margin-top: 1em;
	overflow-y: auto;
	text-align: center;
`;

export const Bold = styled.span`
	font-weight: bold;
`;

export const Title = styled(Margin)`
	font-size: 20px;
	font-weight: bold;
`;

export const CursorPointer = styled.span`
	cursor: pointer;
`;

export default Container;
