import { Property } from 'csstype';
import styled from 'styled-components';

export type FlexProps = {
	flexDirection?: Property.FlexDirection;
	alignItems?: Property.AlignItems;
	justifyContent?: Property.JustifyContent;
	width?: Property.Width;
	minWidth?: Property.MinWidth;
	margin?: Property.Margin;
	padding?: Property.Padding;
	gap?: Property.Gap;
	height?: Property.Height;
	flexGrow?: Property.FlexGrow;
	backgroundColor?: Property.BackgroundColor;
	borderRadius?: Property.BorderRadius;
};

export const Flex = styled.div<FlexProps>`
	display: flex;

	flex-direction: ${({ flexDirection }) => flexDirection ?? 'initial'};
	align-items: ${({ alignItems }) => alignItems ?? 'initial'};
	justify-content: ${({ justifyContent }) => justifyContent ?? 'initial'};
	width: ${({ width }) => width ?? 'initial'};
	min-width: ${({ minWidth }) => minWidth ?? 'initial'};
	margin: ${({ margin }) => margin ?? 'initial'};
	padding: ${({ padding }) => padding ?? 'initial'};
	gap: ${({ gap }) => gap ?? 'initial'};
	height: ${({ height }) => height ?? 'initial'};
	flex-grow: ${({ flexGrow }) => flexGrow ?? 'initial'};
	background-color: ${({ backgroundColor }) => backgroundColor ?? 'initial'};
	border-radius: ${({ borderRadius }) => borderRadius ?? 'initial'};
`;

export type MarginProps = {
	top?: number;
	right?: number;
	bottom?: number;
	left?: number;
	all?: string;

	centerContent?: boolean;
};

export const Margin = styled.div<MarginProps>`
	margin-top: ${({ top }) => top ?? 0}px;
	margin-right: ${({ right }) => right ?? 0}px;
	margin-bottom: ${({ bottom }) => bottom ?? 0}px;
	margin-left: ${({ left }) => left ?? 0}px;

	${({ all }) =>
		all &&
		`
		margin: ${all};
	`}

	display: ${(props) => (!!props.centerContent ? 'flex' : 'inherit')};
	align-items: center;
`;

export type SpacerProps = {
	space: number;
};

export const Spacer = styled.div<SpacerProps>`
	padding: ${({ space }) => `${space}px`};
`;
