import { AxiosResponse, AxiosInstance } from 'axios';
import Keycloak from "keycloak-js";
import { UserKeycloakInfo } from 'utils/type';
import type { ApiResponse } from './type';

export const createMe = async (client: AxiosInstance, keycloak: Keycloak): Promise<UserResponse | undefined> => {
	if (keycloak.subject === undefined) throw Error('Keycloak user is not setup');
	let userInfo: UserKeycloakInfo = {
		sub: '',
		email_verified: false,
		roles: [],
	};
	await keycloak.loadUserInfo().then((userRead) => {
		userInfo = userRead as UserKeycloakInfo;
	});

	const body: UserCreate = {
		_id: keycloak.subject,
		userName: userInfo && userInfo.preferred_username ? userInfo.preferred_username : '',
		displayName: userInfo.name ? userInfo.name : '',
		settings: '{}',
	};
	const { data }: AxiosResponse<ApiResponse<UserResponse[]>> = await client.post('user', body);

	if (data?.data && data?.data.length === 1) {
		const [first] = data.data;

		return first;
	}
};

export const updateUser = async (client: AxiosInstance, keycloak: Keycloak, body: UserUpdate): Promise<UserResponse | undefined> => {
	const { data }: AxiosResponse<ApiResponse<UserResponse[]>> = await client.put(`user/${keycloak.subject || ''}`, body);

	if (data?.data && data?.data.length === 1) {
		const [first] = data.data;

		return first;
	}
};

export const getMe = async (client: AxiosInstance, keycloak: Keycloak): Promise<UserResponse | undefined> => {
	const { data }: AxiosResponse<ApiResponse<UserResponse[]>> = await client.get(`user/?_id=${keycloak.subject || ''}`);

	if (data?.data && data?.data.length === 1) {
		const [first] = data.data;

		return first;
	}
};

export const userList = async (client: AxiosInstance): Promise<UserResponse[]> => {
	const res: AxiosResponse<ApiResponse<UserResponse[]>> = await client.get('user');

	return res.data?.data;
};

export const userWithReportList = async (client: AxiosInstance, startDate: Date, endDate: Date): Promise<UserResponse[]> => {
	const res: AxiosResponse<ApiResponse<UserResponse[]>> = await client.get(`user/report?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}`);

	return res.data?.data;
}