import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import { showError } from 'utils/error';
import { ReportType } from 'api/types/report';
import { CommentTypeExtended } from 'api/types/comment';
import { getAllCommentType } from 'api/routes/commentType';
import { useClient } from 'providers/client';
import { createComment, getAllComment, updateComment } from 'api/routes/comment';

type CommentContextProps = {
    comment: string;
    commentTypes: CommentTypeExtended[];
    setComment: React.Dispatch<React.SetStateAction<string>>;
    updateCommentType: (value: string, commentType: CommentTypeExtended) => void;
};

const CommentContext = createContext<CommentContextProps>({
    comment: '',
    commentTypes: [],
    setComment: () => { },
    updateCommentType: () => { },
});

type Props = {
    currentReport: ReportType | null,
    updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>
}

export const CommentProvider: React.FC<PropsWithChildren & Props> = ({ children, currentReport, updateReport }) => {
    const { clientHandler: { client } } = useClient();
    const [comment, setComment] = useState<string>(currentReport?.description ?? '');
    const [commentTypes, setCommentTypes] = useState<CommentTypeExtended[]>([]);
    const [refresh, setRefresh] = useState(false);
    const debouncedComment = useDebounce(comment, 750);

    const updateCommentType = (value: string, commentType: CommentTypeExtended): void => {
        if (!currentReport?._id) return;

        if (!commentType.comment._id) {
            const newComment = {
                content: value,
                CommenttypeId: commentType._id
            }

            createComment(client, currentReport._id, newComment)
                .then(() => setRefresh(!refresh))
                .catch(showError(`Failed to update comment`));
        } else {
            updateComment(client, currentReport._id, commentType.comment._id, { content: value })
                .then(() => setRefresh(!refresh))
                .catch(showError(`Failed to update comment`));
        }
    }

    useEffect(() => {
        updateReport('description', debouncedComment ?? '').catch(showError("Unable to update description"));
    }, [debouncedComment]);

    useEffect(() => {
        if (!currentReport?._id) return;
        const reportId = currentReport._id;
        getAllCommentType(client).then((commentTypesResults) => {
            getAllComment(client, reportId, 'ByComment').then((commentsResults) => {
                const newCommentTypes = commentTypesResults
                    .filter((e) => !e.disabled)
                    .map((e): CommentTypeExtended => {
                        const matchingComment = commentsResults.find(cr => cr.CommenttypeId === e._id);

                        if (matchingComment) return {
                            ...e,
                            comment: matchingComment,
                        };

                        return {
                            ...e,
                            comment: {
                                content: '',
                                CommenttypeId: e._id,
                            }
                        }
                    });
                setCommentTypes(newCommentTypes);
            }).catch(showError("Failed to get comments by CommentTypes"));
        }).catch(showError("Failed to get commentTypes"));
    }, [currentReport, refresh]);

    return <CommentContext.Provider value={{ comment, commentTypes, setComment, updateCommentType }}>{children}</CommentContext.Provider>;
};

export const useComment: () => CommentContextProps = () => useContext(CommentContext);
