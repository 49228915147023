import React from 'react';

type Props = {
	color?: string;
}

const StatusCheck: React.FC<Props> = ({ color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.38989 11.7942L10.5957 17L18.6101 8.9856L16.6244 6.99991L10.5957 13.0286L7.37559 9.80853L5.38989 11.7942Z"
				fill={color ?? "#FFF"}
			/>
		</svg>
	);
};

export default StatusCheck;
