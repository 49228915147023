import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex } from 'styles';
import ReportWidget from 'widgets/Report';
import ReportHeader from 'widgets/ReportHeader';
import { useReports } from 'providers/ReportsProvider';
import routes from 'utils/routes';
import { ReportProvider } from 'widgets/Report/provider';

const Report: React.FC = () => {
	const navigate = useNavigate();
	const { currentReport } = useReports();

	useEffect(() => {
		if (currentReport == null) {
			navigate(routes.home);
		}
	}, [currentReport, navigate]);

	return (
		<Flex flexDirection="column" height="100%" key={`Report-${currentReport?._id ?? 'null'}-${currentReport?.status ?? 'null'}`}>
			<ReportProvider>
				<ReportHeader />
				<ReportWidget />
			</ReportProvider>
		</Flex>
	);
};

export default Report;
