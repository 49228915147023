import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import { useReports } from 'providers/ReportsProvider';
import { Margin } from 'styles';
import { TimerChildContainer, TimerContainer, TimerInput } from './style';

export enum TimerType {
	Default,
	Report,
	Setting
}

type TimerProps = {
	title?: string | null;
	time?: string | null;
	type?: TimerType;
	value: number;
	onValueChange: (minutes: number) => unknown;
	settings?: boolean;
	firstChildAlignedRight?: boolean;
	justifyContent?: string;
};

export const Timer: React.FC<TimerProps> = ({ title, time, value, onValueChange, type = TimerType.Default, settings = false }) => {
	const { canEdit } = useReports();
	const [hours, setHours] = useState(Math.floor(value / 60));
	const [minutes, setMinutes] = useState(value - 60 * hours);

	const timeAsMinutes = useMemo(() => hours * 60 + minutes, [hours, minutes]);
	const debouncedTimeAsMinutes = useDebounce(timeAsMinutes, 750);

	useEffect(() => {
		onValueChange(debouncedTimeAsMinutes);
	}, [debouncedTimeAsMinutes]);

	return (
		<TimerContainer>
			{title && title != null && <Margin bottom={4}>{title}</Margin>}
			<TimerChildContainer type={type}>
				{time && <span>{time}</span>}
				<TimerInput type={type} disabled={!canEdit && !settings} value={hours} onChange={(e) => setHours(+e.target.value)}>
					{Array.from({ length: 24 }, (_, i) => i).map((hour) => (
						<option key={hour} value={hour}>
							{hour}
						</option>
					))}
				</TimerInput>
				<span>{i18n.t('hours')}</span>
				<TimerInput disabled={!canEdit && !settings} value={minutes} onChange={(e) => setMinutes(+e.target.value)}>
					<option value={0}>00</option>
					<option value={15}>15</option>
					<option value={30}>30</option>
					<option value={45}>45</option>
				</TimerInput>
				<span>{i18n.t('minutes')}</span>
			</TimerChildContainer>
		</TimerContainer>
	);
};


