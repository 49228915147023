import React from 'react';
import RSwitch from 'react-switch';

import Container from './style';

type Props = {
	value: boolean;
	onChange: () => void;
	onColor: string;
};

const Switch: React.FC<Props> = ({ onChange, value, onColor }) => {
	return (
		<Container>
			<RSwitch
				height={25}
				width={50}
				onColor={onColor}
				offColor="#FFFFFF"
				offHandleColor="#4D9CBF"
				onChange={onChange}
				checked={value}
				checkedIcon={false}
				uncheckedIcon={false}
		
			/>
		</Container>
	);
};

export default Switch;
