import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import i18n from 'i18next';
import {
	AddEotpButton,
	CounterItem,
	CounterItemCheckbox,
	CounterItemLabel,
	CounterItemsContainer,
	CounterItemUnit,
	Page,
	PageActions,
} from './style';
import { usePreparedCounters } from './usePreparedCounters';
import { DetailView, MasterDetailViewContainer, MasterView, MasterViewItem } from 'widgets/MasterDetail';
import Search from 'components/Search';
import Button, { Type as ButtonType } from 'components/Button';
import Add from 'assets/svg/Add';
import { useSearch } from 'components/Search/useSearch';
import { useModal } from 'components/Modal';
import { AdminAddEotpModal } from './components/AdminAddEotpModal';
import { useRBAC, UserRoles } from 'providers/user';
import * as R from 'ramda';
import EOTPGroupRadioSelect from './components/EOTPGroupRadioSelect';
import { useUpdateEffect } from 'usehooks-ts';

const useMasterDetail = (): {
	activeMasterIndex: number;
	activeDetails: unknown[] | null;
	handleMasterChange: (n: number) => void;
} => {
	const [activeMasterIndex, setActiveMasterIndex] = useState(-1);

	const handleMasterChange = useCallback((newIndex: number) => {
		setActiveMasterIndex((currentMaster) => (currentMaster === newIndex ? -1 : newIndex));
	}, []);

	return {
		activeMasterIndex,
		activeDetails: null,
		handleMasterChange,
	};
};

const AdminPage: React.FC = () => {
	useRBAC({
		anyRoleIn: [UserRoles.Administrator],
		redirectTo: '/',
	});

	const { preparedCounters, toggleGroup, addEOTP } = usePreparedCounters();
	const { filteredItems, searchValue, handleSearchChange, setSearchValue } = useSearch(preparedCounters ?? [], ['task', 'label']);
	const { activeMasterIndex, handleMasterChange } = useMasterDetail();
	const lastKnownCounters = useRef([...preparedCounters]);
	const { push } = useModal();

	// Ensure we never exceeds the maximum active index
	useEffect(() => {
		handleMasterChange(-1);
	}, [searchValue]);

	const activeCounter = filteredItems?.at(activeMasterIndex);
	const activeTask = activeCounter?.task ?? '';

	const groups = useMemo(() => {
		return R.uniq(preparedCounters.flatMap((counter) => counter.groups.filter(Boolean))) as [string, ...string[]];
	}, [preparedCounters]);

	useUpdateEffect(() => {
		if (lastKnownCounters.current.length !== 0 && lastKnownCounters.current.length !== preparedCounters.length) {
			handleMasterChange(preparedCounters.length - 1);

			document.querySelector(`[data-master-key="${preparedCounters.length - 1}"]`)?.scrollIntoView({
				behavior: 'smooth',
			});
		}
		lastKnownCounters.current = [...preparedCounters];
	}, [preparedCounters]);

	return (
		<Page>
			<PageActions>
				<Search placeholder={i18n.t('search') ?? 'Search'} value={searchValue} onChange={handleSearchChange} clearText={() => setSearchValue('')} />
				<div>
					<AddEotpButton
						onClick={() => {
							push(<AdminAddEotpModal addEotp={addEOTP} groups={groups} />);
						}}
					>
						<b>{i18n.t('add_eotp')}</b>
						<Button type={ButtonType.fourth}>
							<Add />
						</Button>
					</AddEotpButton>
				</div>
			</PageActions>
			<MasterDetailViewContainer>
				<MasterView>
					{filteredItems?.map((counter, index) => (
						<MasterViewItem
							key={counter._id}
							data-master-key={index}
							selected={activeMasterIndex === index}
							onClick={() => handleMasterChange(index)}
						>
							{counter.label ? `${counter.label}` : i18n.t('unknown') }&nbsp;({counter.task})
						</MasterViewItem>
					)) ?? <></>}
				</MasterView>
				{activeMasterIndex !== -1 && (
					<DetailView>
						<EOTPGroupRadioSelect
							groups={groups}
							activeGroup={activeCounter?.activeGroup}
							onGroupChange={(newGroup) => toggleGroup(activeTask, newGroup)}
						/>

						<CounterItemsContainer>
							{activeCounter?.items?.sort((a, b) => a.label.localeCompare(b.label)).map((item) => (
								<CounterItem key={item.id}>
									<CounterItemLabel>
										<CounterItemCheckbox
											type="checkbox"
											checked={item.checked}
											onChange={() => void item.toggle(activeTask, { isChecked: item.checked })}
										/>
										<span>{item.label}</span>
									</CounterItemLabel>
									<CounterItemUnit>{item.unit}</CounterItemUnit>
								</CounterItem>
							)) ?? ''}
						</CounterItemsContainer>
					</DetailView>
				)}
			</MasterDetailViewContainer>
		</Page>
	);
};

export default React.memo(AdminPage);
