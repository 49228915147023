import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    & > div {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
    }
`;

export const InputContainer = styled.div`
`

export const TemperatureInput = styled.input`
    background-color: #ffffff;
    width: 70px;
    height: 25px;
    border-radius: 6px;
    padding: 0 6px;
`

export const BolderText = styled.b`
    font-weight: 700;
    font-size: 14;
`;


export default Container;