import styled from 'styled-components';
import { Flex } from 'styles';

export const Container = styled(Flex)`
	z-index: 1;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	justify-content: center;
	align-items: center;
`;
type ModalContainerPropsType = {
	customHeight?: string;
	maxWidth?: string;
};

export const ModalContainer = styled(Flex)<ModalContainerPropsType>`
	padding: 32px;
	gap: 16px;

	width: 80%;
	height: ${({customHeight}) => customHeight || "75%"};
	max-width: ${({maxWidth}) => maxWidth || "1439px"};

	background: #f2f2f2;
	border-radius: 8px;
	flex-direction: column;
`;

export default Container;
