import React from 'react';

import Calendar from 'widgets/Calendar';
import { Flex } from 'styles';

const Home: React.FC = () => {
	return (
		<Flex height={'100%'}>
			<Calendar />
		</Flex>
	);
};

export default Home;
