import React from 'react';

type Props = {
	color?: string;
};

const Calendar: React.FC<Props> = ({ color }) => {
	return (
		<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.2085 20.0834H2.79169C1.73313 20.0834 0.875 19.2252 0.875 18.1667V4.74988C0.875 3.69132 1.73313 2.83319 2.79169 2.83319H4.70837V0.916504H6.62506V2.83319H12.3751V0.916504H14.2918V2.83319H16.2085C17.267 2.83319 18.1252 3.69132 18.1252 4.74988V18.1667C18.1252 19.2252 17.267 20.0834 16.2085 20.0834ZM2.79169 8.58325V18.1667H16.2085V8.58325H2.79169ZM2.79169 4.74988V6.66656H16.2085V4.74988H2.79169ZM14.2918 16.25H12.3751V14.3333H14.2918V16.25ZM10.4584 16.25H8.54174V14.3333H10.4584V16.25ZM6.62506 16.25H4.70837V14.3333H6.62506V16.25ZM14.2918 12.4166H12.3751V10.4999H14.2918V12.4166ZM10.4584 12.4166H8.54174V10.4999H10.4584V12.4166ZM6.62506 12.4166H4.70837V10.4999H6.62506V12.4166Z"
				fill={color ?? 'white'}
			/>
		</svg>
	);
};

export default Calendar;
