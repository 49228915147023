import styled from 'styled-components';
import { Type } from '.';

type Props = {
	variant: Type;
	paddingY?: number;
	paddingX?: number;
	color?: string;
};

const Button = styled.button<Props>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: ${({ paddingY }) => paddingY ?? 0}px ${({ paddingX }) => paddingX ?? 10}px;
	margin: 0 5px;

	&[disabled] {
		cursor: not-allowed;
		filter: opacity(0.5);
	}

	${({ variant }) =>
		(variant !== Type.colorized && variant !== Type.meteo && variant !== Type.meteoSelected) &&
		`
		min-width: 35px;
		height: 35px;
	`}

	border-radius: 6px;

	${({ variant }) =>
		(variant === Type.primary || variant === Type.fourth || variant === Type.meteoSelected || variant === Type.calendarHeader || variant === Type.modalValidator) &&
		`
        opacity: 1;
        color: #FFFFFF;
        background: #4D9CBF;
    `}

	${({ variant }) => variant === Type.primary && `
		padding: 0;
		min-width: 25px;
		height: 25px;
	`}

	${({ variant }) =>
		variant === Type.secondary &&
		`
        opacity: 0.5;
        color: black;
        border: 2px solid #25465F;
        background-color: transparent;
    `}

	${({ variant }) =>
		(variant === Type.third || variant === Type.meteo) &&
		`
		padding: 0;
		background-color: white;
	`}

	${({ variant }) =>
		(variant === Type.fourth || variant === Type.meteoSelected) &&
		`
		padding: 0;
		background-color: #25465F;
	`}

	${({ variant }) =>
		(variant === Type.filter || variant === Type.filterSelected) && `
		min-width: unset;
		padding: 0 6px;
	`}

	${({ variant }) =>
		variant === Type.filter &&
		`
        border: 2px solid #25465F;
		background-color: transparent;
		color: black;
		height: 30px;
		width: 30px;
		margin: 0;
	`}

	${({ variant }) =>
		variant === Type.filterSelected &&
		`
		border: 2px solid transparent;
        background-color: #25465F;
		color: #ffffff;
		height: 30px;
		width: 30px;
		margin: 0;
	`}

	${({ variant, color }) =>
		!!color &&
		variant === Type.colorized &&
		`
		background-color: ${color};
		border-radius: 0;
		min-width: 27px;
		min-height: 27px;
		padding: 0;
		cursor: unset;
	`}

	${({ variant }) => (variant === Type.meteo || variant === Type.meteoSelected) && `
		min-width: 27px;
		height: 27px;
		margin: 0 5px 0 0;
	`}

	${({ variant }) => (variant === Type.report || variant === Type.reportSelected) && `
		border-width: 0;
		background-color: white;
		padding: 0;
		min-width: 25px;
		height: 25px;

		&:disabled {
			background-color: transparent;
		}
	`}

	${({ variant }) => (variant === Type.reportSelected) && `
		background-color: #25465F;
	`}

	${({ variant }) =>
		variant === Type.modalValidator &&
		`
		width: 190px;
		height: 40px;
		padding: 0;
		margin: 0;
		font-size: 18px;
		font-weight: bold;
	`}
`;

export default Button;
