import React from 'react';

import i18n from 'utils/lang';
import { ReportType } from 'api/types/report';

import { WeatherList } from '../Weather';
import { Timer, TimerType } from '../WorkHours';

import Container from './style';

type Props = {
    time: 'start' | 'end',
    currentReport: ReportType | null,
    updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>
}

const Shift: React.FC<Props> = ({ time, currentReport, updateReport }: Props) => {
    return (
        <Container>
            <div>
                <span>{time === 'start' ? i18n.t('start_working') : i18n.t('end_working')}</span>
            </div>
            {
                time === 'start' ? (
                    <>
                        <Timer
                            type={TimerType.Report}
                            value={currentReport?.startTime ?? 0}
                            onValueChange={(value) => updateReport('startTime', value)}
                        />
                        <WeatherList
                            time="from" weather={currentReport?.weatherAtStart ?? null}
                            onUpdate={(value) => updateReport('weatherAtStart', value)}
                        />
                    </>
                ) : (
                    <>
                        <Timer
                            type={TimerType.Report}
                            value={currentReport?.endTime ?? 0}
                            onValueChange={(value) => updateReport('endTime', value)}
                        />
                        <WeatherList
                            time="to"
                            weather={currentReport?.weatherAtEnd ?? null}
                            onUpdate={(value) => updateReport('weatherAtEnd', value)}
                        />
                    </>
                )
            }

        </Container>
    )
}


export default Shift;