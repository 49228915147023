import React from 'react';

export type Props = {
	color?: string;
};

const Fog: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="FogSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color ?? '#25465F'}
				d="M20,16.5c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.2l-2.4,0.7l-0.4,1.3c0,0.1-0.1,0.2-0.2,0.3
	c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.3-0.6l0.2-0.8l-0.2,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0l-1.9-0.5l-0.4,1.5
	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.3-0.6l0.4-1.5L12.2,17c-0.1,0-0.2,0-0.4,0l-1.2,0.3l-0.4,1.6
	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.4-0.6l0.3-1l-1.1,0.3c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0
	l-0.9-0.2l-0.3,1.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.3-0.1-0.4-0.3-0.3-0.6l0.3-1.2l-1.9-0.5
	c-0.1,0-0.2-0.1-0.3-0.2C4,16.6,4,16.5,4,16.3c0.1-0.3,0.4-0.4,0.6-0.4l1.9,0.5L6.9,15c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5,0,0.7,0.2
	c0.1,0.1,0.1,0.2,0,0.4l-0.4,1.5l0.6,0.2c0.1,0,0.2,0,0.4,0l1.4-0.4l0.4-1.5c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5,0,0.7,0.2h0
	c0.1,0.1,0.1,0.2,0,0.4l-0.3,1l0.9-0.2c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0l0.9,0.2l0.3-1.2c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5,0,0.7,0.2
	c0.1,0.1,0.1,0.2,0,0.4L14,16.5l1.6,0.4c0.1,0,0.2,0,0.4,0l0.4-0.1l0.5-1.8c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5,0,0.7,0.2
	c0.1,0.1,0.1,0.2,0,0.4l-0.3,1.2l1.9-0.5c0.3-0.1,0.5,0.1,0.6,0.4C20,16.4,20,16.4,20,16.5z"
			/>
			<path
				fill={color ?? '#25465F'}
				d="M20,10.6c0,1.2-1,2.2-2.2,2.2h-11C5.2,12.8,4,11.6,4,10c0-1.5,1.2-2.8,2.8-2.8C6.8,5.5,8.2,4,10,4
	c1.3,0,2.4,0.8,2.9,1.8c0.4-0.2,0.9-0.4,1.3-0.4c1.5,0,2.8,1.2,2.8,2.8c0,0.1,0,0.2,0,0.2c0.2-0.1,0.5-0.1,0.7-0.1
	C19,8.4,20,9.3,20,10.6z"
			/>
		</svg>
	);
};

export default Fog;
