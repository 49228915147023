import Cross from "assets/svg/Cross";
import { CrossContainer, ModalHeaderContainer, Title, TitleContainer } from "./style";

type ModalHeaderProps = {
	title: string;
	pop: () => void;
    isTitleCentered?: boolean
};

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, pop, isTitleCentered }) => {
    return (
        <ModalHeaderContainer>
            <TitleContainer justifyContent={isTitleCentered ? "center" :"space-between"}>
                <Title>{title}</Title>
            </TitleContainer>
            <CrossContainer onClick={pop}>
                <Cross />
            </CrossContainer>
        </ModalHeaderContainer>
    )
}

export default ModalHeader;