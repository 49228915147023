import { FilterType } from 'utils/constants';

export type ToggleableFilterType = FilterType & {
    idx: number;
    active: boolean;
    toggle: VoidFunction;
};

export function getFilterColor(filterName: string | null): string {
    if (filterName === null || filterName === '') return 'rgb(242, 242, 242)';

    const availableColors = ['#EA8100', '#3370F3', '#60A963', '#25465F', '#E87671', '#4D9CBF', '#4D58A1', '#BDD344'];
    const filtersColors = JSON.parse(localStorage.getItem('filter-colors') ?? '{}') as Record<string, string>;

    if (filtersColors[filterName]) return filtersColors[filterName];

    const alreadyUsedColors = Object.keys(filtersColors).map((name) => filtersColors[name]);
    const newColorIndex = alreadyUsedColors.length % availableColors.length;
    filtersColors[filterName] = availableColors[newColorIndex];

    localStorage.setItem('filter-colors', JSON.stringify(filtersColors));

    return filtersColors[filterName];
}