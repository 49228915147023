import React, { useEffect, useState } from 'react';

import { CategoryType } from 'api/types/category';
import { ReferentialResponse } from 'api/types/ref';
import Add from 'assets/svg/Add';
import Cross from 'assets/svg/Cross';
import Button, { Type } from 'components/Button';
import { useModal } from 'components/Modal';
import { TaskPreferences, usePreferences } from 'providers/preferences';
import { Flex, Margin } from 'styles';

import i18n from 'utils/lang';
import Modal from './modal';
import Container, { Bold, Card, CardAdd, CardContent, CursorPointer, Title } from './style';

type Props = {
	category: CategoryType;
};

const FilterTask: React.FC<Props> = ({ category }) => {
	const {
		preferences: { tasks },
		setTaskPreferences,
	} = usePreferences();
	const { push } = useModal();
	const [favTasks, setFavTasks] = useState<ReferentialResponse[]>([]);

	useEffect(() => {
		setFavTasks(
			tasks
				?.find((t) => t.categoryID === category._id)
				?.refs.filter((ref): ref is ReferentialResponse => ref !== undefined) ?? [],
		);
	}, [tasks]);

	const deleteTaskPref = (referential: ReferentialResponse): void => {
		const taskCopy: TaskPreferences[] | undefined = tasks;
		const idxOfCategory = taskCopy?.findIndex((e) => e.categoryID === category._id) ?? -1;
		if (taskCopy && idxOfCategory !== -1) {
			taskCopy[idxOfCategory] = {
				categoryID: category._id,
				refs: taskCopy[idxOfCategory].refs.filter((r) => r.id !== referential.id),
			};
			setTaskPreferences([...taskCopy]);
			setFavTasks(taskCopy[idxOfCategory].refs);
		}
	};

	return (
		<Container flexDirection="column">
			<Title bottom={10}>{category.name}</Title>
			<Card flexDirection="column">
				<CardAdd justifyContent="center" alignItems="center">
					<Button
						type={Type.primary}
						onClick={() => {
							push(<Modal category={category} favTasks={favTasks} setFavTasks={setFavTasks} openedFromFavorites />);
						}}
					>
						<Add />
					</Button>
				</CardAdd>
				<CardContent>
					{favTasks &&
						favTasks.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? '')).map((referential) => (
							<Margin key={`FilterTask-task-${category.typeReference}-${referential.id}`} top={10} bottom={5}>
								<Flex justifyContent="space-between" alignItems="center">
									<Bold>{referential.label ? referential.label + ' (' + referential.id + ')' : referential.label}</Bold>
									<CursorPointer onClick={() => deleteTaskPref(referential)}>
										<Cross />
									</CursorPointer>
								</Flex>
							</Margin>
						))}
					{(!favTasks || favTasks.length === 0) &&
						<Bold>{i18n.t('noSelectedFilter')}</Bold>
					}
				</CardContent>
			</Card>
		</Container>
	);
};

export default FilterTask;
