import styled from 'styled-components';

export const FlexCol = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1em;
`;

export const WeatherListContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
