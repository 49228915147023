import React from 'react';

export enum MenuType {
	Vertical,
	Horizontal,
	Disabled
}

export type Props = {
	color?: string;
	type?: MenuType
};

const Menu: React.FC<Props> = ({ color, type = MenuType.Vertical }) => {
	return (
		<svg
			style={type === MenuType.Horizontal ? { transform: 'rotate(90deg)' } : {}}
			cursor={type === MenuType.Disabled ? 'not-allowed' : 'pointer'}
			width="4"
			height="16"
			viewBox="0 0 4 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 -5.63677e-07 3.10457 -6.11959e-07 2C-6.60242e-07 0.89543 0.89543 -3.91405e-08 2 -8.74228e-08C2.53043 -1.10609e-07 3.03914 0.210714 3.41421 0.585787C3.78929 0.960859 4 1.46957 4 2ZM4 8C4 9.10457 3.10457 10 2 10C0.89543 10 -3.01409e-07 9.10457 -3.49691e-07 8C-3.97973e-07 6.89543 0.89543 6 2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8ZM4 14C4 15.1046 3.10457 16 2 16C0.89543 16 -3.91405e-08 15.1046 -8.74228e-08 14C-1.35705e-07 12.8954 0.89543 12 2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14Z"
				fill={color ?? '#25465F'}
			/>
		</svg>
	);
};

export default Menu;
