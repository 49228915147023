import React, { useCallback, useContext } from "react";

import { createCheckedCounterForReport, deleteCheckedCounter, updateCheckedCounter } from "api/routes/checkedCounter";
import { createCounter, deleteCounter, updateCounter } from "api/routes/counter";
import { DropdownButton, DropdownMenu } from "widgets/Navbar/style";
import { CounterType, } from "api/types/counter";
import Dropdown, { DropdownType } from "components/Dropdown";
import { useReports } from "providers/ReportsProvider";
import Menu, { MenuType } from "assets/svg/Menu";
import { ReportStatus } from "api/types/report";
import { SourceType } from "api/types/source";
import { useClient } from "providers/client";
import { useModal } from "components/Modal";
import Trashcan from "assets/svg/Trashcan";
import { TaskType } from "api/types/task";
import Move from "assets/svg/Move";
import Copy from "assets/svg/Copy";
import i18n from "utils/lang";

import { ReportContext } from '../../provider';
import CopyOrMoveReportLineModal from "../Modal/CopyOrMoveReportLineModal";
import { CounterActionType } from "../Modal/type";

type Props = {
	canDelete: boolean;
	subtitle: string;
	sources: SourceType[];
	tasks: TaskType[];
	counter: CounterType;
}

const CounterByDropdown: React.FC<Props> = ({ canDelete, subtitle, sources, tasks, counter }) => {
	const { push, pop } = useModal();
	const { canEdit, currentReport } = useReports();
	const { refresh, setRefresh } = useContext(ReportContext);
	const { clientHandler: { client } } = useClient();

	const createCounterFn = canEdit && currentReport?.status === ReportStatus.Validated ? createCheckedCounterForReport(currentReport?._id ?? -1) : createCounter;
	const copyCounterToSource = useCallback(
		(selectedSource: SourceType) => {
			void (async () => {
				await Promise.allSettled(
					tasks.map((t) => {
						try {
							// We don't want _id to copy because it will lead to a duplicate item
							const { _id: id, ...counterWithoutId } = counter;
							return createCounterFn(client, {
								...counterWithoutId,
								task: t.task,
								SourceId: selectedSource._id.toString(),
								quantity: t.quantity,
							});
						} catch (message) {
							return console.error(message);
						}
					}),
				);

				setRefresh(!refresh);
			})();
		},
		[tasks, counter._id],
	);

	const updateCounterFn = canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;
	const moveCounterToSource = useCallback(
		(selectedSource: SourceType) => {
			void (async () => {
				await Promise.allSettled(
					tasks.map(async (t): Promise<CounterType|undefined> => {
						try {
							if (!t._id) return undefined;
							return await updateCounterFn(
								client,
								{
									...counter,
									quantity: t.quantity,
									SourceId: selectedSource._id.toString(),
								},
								t._id.toString(),
							);
						} catch (err) {
							console.error(err);
						}
					}),
				);

				setRefresh(!refresh);
			})();
		},
		[tasks, counter._id],
	);

	const deleteCounterFn = canEdit && currentReport?.status === ReportStatus.Validated ? deleteCheckedCounter : deleteCounter;
	const deleteCounterToSource = useCallback(
		() => {
			void (async () => {
				await Promise.allSettled(
					tasks.map(async (t) => {
						try {
							if (t._id == null) return;
							return await deleteCounterFn(client, t._id.toString())
						} catch (err) {
							console.error(err);
						}
					}),
				);

				setRefresh(!refresh);
			})();
		},
		[tasks],
	);

	const counterAction = useCallback((type: CounterActionType) => {
		push(
			<CopyOrMoveReportLineModal
				type={type}
				subtitle={subtitle}
				sources={sources}
				onValidate={(s) => {
					switch (type) {
						case 'copy': copyCounterToSource(s);
							break;
						case 'move': moveCounterToSource(s);
							break;
						case 'delete': deleteCounterToSource();
					}
					pop();
				}}
			/>,
		);
	}, [subtitle, sources]);

	return (
		<Dropdown
			type={canEdit ? DropdownType.Menu : DropdownType.Disabled}
			titleComponent={
				<Menu type={canEdit ? MenuType.Horizontal : MenuType.Disabled} />
			}>
			<DropdownMenu>
				<DropdownButton onClick={() => counterAction('move')}>
					{i18n.t('report.copy_move_modal.move_btn')}
					<Move />
				</DropdownButton>
				<DropdownButton onClick={() => counterAction('copy')}>
					{i18n.t('report.copy_move_modal.copy_btn')}
					<Copy />
				</DropdownButton>
				{canDelete &&
					<DropdownButton onClick={() => counterAction('delete')}>
						{i18n.t('report.copy_move_modal.delete_btn')}
						<Trashcan />
					</DropdownButton>
				}
			</DropdownMenu>
		</Dropdown>
	)
}


export default CounterByDropdown;