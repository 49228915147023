import { AxiosError, AxiosInstance } from 'axios';
import { TaskPreferences } from 'providers/preferences';
import { EOTPCategory } from 'utils/constants';
import { PreparedCounterResponse } from '../types/preparedCounter';

export async function getPreparedCounters(client: AxiosInstance, categoryId: number): Promise<PreparedCounterResponse[]> {
	const {
		data: { data },
	} = await client.get<{ data: PreparedCounterResponse[] }>(`preparedCounter?CategoryId=${categoryId}`);

	return data;
}

export async function checkPreparedCounterItem(client: AxiosInstance, counter: PreparedCounterResponse): Promise<PreparedCounterResponse> {
	delete counter._id;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-explicit-any
	const result: any = await client.post<PreparedCounterResponse>(`preparedCounter`, counter);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
	return !result.data ? null : result.data.data ? result.data.data : result.data; // TODO: Fix this
}

export async function addPreparedCounterItem(
	client: AxiosInstance,
	counter: Omit<PreparedCounterResponse, '_id'>,
): Promise<PreparedCounterResponse | null> {
	try {
		const result = await client.post<{ data: PreparedCounterResponse }>(`preparedCounter`, counter);

		if (result instanceof AxiosError && result.response?.status === 409) {
			return (result.response?.data as { data: PreparedCounterResponse }).data;
		}

		return result.data.data;
	} catch (err) {
		// This simply means the prepared counter already exists, fail silently without consequences
		if (err instanceof AxiosError && err.response?.status === 409) {
			return err.response.data as PreparedCounterResponse;
		}

		console.error(`Error while adding prepared counter item`, err);
		return null;
	}
}

export async function uncheckPreparedCounterItem(client: AxiosInstance, counter: PreparedCounterResponse): Promise<void> {
	if (!counter._id) {
		console.error('uncheckPreparedCounterItem: counter._id is undefined');
		return;
	}
	await client.delete(`preparedCounter/${counter._id}`);
}

export async function generatePreparedCounter(client: AxiosInstance, {
	tasks,
	CategoryId,
	date,
}: {
	tasks: TaskPreferences[];
	CategoryId: number;
	date: string;
}): Promise<void> {
	tasks = tasks.filter((t) => t.categoryID === EOTPCategory._id);
	await client.post(`preparedCounter/generate`, {
		date,
		CategoryId,
		tasks: tasks.map((t) => t.refs.map((r) => r.id).join('|')).join(''),
	});
}

export async function generateGenericPreparedCounter(client: AxiosInstance, {
	refs,
	CategoryId,
	date,
}: {
	refs: { id: string; }[];
	CategoryId: number;
	date: string;
}): Promise<void> {
	await client.post(`preparedCounter/generate`, {
		date,
		CategoryId,
		tasks: refs.map((r) => r.id).join('|'),
	});
}
