import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

import { Flex } from "styles";
import routes from "utils/routes";
import ArrowLeft from "assets/svg/ArrowLeft";
import { ReportType } from "api/types/report";
import { getLocaleFromCode } from 'utils/lang';
import ArrowRight from "assets/svg/ArrowRight";
import { usePreferences } from "providers/preferences";
import Dropdown, { DropdownType } from "components/Dropdown";

import Container, { DivCursorPointer } from "./style";

type Props = {
	reports: ReportType[];
	currentReport: ReportType | null;
	currentDate: Date;
	setCurrentReport: (r: ReportType) => void;
}

const Calendar: React.FC<Props> = ({ currentReport, reports, currentDate, setCurrentReport }) => {
	const [prevReport, setPrevReport] = useState<ReportType | undefined>(undefined);
	const [nextReport, setNextReport] = useState<ReportType | undefined>(undefined);
	const navigate = useNavigate();
	const { preferences } = usePreferences();
	const formattedCurrentDate = format(currentDate, 'dd/MM/yyyy', { locale: getLocaleFromCode(preferences.language) });

	useEffect(() => {
		if (currentReport) {
			const reportIndex = reports.sort((a, b) => a.dateReport.localeCompare(b.dateReport)).findIndex(
				(r) => r._id === currentReport._id
			);

			setPrevReport(reportIndex - 1 >= 0 ? reports[reportIndex - 1] : undefined);
			setNextReport(reportIndex + 1 <= reports.length - 1 ? reports[reportIndex + 1] : undefined);
		}
	}, []);

	const goToPrevReport = (): void => {
		if (prevReport) {
			setTimeout(() => {
				setCurrentReport(prevReport);
				navigate(`${routes.report}/${prevReport.dateReport}`)
			}, 500);
		}
	}

	const goToNextReport = (): void => {
		if (nextReport) {
			setTimeout(() => {
				setCurrentReport(nextReport);
				navigate(`${routes.report}/${nextReport.dateReport}`);
			}, 500);
		}
	}

	return (
		<Container>
			<DivCursorPointer disabled={prevReport === undefined} onClick={goToPrevReport}>
				<ArrowLeft />
			</DivCursorPointer>
			<Flex flexDirection="column">
				<Dropdown notInteractive title={formattedCurrentDate} type={DropdownType.Calendar} />
			</Flex>
			<DivCursorPointer disabled={nextReport === undefined} onClick={goToNextReport}>
				<ArrowRight />
			</DivCursorPointer>
		</Container>
	);
}

export default Calendar;