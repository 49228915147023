import { AxiosResponse, AxiosInstance } from 'axios';

import { CounterCreate, CounterType, CounterUpdate } from '../types/counter';
import type { ApiResponse } from './type';

const createCheckedCounter = async (client: AxiosInstance, data: CounterCreate & { ReportId: number }): Promise<CounterType> => {
	const res: AxiosResponse<ApiResponse<CounterType>> = await client.post(`checkedCounter`, data);
	return res.data?.data;
};

export const createCheckedCounterForReport = (ReportId: number): (client: AxiosInstance, data: CounterCreate) => Promise<CounterType> => {
	return async (client: AxiosInstance, data: CounterCreate) => createCheckedCounter(client, { ...data, ReportId });
};

export const updateCheckedCounter = async (client: AxiosInstance, data: CounterUpdate, task_id: string): Promise<CounterType> => {
	const res: AxiosResponse<ApiResponse<CounterType>> = await client.put(`checkedCounter/${task_id}`, data);
	return res.data?.data;
};

export const deleteCheckedCounter = async (client: AxiosInstance, task_id: string): Promise<void> => {
	return client.delete(`checkedCounter/${task_id}`);
};