import i18n from '../../utils/lang';

export type ReportType = {
	_id?: number;
	editor: string;
	name?: string;
	description?: string;
	status: ReportStatus;
	dateReport: string;
	startTime: number;
	endTime: number;
	weatherAtStart: number;
	weatherAtEnd: number;
	tempMin?: number;
	tempMax?: number;
	createdBy?: string;
};

export enum ReportStatus {
	Invalidated = 0,
	Validated = 1,
	Checked = 2,
	Locked = 3,
}

export function getReportStatusText(status: ReportStatus): string {
	switch (status) {
		case ReportStatus.Invalidated:
			return i18n.t('report.status.invalidated');

		case ReportStatus.Validated:
			return i18n.t('report.status.validated');

		case ReportStatus.Locked:
			return i18n.t('report.status.locked');

		default:
			return '';
	}
}
