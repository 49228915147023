import React from 'react';

export type Props = {
	color?: string;
};

const ArrowDown: React.FC<Props> = ({ color }) => {
	return (
		<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.49999 7.71309L12.51 1.70309L11.097 0.288086L6.49999 4.88809L1.90399 0.288086L0.48999 1.70209L6.49999 7.71309Z"
				fill={color ?? 'white'}
			/>
		</svg>
	);
};

export default ArrowDown;
