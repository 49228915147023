type SubstractProps = {
	color: string;
};

const Substract: React.FC<SubstractProps> = ({ color }: SubstractProps) => {
	return (
		<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M21.9643 18.689C21.3261 18.7995 20.6698 18.8572 20 18.8572C13.6882 18.8572 8.57143 13.7404 8.57143 7.42858C8.57143 4.60209 9.5975 2.01525 11.2975 0.0202332C4.99704 0.383942 0 5.60842 0 12C0 18.6274 5.37258 24 12 24C16.1509 24 19.8096 21.8924 21.9643 18.689Z" fill={color} />
		</svg>
	)
};

export default Substract;

