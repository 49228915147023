import { AxiosInstance } from 'axios';

export type WorksiteResponse = {
    _id: number,
    code: string,
    name: string,
    keycloak_client: string,
    referential_url: string,
    export_url: string,
    powerbi_url: string,
    logo_b64: string,
    settings: string,
}

export type WorksiteResponseUnauth = {
    code: string,
    keycloak_client: string,
    name: string,
    logo_b64: string,
}

export const getCurrentWorksite = async (client: AxiosInstance): Promise<WorksiteResponse> => {
    const { data: { data } } = await client.get<{ data: WorksiteResponse | undefined }>('worksite/current');
    if (!data) throw Error("Unable to fetch current worksite data");

    return data;
}

export const getAllWorksiteUnauth = async (client: AxiosInstance): Promise<WorksiteResponseUnauth[]> => {
    const { data: { data } } = await client.get<{ data: WorksiteResponseUnauth[] | undefined }>('worksite/all');
    if (!data) throw Error("Unable to fetch all worksite data");

    return data;
}

export const searchCodeWorksite = async (client: AxiosInstance, code: string): Promise<WorksiteResponse> => {
    const { data: { data } } = await client.get<{ data: WorksiteResponse | undefined }>(`worksite/${code}`);
    if (!data) throw Error("Unable to fetch searched worksite data");

    return data;
}