import styled from 'styled-components';

export const ModalBody = styled.div`
	min-width: 33vw;
	min-height: 15vh;

	color: black;
	background-color: white;
	border-radius: 5px;

	display: flex;
	flex-direction: column;

	padding: 1em;
`;

export const ModalHeader = styled.div`
	flex-grow: 0;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;

	& > * {
		cursor: pointer;
	}
`;

export const ModalContent = styled.div`
	flex-grow: 1;

	display: flex;
	flex-direction: column;

	& > p {
		flex-grow: 1;

		display: grid;
		place-content: center;

		font-weight: 500;
	}
`;

export const ModalActions = styled.div`
	flex-grow: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	& > button {
		min-width: 33%;
		padding: 0.5em 1em;
		border-radius: 5px;

		&:hover {
			font-weight: 500;
		}

		&.cancel {
			color: white;
			background-color: #4D9CBF;
			font-weight: 500;

			&:hover {
				font-weight: 600;
			}
		}
		
		&.confirm {
			color: white;
			background-color: #cb3f20;
			font-weight: 500;

			&:hover {
				font-weight: 600;
			}
		}
	}
`;
