import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Margin, Spacer } from 'styles';
import { FeaturesFlag, hasFlags } from 'api/utils';
import { useModal } from 'components/Modal';
import Button, { Type as ButtonType } from 'components/Button';
import { ReportContext } from 'widgets/Report/provider';
import { EOTPCategory, PRODUCTION_TYPE_REFERENCE } from 'utils/constants';
import { CategoryType } from 'api/types/category';
import { ReportStatus } from 'api/types/report';
import { createCheckedCounterForReport } from 'api/routes/checkedCounter';
import { useReports } from 'providers/ReportsProvider';
import { createCounter } from 'api/routes/counter';
import Add from 'assets/svg/Add';
import i18n from 'utils/lang';

import Modal from '../Modal';
import ModalEOTP from '../Modal/eotp';
import Container, { Separator, Tab } from './style';

export type Props = {
	categories: Readonly<CategoryType[]>;
	selectedTab: number;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

const Tabs: React.FC<Props> = ({ categories, selectedTab, setSelectedTab }) => {
	const { date } = useParams();
	const { canEdit, currentReport } = useReports();
	const { push } = useModal();
	const { refresh, setRefresh } = useContext(ReportContext);

	const currentCategory = categories.find((c) => c._id === selectedTab);
	const hasAddEOTP = hasFlags(currentCategory?.features || -1, FeaturesFlag.AddEOTP);
	const hasNoResources = hasFlags(currentCategory?.features || -1, FeaturesFlag.WithoutResource);

	// TODO: FIXME
	const createCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated
			? createCheckedCounterForReport(currentReport?._id ?? -1)
			: createCounter;

	return (
		<Container justifyContent="space-between">
			<Flex justifyContent="flex-start">
				{categories &&
					categories.map((e, idx) => (
						<Tab key={`Tabs-${e._id}-${idx}`} selected={selectedTab === e._id} onClick={() => setSelectedTab(e._id)}>
							{e.name}
						</Tab>
					))}
				<Separator />
				<Tab selected={selectedTab === EOTPCategory._id} onClick={() => setSelectedTab(EOTPCategory._id)}>
					{i18n.t('view_eotp')}
				</Tab>
			</Flex>
			<Flex justifyContent="flex-end" alignItems="center" className="fs-14">
				{canEdit && hasAddEOTP && selectedTab !== EOTPCategory._id && (
					<>
						<Flex flexDirection="row" alignItems="center">
							<Margin right={5}>
								<b>{i18n.t('add_eotp')}</b>
							</Margin>
							<Button
								type={ButtonType.primary}
								onClick={() => {
									if (currentCategory && date) {
										push(
											<ModalEOTP
												date={date}
												category={EOTPCategory}
												productionCategory={categories.find((e) => e.typeReference === PRODUCTION_TYPE_REFERENCE)}
												currentCategory={currentCategory}
												refresh={refresh}
												setRefresh={setRefresh}
												createCounterFn={createCounterFn}
											/>,
										);
									}
								}}
							>
								<Add />
							</Button>
						</Flex>
						<Spacer space={10} />
					</>
				)}
				{canEdit && !hasNoResources && selectedTab !== EOTPCategory._id && (
					<Flex flexDirection="row" alignItems="center">
						<Margin right={5}>
							<b>{i18n.t('add_lines')}</b>
						</Margin>
						<Button
							type={ButtonType.primary}
							onClick={() => {
								if (currentCategory && date) {
									push(
										<Modal
											title={currentCategory.name}
											category={currentCategory}
											date={date}
											refresh={refresh}
											setRefresh={setRefresh}
											createCounterFn={createCounterFn}
										/>,
									);
								}
							}}
						>
							<Add />
						</Button>
					</Flex>
				)}
			</Flex>
		</Container>
	);
};

export default Tabs;
