export default function downloadFile(fileName: string, fileContent: string, fileType: string): void {
	const element = document.createElement('a');
	if (fileType === 'xlsx') {
		element.setAttribute('href', 'data:text/xlsx;base64,' + encodeURIComponent(fileContent));
	} else {
		element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(fileContent));
	}
	element.setAttribute('download', fileName);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}
