import React from 'react';
import { CustomRadioInput, RadioGroup } from '../style';
import i18n from 'i18next';
import styled from 'styled-components';

const Container = styled.div`
	h2 {
		font-size: 20px;
		margin: 0 0 0.33em 0;
	}
`;

type Props = {
	groups: string[];
	activeGroup?: string;
	onGroupChange: (newGroup: string) => unknown;
};

const EotpGroupRadioSelect: React.FC<Props> = ({ groups, activeGroup, onGroupChange }: Props) => {
	return (
		<Container>
			<h2>{i18n.t('quick_selection')}</h2>
			<RadioGroup>
				{groups.map((group) => (
					<div key={group}>
						<CustomRadioInput
							type="radio"
							name="group-select"
							id={`group-radio-${group}`}
							value={group}
							checked={group === activeGroup}
							onClick={() => onGroupChange(group)}
							onChange={() => void {}}
						/>
						<label htmlFor={`group-radio-${group}`}>{group}</label>
					</div>
				))}
			</RadioGroup>
		</Container>
	);
};

export default React.memo(EotpGroupRadioSelect);
