import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getAllWorksiteUnauth } from "api/routes/worksite";
import { showError } from "utils/error";
import Loader, { LoadingType } from "components/Loader";
import routes from "utils/routes";
import { useKeycloak } from "providers/keycloak";
import { unauthClient } from "api/client";

const UnauthHome: React.FC = () => {
    const { clientId, keycloak } = useKeycloak();
    const navigate = useNavigate();

    useEffect(() => {
        if (!clientId && !keycloak) {
            (async () => {
                const worksites = await getAllWorksiteUnauth(unauthClient);

                if (!worksites || worksites.length < 1) throw Error("Worksite should not be empty");
                navigate(`${routes.worksite}/${worksites[0].code}`);
            })().catch(showError('Error when fetching worksites'));
        }
    }, [])

    return <Loader type={LoadingType.Fullscreen} />;
}

export default UnauthHome;