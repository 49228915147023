// note: we prettier ignpore to be able to put values on the same line
// prettier-ignore
export enum FeaturesFlag {
	AddEOTP			= 0b00000001,
	TaskView		= 0b00000010,
	// CounterData.title before CounterResponse.code
	LabelBefore		= 0b00000100, 
	ShowFilter		= 0b00001000,
	// Creation of a report -> list categories -> Generate counters (call method to add counters associated to prepared-counters)
	GenPreScore		= 0b00010000, 
	// No possibilities to create resources
	WithoutResource	= 0b00100000,
	// No preferences if true
	NoPreferences	= 0b01000000,
	// Resource view
	ResourceView 	= 0b10000000
}

export enum WeatherFlag {
	NotSet = 0b000000,
	Sun    = 0b000001,
	Rain   = 0b000010,
	Wind   = 0b000100,
	Frost  = 0b001000,
	Snow   = 0b010000,
	Fog    = 0b100000,
}

export const addFlags = (value: number, flag: FeaturesFlag | WeatherFlag): number => value |flag;
export const hasFlags = (value: number, flag: FeaturesFlag | WeatherFlag): boolean => (value & flag) === flag;
export const removeFlags = (value: number, flag: FeaturesFlag | WeatherFlag): number => value & ~flag;
