import { AxiosInstance, AxiosResponse } from 'axios';

import { Comment } from '../types/comment';
import type { ApiResponse } from './type';


export const getAllComment = async (client: AxiosInstance, reportId: number, filter: 'byTask' | 'ByComment'): Promise<Comment[]> => {
    const {
        data: { data },
    }: AxiosResponse<ApiResponse<Comment[]>> = await client.get(`report/${reportId}/comment/${filter}`);

    return data;
};

type CreateComment = {
    content: string;
    task?: string;
    CommenttypeId?: number;
}

type UpdateComment = Partial<CreateComment>;

export const createComment = async (client: AxiosInstance, reportId: number, newComment: CreateComment): Promise<Comment> => {
    const {
        data: { data },
    }: AxiosResponse<ApiResponse<Comment>> = await client.post(`report/${reportId}/comment`, newComment);

    return data;
};

export const updateComment = async (client: AxiosInstance, reportId: number, commentId: number, updatedComment: UpdateComment): Promise<Comment> => {
    const {
        data: { data },
    }: AxiosResponse<ApiResponse<Comment>> = await client.put(`report/${reportId}/comment/${commentId}`, updatedComment);

    return data;
};
