import styled from 'styled-components';

const Container = styled.footer`
	box-sizing: border-box;

	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;

	background-color: #25465f;
	width: 100%;
	max-height: 59px;
	height: 8vh;

	padding: 0 12px;
`;

export const MarginLogo = styled.div`
	margin-right: 16px;
	margin-top: 6px;
`;

export default Container;
