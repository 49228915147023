import React from 'react';

type Props = {
    color?: string;
}

const Cross: React.FC<Props> = ({ color }) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.9841 0.66626L9.00081 6.64959L3.01748 0.66626L0.66748 3.01626L6.65081 8.99959L0.66748 14.9829L3.01748 17.3329L9.00081 11.3496L14.9841 17.3329L17.3341 14.9829L11.3508 8.99959L17.3341 3.01626L14.9841 0.66626Z"
				fill={color ?? "black"}
			/>
		</svg>
	);
};

export default Cross;
