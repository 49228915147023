import styled from 'styled-components';

import { Flex } from 'styles';

const Container = styled(Flex)`
	min-height: 60px;
	background-color: #ffffff;

	margin-bottom: 10px;
	border-radius: 4px;
	/* padding: 0 10px; */

	align-items: center;
	justify-content: space-between;

	display: grid;
	grid-template-columns: 1.9fr .1fr .5fr 5fr 0.5fr;
`;

export const ColText = styled.a`
	text-align: center;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const FiltersContainer = styled(Flex)`
	justify-content: flex-start;
	overflow: visible;
	gap: 1em;
	margin-left: 10px;
`;

export const FilterContainer = styled(Flex)`
	min-width: 0;
	align-items: center;
`;

export const WizardStickContainer = styled(Flex)`
	cursor: pointer;
`;

export default Container;
