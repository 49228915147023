import styled from 'styled-components';

export const Page = styled.main`
	flex-grow: 1;

	display: flex;
	flex-direction: column;

	overflow: scroll;

	padding: 1em;

	height: 0;
`;

export const PageActions = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-gap: 1em;

	padding: 0.5em 0;
	margin-bottom: 0.5em;

	border-bottom: solid 1px #25465f;

	div:first-child {
		grid-area: 1/1;
	}

	div:last-child {
		grid-area: 1/2;
		place-self: end;

		height: 100%;

		display: flex;
		align-items: center;
	}
`;

export const AddEotpButton = styled.div`
	cursor: pointer;

	display: flex;
	align-items: center;
`;

export const DetailsHeader = styled.div`
	width: 100%;
	padding: 1em;
`;

export const RadioGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	flex-wrap: nowrap;
	flex-shrink: 0;

	overflow-y: hidden;

	max-width: 100%;
	overflow-x: scroll;

	margin: 0 0 10px 0;
	margin-inline: 0;
	padding: 0;
	padding-inline: 0;
	padding-bottom: .5em;

	border: none;

	gap: 1.2em;

	& > div {
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: center;

		height: 100%;

		& > input[type='radio'] {
			margin-right: 0.33em;
		}

		& > label {
			white-space: nowrap;

			font-size: 12px;
			font-weight: 500;
			margin-left: 0.25em;
		}
	}
`;

export const CustomRadioInput = styled.input`
	height: 100%;
	width: 20px;
	height: 20px;

	accent-color: #000;
`;

export const CounterItemsContainer = styled.div`
	box-sizing: border-box;
	max-height: 100%;
	width: 100%;

	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.66em;

	padding: 0.5em 1em 0.5em 1em;

	overflow-y: scroll;
`;

export const CounterItem = styled.div`
	padding: 10px 18px;

	display: grid;
	place-content: center;
	grid-template-columns: 1fr 2fr;
	grid-gap: 24px;

	color: black;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);

	font-size: 14px;

	cursor: pointer;
`;

export const CounterItemCheckbox = styled.input`
	flex-grow: 0;

	appearance: none;
	background-color: #fff;
	margin: 0;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 0.2em;
	transform: translateY(-0.075em);

	display: grid;
	place-content: center;

	&::before {
		content: '';
		width: 0.65em;
		height: 0.65em;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em #000;

		transform-origin: bottom left;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	}

	&:checked::before {
		transform: scale(1);
	}
`;

export const CounterItemLabel = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;

	font-weight: 400;
`;

export const CounterItemUnit = styled.div`
	flex-shrink: 0;

	font-weight: 700;
`;
