import styled from 'styled-components';

import { Flex } from 'styles';

const Container = styled.div`
	flex-grow: 1;

	box-sizing: border-box;
	padding: 10px 24px 0 24px;

	display: flex;
	flex-direction: column;
`;

export enum ColType {
	Header,
	Accordion,
	Row,
}

type ColProps = {
	type: ColType;
}

export const Col = styled(Flex) <ColProps>`
	border-left: 1px solid ${({ type }) => type !== ColType.Header ? 'transparent' : '#000000'};
	padding: 15px 5px;
	font-weight: bold;
	max-width: 127px;
	min-width: 127px;
	align-items: center;
	justify-content: ${({ type }) => type === ColType.Accordion ? 'flex-end' : 'center'};

	&:first-child {
		border-left: none;
	}
`;

export const TotalCol = styled(Col)`
	align-items: center;
	justify-content: ${({ type }) => type === ColType.Header ? 'center' : 'flex-end'};
	display: flex;
	border-left: none;
	width: unset;
	min-width: unset;
	max-width: unset;
	margin: ${({ type }) => type === ColType.Row ? '0 10px' : '0 5px'};
`;

export const ColNotSpecified = TotalCol;

export const Cols = styled(Flex)`
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 80%;
	min-width: 0;
`;

export const ScrollCols = styled(Flex) <ColProps>`
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	flex-direction: row;
	border-left: 1px solid ${({ type }) => type === ColType.Header ? '#000000' : 'transparent'};
	border-right: 1px solid ${({ type }) => type === ColType.Header ? '#000000' : ' transparent'};

	${({ type }) => type !== ColType.Header && `
		&::-webkit-scrollbar {
			display: none;
		}
		scrollbar-width: none;
		-ms-overflow-style: none;
	`}
`;

export default Container;
