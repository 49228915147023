import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;

	border: 2px solid #000000;
	border-radius: 3px;
	min-width: 20px;
	min-height: 20px;

	&[data-disabled='true'] {
		cursor: not-allowed;
	}
`;

export default Container;
