import { useState } from 'react';
import Keycloak from "keycloak-js";

import { ReportResponse } from '../api/types';
import { ReportType } from '../api/types/report';
import { UserKeycloakInfo } from './type';

type LocalStorageState<T> = readonly [T, (value: T | ((val: T) => T)) => void];

/**
 * React.useState with the state stored inside localStorage
 *
 * Note: forceConstructor is here in case of JSON.parse doesn't work from your type
 * */
export const useLocalStorageState = <T>(
	key: string,
	initialValue: T,
	keycloak?: Keycloak,
	forceConstructor?: (val: string) => T,
): LocalStorageState<T> => {
	const settings = keycloak?.userInfo as UserKeycloakInfo | undefined;

	const fullKey = `${key}-${keycloak?.clientId ?? ''}-${settings?.sub ?? ''}`;
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const item = window.localStorage.getItem(fullKey);

			if (!item) {
				const oldItem = window.localStorage.getItem(key);

				 if (oldItem) {
					return !!forceConstructor ? forceConstructor(JSON.parse(oldItem) as string) : JSON.parse(oldItem) as T;
				 }
				return initialValue;
			}
			if (!!forceConstructor) return forceConstructor(JSON.parse(item) as string);
			return JSON.parse(item) as T;
		} catch (err) {
			console.error(err);
			return initialValue;
		}
	});

	const setValue = (value: T | ((val: T) => T)): void => {
		try {
			const valueToStore: T = value instanceof Function ? value(storedValue) : value;

			setStoredValue(valueToStore);
			window.localStorage.setItem(fullKey, JSON.stringify(valueToStore));
		} catch (err) {
			console.error(err);
		}
	};
	return [storedValue, setValue] as const;
};

// [DEBUG] Print the value and return it
export const dbg = <T>(value: T): T => {
	// eslint-disable-next-line no-console
	console.log(value);
	return value;
};

export function reportResponseToReportType(r: ReportResponse | null): ReportType | null {
	if (r === null) return null;

	return {
		...r,
		editor: r.editor ?? 'NO_EDITOR',
		status: r.status ?? -1, // Never happens
		dateReport: r.dateReport ?? '', // Never happens
		startTime: r.startTime ?? -1, // Never happens
		endTime: r.endTime ?? -1, // Never happens
		weatherAtStart: r.weatherAtStart ?? -1, // Never happens
		weatherAtEnd: r.weatherAtEnd ?? -1, // Never happens
	};
}
