import styled from 'styled-components';
import { Flex } from 'styles';

const Container = styled(Flex)`
	border-bottom: 1px solid #25465f;
`;

export type TabProps = {
	selected?: boolean;
};

export const Tab = styled.div<TabProps>`
	padding: 10px;
	margin: 0px 2px;
	background: #ffffff;
	cursor: pointer;
	font-weight: 400;
	color: #25465F;
	font-size: 16px;

	&:first-child {
		margin-left: 0px;
	}

	&:last-child {
		margin-right: 0px;
	}

	${({ selected }) =>
		selected &&
		`
        background: #4D9CBF;
        color: #ffffff;
		font-weight: bold;
    `}
`;

export const Separator = styled.div`
	width: 1px;
	background-color: #25465f;
	margin: 0px 4px;
`;

export default Container;
