import * as dateFns from 'date-fns';
import React, { useCallback, useId, useMemo, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { createCheckedCounterForReport, updateCheckedCounter } from 'api/routes/checkedCounter';
import { createCounter, updateCounter } from 'api/routes/counter';
import { CounterByResource, CounterType, SourceWithTask } from 'api/types/counter';
import { ReportStatus } from 'api/types/report';
import { TaskType } from 'api/types/task';
import Calendar from 'assets/svg/Calendar';
import Verified from 'assets/svg/Verified';
import Checkbox, { State } from 'components/Checkbox';
import Input, { Style } from 'components/Input';
import CustomTooltip from 'components/Tooltip';
import { useClient } from 'providers/client';
import { useReports } from 'providers/ReportsProvider';
import { usePreferences } from 'providers/preferences';
import { Flex } from 'styles';
import { showError } from 'utils/error';
import i18n, { getLocaleFromCode } from 'utils/lang';
import { Col, ColNotSpecified, ColType, Cols, ScrollCols, TotalCol } from 'widgets/Report/style';
import { useReportDataResources } from 'widgets/Report/provider/counters/resources';
import { getFilterColor } from 'widgets/Report/provider/counters/filter';
import Button, { Type as ButtonType } from 'components/Button';
import { CategoryType } from 'api/types/category';
import { SourceType } from 'api/types/source';

import useData from '../Modal/useData';
import CounterByDropdown from '../Dropdown';
import {
	AccordionRowText,
	InputSize,
	RowContainer,
	RowInput,
	ViewType,
} from './style';

type ContentProps = {
	task: TaskType;
	unit: string;
	source: SourceWithTask;
	inputString: string;
	inputId: string;
};

// Building ASTs for regex is a costly operation.
// Regex are defined here to only do that operation once.
const nonNumberCharsRegex = /[^0-9.]/g;
const extraEndingDotsRegex = /\.\.+$/;
const validNumberRegex = /^\d+(\.\d*)?$/;


export const Content: React.FC<ContentProps> = ({ task, unit, source, inputString, inputId }) => {
	const { clientHandler: { client } } = useClient();
	const { fetchCounters } = useReportDataResources();
	const [inputValue, setInputValue] = useState<string>(inputString);
	const { preferences } = usePreferences();
	const { canEdit, reports, currentReport } = useReports();

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;
	const createCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated
			? createCheckedCounterForReport(currentReport?._id ?? -1)
			: createCounter;

	const verifiedId = useId();

	const updateOrCreateQuantity = useCallback(async (): Promise<CounterType | void> => {
		if (!validNumberRegex.test(inputValue)) return;

		const value = parseFloat(inputValue);

		if (task._id) {
			return updateCounterFn(client, { quantity: value }, task._id.toString()).catch(showError('Failed to update counter'));
		} else {
			return createCounterFn(client, {
				code: source.baseCounter.code,
				work: source.baseCounter.work,
				task: task.task,
				cost: null,
				quantity: value,
				include: source.include ?? source.baseCounter.include,
				name: source.baseCounter.name,
				tags: null,
				comeFrom: source.baseCounter.comeFrom,
				dateCounter: source.baseCounter.dateCounter,
				comment: source.baseCounter.comment ?? '',
				SourceId: source._id.toString(),
			}).catch(showError('Failed to create counter'));
		}
	}, [inputValue, task, source, updateCounter, createCounter]);

	const handleOnBlur = useCallback(() => {
		void (async () => {
			try {
				// Ensure no extra dot stays in the input field if the input value ends with a dot
				if (inputValue.endsWith('.')) {
					setInputValue(inputValue.slice(0, -1));
				}

				await updateOrCreateQuantity();
				fetchCounters();
			} catch (err) {
				showError(`Failed to create or update quantity`)(err as Error);
			}
		})();
	}, [inputValue, updateOrCreateQuantity, fetchCounters, updateCounterFn, createCounterFn]);

	const handleOnChange = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement>) => {
			if (!canEdit) return;
			let cleanValue = evt.target.value.replace(nonNumberCharsRegex, '');
			if (cleanValue === '.') cleanValue = '0';
			cleanValue = cleanValue.replace(extraEndingDotsRegex, '.');

			if (validNumberRegex.test(cleanValue)) {
				setInputValue(cleanValue);
			}
		},
		[canEdit, setInputValue],
	);

	const validatedInReport = useMemo(() => {
		return task.ValidatedIn == null ? null : reports.find((r) => r._id === task.ValidatedIn) ?? null;
	}, [task.ValidatedIn, reports]);

	return (
		<>
			{validatedInReport != null && (
				<>
					<div className="verified-icon-container" data-tooltip-id={verifiedId}>
						<Verified />
					</div>
					<CustomTooltip
						id={verifiedId}
						place="bottom-start"
						positionStrategy="fixed"
					>
						<span>
							{i18n.t('validated_by')}: {validatedInReport.editor}
						</span>
						<Flex alignItems="center" gap=".5em">
							<Calendar /> {i18n.t('onDay').at(0)?.toUpperCase() ?? ''}
							{i18n.t('onDay').slice(1)}{' '}
							{dateFns.format(new Date(validatedInReport.dateReport), 'EEEE dd', { locale: getLocaleFromCode(preferences.language) })}
						</Flex>
					</CustomTooltip>
				</>
			)}

			<RowInput size={task.task === "" ? InputSize.ReportUndefined : InputSize.ReportContent}>
				<Input
					value={inputValue !== "0" ? inputValue : undefined}
					placeholder={inputValue === "0" ? "0" : undefined}
					disabled={validatedInReport !== null}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					id={inputId}
					onFocus={(evt) => {
						evt.target.select();
					}}
					textAlign="end"
					style={Style.Content}
				/>
				{task.task === '' && (
					<div>{unit}</div>
				)}
			</RowInput>
		</>
	);
};

type RowProps = {
	category: CategoryType;
	source: SourceWithTask
	currentResource: CounterByResource;
};

const RowResource: React.FC<RowProps> = ({ category, source, currentResource }) => {
	const baseId = useId();
	const sourceTitleID = useId();

	const { clientHandler: { client } } = useClient();
	const { sources } = useData(false, category);
	const { canEdit, currentReport } = useReports();
	const { fetchCounters } = useReportDataResources();

	const unknownData: TaskType | undefined = source.tasks.find((e) => e.task === '');
	const datas: TaskType[] = source.tasks.filter((e) => e.task !== '');
	const filterColor = getFilterColor(source.baseCounter.comeFrom);
	const isChecked = source.baseCounter.include;

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;

	const updateCounters = (): void => {
		const taskIds = source.tasks.filter(({ _id }) => _id != null).map(({ _id }) => _id);

		Promise.all(
			taskIds
				.filter((taskID): taskID is number => taskID !== undefined)
				.map((taskID) => updateCounterFn(client, { include: !isChecked }, taskID.toString())),
		)
			.then(() => setTimeout(() => {
				fetchCounters();
			}, 400))
			.catch(showError('Failed to update counters'));
	};

	const alterableSources: SourceType[] = useMemo(
		() => sources
			.filter((s) => !currentResource.sources.some((cr) => s._id === cr._id))
			.filter((s) => s.unit === source.unit),
		[currentResource, source, sources]
	);

	const formatCounter = useCallback(
		(tasks: TaskType[]): CounterType => ({ ...source.baseCounter, tasks }),
		[source.baseCounter]
	);

	return (
		<RowContainer type={ViewType.CounterBySource}>
			<Flex
				alignItems="center"
				width="95%"
				backgroundColor="var(--title-column-bg-color, unset)"
				borderRadius="8px"
				minWidth="0"
				margin="0 0 0 10px"
			>
				<Checkbox disabled={!canEdit} onClick={updateCounters} state={isChecked ? State.Checked : State.Unchecked} />
				<Button type={ButtonType.colorized} color={filterColor} />
				<AccordionRowText isResource>
					<span data-tooltip-id={sourceTitleID}>{source.label}</span>
					<CustomTooltip
						id={sourceTitleID}
						place='top-start'
					>
						{source.label}
					</CustomTooltip>
				</AccordionRowText>
			</Flex>
			<CounterByDropdown
				canDelete={!!source && !!!source.baseCounter.comeFrom}
				subtitle={`${source?.label ?? ''} (${source?.unit ?? 'U'}) | ${source.baseCounter.code}`}
				sources={alterableSources}
				tasks={source.tasks}
				counter={formatCounter(source.tasks)}
			/>
			<ColNotSpecified type={ColType.Row}>
				{!!unknownData && (
					<Content
						task={unknownData}
						unit={source.unit}
						source={source}
						inputString={unknownData.quantity.toString()}
						inputId={`${baseId}-${unknownData?._id ?? ''}-${unknownData?.task}`}
					/>
				)}
			</ColNotSpecified>

			<Cols>
				{datas.length > 0 && (
					<ScrollSyncPane group="horizontal">
						<ScrollCols type={ColType.Row}>
							{datas.map((value, idx) => (
								<Col key={`Row-${source._id}-${idx}-${value.task}-${value._id || ''}`} type={ColType.Row}>
									<Content
										task={value}
										unit={source.unit}
										source={source}
										inputString={value.quantity.toString()}
										inputId={`${baseId}-${value?._id ?? ''}-${value?.task}`}
									/>
								</Col>
							))}
						</ScrollCols>
					</ScrollSyncPane>
				)}
			</Cols>
			<TotalCol type={ColType.Row}>
				{Intl.NumberFormat(navigator.language, { maximumFractionDigits: 2 }).format(source.sum ?? 0)} {source.unit}
			</TotalCol>
		</RowContainer>
	);
};

export default RowResource;