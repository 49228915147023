import React from 'react';


const LinkImage1: React.FC = () => {
	return (
		<svg width="338" height="341" viewBox="0 0 338 341" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="52.5" y="61.3282" width="233.001" height="134.344" fill="white" stroke="#25465F" />
			<path d="M53 61.7501H89V97.2501L53 96.9662V61.7501Z" fill="#25465F" />
			<path d="M88.9995 61.7501H285V97.2501H88.9995V61.7501Z" fill="#E0EDFA" />
			<rect x="58.9399" y="101.481" width="19.3348" height="11.8983" fill="#4D9CBF" />
			<rect x="80.7534" y="101.481" width="19.3348" height="11.8983" fill="#E0EDFA" />
			<rect x="102.567" y="101.481" width="19.3348" height="11.8983" fill="#E0EDFA" />
			<rect x="124.382" y="101.481" width="19.3348" height="11.8983" fill="#E0EDFA" />
			<rect x="146.194" y="101.481" width="19.3348" height="11.8983" fill="#E0EDFA" />
			<g filter="url(#filter0_d_156_20801)">
				<rect x="58.9399" y="116.354" width="218.632" height="8.92376" rx="1.98306" fill="white" />
			</g>
			<g filter="url(#filter1_d_156_20801)">
				<rect x="58.9399" y="128.252" width="218.632" height="8.92376" rx="1.98306" fill="white" />
			</g>
			<g filter="url(#filter2_d_156_20801)">
				<rect x="58.9399" y="140.151" width="218.632" height="8.92376" rx="1.98306" fill="white" />
			</g>
			<g filter="url(#filter3_d_156_20801)">
				<rect x="58.9399" y="152.049" width="218.632" height="8.92376" rx="1.98306" fill="white" />
			</g>
			<g filter="url(#filter4_d_156_20801)">
				<rect x="58.9399" y="163.947" width="218.632" height="8.92376" rx="1.98306" fill="white" />
			</g>
			<circle cx="169" cy="212.5" r="49.4845" fill="white" stroke="#25465F" stroke-width="1.03093" />
			<path d="M169.516 221.263C180.903 221.263 190.134 212.032 190.134 200.644C190.134 189.257 180.903 180.026 169.516 180.026C158.128 180.026 148.897 189.257 148.897 200.644C148.897 212.032 158.128 221.263 169.516 221.263Z" fill="#FFB6B6" />
			<path d="M204.582 246.24C196.538 255.393 185.216 261.016 173.062 261.894C160.908 262.773 148.38 259.577 139.103 251.675L143.601 239.822C144.501 237.848 145.922 236.156 147.71 234.928C149.498 233.7 151.587 232.982 153.752 232.851L156.286 232.695L162.328 227.485C162.328 227.485 178.607 226.903 178.365 226.625L188.226 232.556L188.218 232.515L192.094 232.753C194.259 232.883 196.348 233.601 198.137 234.829C199.925 236.057 201.346 237.749 202.245 239.723L204.582 246.24Z" fill="#4D9CBF" />
			<defs>
				<filter id="filter0_d_156_20801" x="46.5458" y="105.943" width="243.42" height="33.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="1.98306" />
					<feGaussianBlur stdDeviation="6.19705" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_20801" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_20801" result="shape" />
				</filter>
				<filter id="filter1_d_156_20801" x="46.5458" y="117.841" width="243.42" height="33.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="1.98306" />
					<feGaussianBlur stdDeviation="6.19705" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_20801" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_20801" result="shape" />
				</filter>
				<filter id="filter2_d_156_20801" x="46.5458" y="129.74" width="243.42" height="33.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="1.98306" />
					<feGaussianBlur stdDeviation="6.19705" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_20801" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_20801" result="shape" />
				</filter>
				<filter id="filter3_d_156_20801" x="46.5458" y="141.638" width="243.42" height="33.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="1.98306" />
					<feGaussianBlur stdDeviation="6.19705" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_20801" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_20801" result="shape" />
				</filter>
				<filter id="filter4_d_156_20801" x="46.5458" y="153.536" width="243.42" height="33.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="1.98306" />
					<feGaussianBlur stdDeviation="6.19705" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_20801" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_20801" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};

export default LinkImage1;
