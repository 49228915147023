import React from 'react';

export type Props = {
	color?: string;
};

const Sun: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="SunSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<rect x="11.3" y="4" fill={color ?? '#25465F'} width="1" height="2.8" />
			<ellipse
				transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9606 12.0041)"
				fill={color ?? '#25465F'}
				cx="12"
				cy="12"
				rx="4.4"
				ry="4.4"
			/>
			<rect x="11.3" y="17.2" fill={color ?? '#25465F'} width="1" height="2.8" />
			<rect x="4" y="11.7" fill={color ?? '#25465F'} width="2.8" height="1" />
			<rect x="17.2" y="11.7" fill={color ?? '#25465F'} width="2.8" height="1" />
			<rect
				x="6.5"
				y="6"
				transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.1661 7.1059)"
				fill={color ?? '#25465F'}
				width="1"
				height="2.8"
			/>
			<polygon fill={color ?? '#25465F'} points="17.7,17.4 17,18.1 15,16.1 15.7,15.4 " />
			<rect
				x="15.8"
				y="6.3"
				transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.2194 14.1498)"
				fill={color ?? '#25465F'}
				width="2.8"
				height="1"
			/>
			<polygon fill={color ?? '#25465F'} points="8.6,16.1 6.6,18.1 5.9,17.4 7.9,15.4 " />
		</svg>
	);
};

export default Sun;
