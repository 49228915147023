import React from "react";


const WizardStick: React.FC = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0383 4.84945C16.0383 3.76433 16.9149 2.88774 18 2.88774C16.9149 2.88774 16.0383 2.01115 16.0383 0.926025C16.0383 2.01115 15.1617 2.88774 14.0766 2.88774C15.1617 2.88774 16.0383 3.76433 16.0383 4.84945ZM5.8236 5.25583C6.59089 4.48854 7.83058 4.48854 8.59788 5.25583C7.83058 4.48854 7.83058 3.24885 8.59787 2.48155C7.83058 3.24885 6.59089 3.24885 5.8236 2.48155C6.59089 3.24885 6.59089 4.48854 5.8236 5.25583ZM13.6703 10.3282C14.4376 11.0955 15.6773 11.0955 16.4446 10.3282C15.6773 11.0955 15.6773 12.3352 16.4446 13.1025C15.6773 12.3352 14.4376 12.3352 13.6703 13.1025C14.4376 12.3352 14.4376 11.0955 13.6703 10.3282ZM8.2528 7.89709L0.128303 16.0212C-0.0427676 16.1923 -0.0427676 16.4695 0.128303 16.6406L2.28541 18.7977C2.45648 18.9688 2.73375 18.9688 2.90482 18.7977L11.0293 10.6732C11.2004 10.5022 11.2004 10.2249 11.0293 10.0538L8.87221 7.89671C8.70114 7.72564 8.42387 7.72564 8.2528 7.89671V7.89709ZM13.0544 3.71489L15.2115 5.872C15.3826 6.04307 15.3826 6.32033 15.2115 6.4914L13.0351 8.66782C12.864 8.83889 12.5868 8.83889 12.4157 8.66782L10.2586 6.51071C10.0875 6.33964 10.0875 6.06238 10.2586 5.89131L12.435 3.71489C12.6061 3.54382 12.8833 3.54382 13.0544 3.71489Z"
                fill="#25465F"
            />
        </svg>
    );
}

export default WizardStick;