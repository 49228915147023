import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { searchCodeWorksite } from "api/routes/worksite";
import { useKeycloak } from "providers/keycloak";
import Loader, { LoadingType } from "components/Loader";
import routes from "utils/routes";
import { unauthClient } from "api/client";


const Worksite: React.FC = () => {
    const [onError, setOnError] = useState<boolean>(false);
    const { setClientId, setKeycloak } = useKeycloak();
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
        (async () => {
            if (!code) throw Error("Code was not provided");

            const cId = await searchCodeWorksite(unauthClient, code);
            if (!cId.keycloak_client) throw Error("Keycloak client is not found");
            setClientId(cId.keycloak_client);
            navigate(routes.home);
        })().catch((err) => {
            console.error(err);
            setKeycloak(undefined);
            setClientId(undefined);
            setOnError(true);
        });
    }, [code]);
    
    if (onError) return <div>Erreur: Code chantier incorrect</div>
    return <Loader type={LoadingType.Fullscreen} />
}


export default Worksite;