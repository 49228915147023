import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: row;

	background-color: #e0edfa;
	width: 100%;
	height: 82px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	width: 100%;
	padding: 0 24px;
`;

export const NoHeight = styled.div`
	height: 0;
	margin-top: 25px;
	position: absolute;
`;

export const MenuContainer = styled.div`
	margin-left: auto;
	margin-right: 15px;
	cursor: pointer;
`;

export default Container;
