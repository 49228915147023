import styled from 'styled-components';

const Container = styled.header<{ admin: boolean }>`
	display: flex;
	position: sticky;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 6px 34px;
	gap: 32px;

	top: 0;
	left: 0;
	background-color: ${(props) => (props.admin ? '#4D9CBF' : '#25465F')};
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	height: 59px;
	height: 5vh;
`;

export const Spacer = styled.div`
	display: flex;
	flex-grow: 3;
`;

export const CursorPointer = styled.a`
	cursor: pointer;
	font-size: 0px;
`;

export const DropdownMenu = styled.div`
	background-color: white;
	width: 100%;
	cursor: default;
	border-radius: 5px;
	padding: 0.5em 0;

	box-shadow: 0 2.5px rgba(0, 0, 0, 0.2);
`;

export const DropdownButton = styled.div`
	cursor: pointer;
	margin: 0 1em;
	padding: 0.33em 0;
	color: black;

	transition: font-weight ease-in-out 0.1s;
	font-weight: 500;
	&:hover {
		font-weight: 700;
	}

	border-bottom: solid 1px #555;
	&:last-of-type {
		border-bottom: none;
	}

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const ReportComponentContainer = styled.div`
	display: flex; 
	align-items: center;
	gap: 20px;
`;

export const NavbarTitle = styled.h1`
	color: white;
	font-weight: 700;
	font-size: 16px;
	margin: 0;
`

export default Container;
