import styled from 'styled-components';
import { Flex } from 'styles';

export const SectionContainer = styled.div`
	margin-bottom: 10px;
	background-color: #ffffff;

	font-size: 14px;
	border-radius: 4px;
`;

export const Container = styled(SectionContainer)`
	margin: 0;
	padding: 0;

	&,
	* {
		box-sizing: border-box;
	}

	&:hover {
		--title-column-bg-color: var(--hover-bg-color, unset);
		--actions-display: var(--actions-display-value, unset);
	}

	&:focus-within {
		--actions-display: flex;
		--move-copy-actions-display: flex;
		--title-column-bg-color: #e0edfa;
	}
`;

type AccordionContainerProps = {
	isTaskView?: boolean;
}

export const AccordionContainer = styled.div<AccordionContainerProps>`
	display: grid;
	grid-template-columns: 1.9fr .1fr .5fr 5fr 0.5fr;
	
	align-items: center;
	justify-content: space-between;
	
	height: 30px;

	${({ isTaskView }) => isTaskView ? `
		margin: 8px;
	` : `
		margin: 0 0 12px 0;
		padding-bottom: 5px;
	`}
`;

export enum ViewType {
	CounterBySource,
	Task
}

type RowContainerProps = {
	type: ViewType;
}

export const RowContainer = styled.div<RowContainerProps>`
	display: grid;
	grid-template-columns: 1.9fr .1fr .5fr 5fr 0.5fr;

	align-items: center;
	justify-content: space-between;

	${({ type }) => type === ViewType.CounterBySource && `
		& > div {
			height: 32px;
			padding: 5px 0;
		}
	`}

	${({ type }) => type === ViewType.Task && `
		& > div {
			width: 100%;
		}
	`}

	&:hover {
		--title-column-bg-color: var(--hover-bg-color, unset);
		--actions-display: var(--actions-display-value, unset);
	}

	&:focus-within {
		--actions-display: flex;
		--move-copy-actions-display: flex;
		--title-column-bg-color: #e0edfa;
	}
`;

export enum TextSize {
	Small,
	Medium,
	Large,
	Unset,
}

export type RowTextProps = {
	size: TextSize;
};

export const RowText = styled.div<RowTextProps>`
	width: ${({ size }) => {
		switch (size) {
			case TextSize.Small:
				return '15px';
			case TextSize.Medium:
				return '90px';
			case TextSize.Large:
				return '300px';
			case TextSize.Unset:
				return 'unset';
		}
	}};
`

type AccordionRowTextProps = {
	isEOTP?: boolean
	isCursorPointer?: boolean;
	isResource?: boolean;
}

export const AccordionRowText = styled.div<AccordionRowTextProps>`
	width: ${({ isEOTP }) => isEOTP ? '25vw' : '20vw'};
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: ${({ isCursorPointer }) => isCursorPointer ? 'pointer' : 'unset'};

	${({ isResource }) => isResource && `
		margin-left: 4px;
	`};
`

export const RowCard = styled.div`
	padding: 6px 12px;
	background: #ffffff;
	box-shadow: 0px 4px 25px 0px #00000026;
	
	border-radius: 4px;
`;

export enum InputSize {
	Medium,
	Large,
	ReportContent,
	ReportUndefined,
}

export type RowInputProps = {
	size: InputSize;
};

export const RowInput = styled.div<RowInputProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	background: #ffffff;
	
	input {
		border-radius: 4px;
	}

	width: ${({ size }) => {
		switch (size) {
			case InputSize.Medium:
				return 100;
			case InputSize.Large:
				return 200;
			case InputSize.ReportContent:
				return 127;
			case InputSize.ReportUndefined:
				return 80;
		}
	}}px;

	${({ size }) => size === InputSize.ReportUndefined && `
		gap: 4px;
		input {
			min-width: 40px;
		}
	`}
`;

type LeftContainerProps = {
	isCursorPointer?: boolean;
};

export const LeftContainer = styled(Flex) <LeftContainerProps>`
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	${({ isCursorPointer }) =>
		isCursorPointer &&
		`
		cursor: pointer;
	`}
`;

export const RightContainer = styled(Flex)`
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-right: 5px;
`;

export type ScrollContainerProps = {
	isHidden?: boolean;
};

type TitleProps = {
	isTaskView?: boolean;
};

export const Title = styled.b<TitleProps>`
	margin-left: 12px;
	width: 30%;

	${({ isTaskView }) =>
		isTaskView &&
		`
		margin-right: 20px;
		width: auto !important;
	`}
`;

export const BorderBottom = styled.div`
	border-bottom: 1px solid darkgray;
	padding: 4px;
	margin: 0 8px;
	
	&:last-child {
		border-bottom: none;
	}
`;


export const RowsContainer = styled.div`
	--hover-bg-color: #e0edfa;
	--actions-display: none;
	--actions-display-value: flex;

	&:focus-within {
		--hover-bg-color: transparent;
		--actions-display-value: none;
	}
`;

export const InputContainer = styled.div`
	position: relative;

	.verified-icon-container {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;

		padding: 0 0.5em;

		display: flex;
		justify-content: flex-start;
		align-items: center;

		cursor: help;
	}

	.verified-tooltip {
		display: none;

		position: absolute;
		bottom: -100%;
		left: 0;
		height: 100%;
		min-width: 100%;

		padding: 0 0.5em;

		z-index: 1000;
		transform: translateZ(0);

		justify-content: flex-start;
		align-items: center;

		background: red;

		&:hover {
			display: flex;
		}
	}

	.verified-icon-container:hover + .verified-tooltip {
		display: flex;
	}
`;


export const RestrainOverflow = styled.div`
	width: 140px;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;


export const CursorPointerFlex = styled(Flex)`
	cursor: pointer;
`;

export default Container;
