import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FixedContainer = styled.div`
	z-index: 1;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Card = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ModalContainer = styled.div`
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	width: 150px;
	height: 150px;
	border-radius: 8px;
`;

export default Container;
