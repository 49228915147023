import React from 'react';

export type Props = {
	color?: string;
};

const Frost: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="FrostSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<style type="text/css"></style>
			<polygon
				fill={color ?? '#25465F'}
				points="20,15.2 18.7,15.5 19,16.8 18,17.1 17.6,15.5 15.9,14.5 16.4,16.3 15.4,16.6 14.7,13.8 12.5,12.6 
	12.5,15 14.6,17.1 13.8,17.8 12.5,16.5 12.5,18.5 13.7,19.6 13,20.3 12,19.4 11,20.3 10.3,19.6 11.5,18.5 11.5,16.5 10.2,17.8 
	9.4,17.1 11.5,15 11.5,12.6 9.3,13.8 8.6,16.6 7.6,16.3 8.1,14.5 6.4,15.5 6,17.1 5,16.8 5.3,15.5 4,15.2 4.3,14.2 5.9,14.6 
	7.6,13.6 5.8,13.2 6,12.2 8.8,12.9 11,11.7 8.8,10.4 6,11.2 5.8,10.2 7.6,9.7 5.9,8.7 4.3,9.2 4,8.2 5.3,7.8 5,6.5 6,6.2 6.4,7.8 
	8.1,8.8 7.6,7 8.6,6.8 9.3,9.6 11.5,10.8 11.5,8.3 9.4,6.3 10.2,5.5 11.5,6.8 11.5,4.9 10.3,3.7 11,3 12,4 13,3 13.7,3.7 12.5,4.9 
	12.5,6.8 13.8,5.5 14.6,6.3 12.5,8.3 12.5,10.8 14.7,9.6 15.4,6.8 16.4,7 15.9,8.8 17.6,7.8 18,6.2 19,6.5 18.7,7.8 20,8.2 
	19.7,9.2 18.1,8.7 16.4,9.7 18.2,10.2 18,11.2 15.2,10.4 13,11.7 15.2,12.9 18,12.2 18.2,13.2 16.4,13.6 18.1,14.6 19.7,14.2 "
			/>
		</svg>
	);
};

export default Frost;
