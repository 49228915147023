import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';
import { ReportResponse } from '../../../../api/types';
import { ReportStatus, getReportStatusText } from '../../../../api/types/report';
import Calendar from '../../../../assets/svg/Calendar';
import { useModal } from '../../../../components/Modal';
import { Flex } from '../../../../styles';
import Container from '../../../../widgets/Report/components/Modal/style';

import ModalHeader from 'components/Modal/ModalHeader';
import { intlFormat } from 'date-fns';
import i18n from '../../../../utils/lang';

const DuplicateReportModalContainer = styled(Container)`
	width: 60rem;
	height: 20rem;
	box-sizing: border-box;
	padding: 1.33rem;

	gap: 2rem;

	& > * {
		box-sizing: border-box;
	}
`;

const ModalButtonsContainer = styled(Flex)`
	width: 100%;
	gap: 2rem;
`;

const ModalButton = styled.button`
	flex-grow: 1;
	width: 50%;
	height: 10rem;
	padding: 1rem;

	border: solid rgb(97, 154, 188) 1px;
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;

	color: #25465f;
	font-size: 16px;
	font-weight: 700;

	transition: box-shadow 0.3s;
	&:hover {
		box-shadow: 0 0 5px rgba(33, 33, 33, 0.2);
	}

	div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 0.5em;
	}
`;

const NewButton = styled(ModalButton)`
	color: white;
	background-color: rgb(97, 154, 188);
`;

type Props = {
	report: ReportResponse;
	onCancel: VoidFunction;
	onDuplicateReport: () => Promise<unknown>;
	onNewReport: () => Promise<unknown>;
};

const DuplicateReportModal: React.FC<Props> = ({ onCancel, report, onDuplicateReport, onNewReport }) => {
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { pop } = useModal();

	const hasPendingOperation = useRef(false);

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const attemptClose = useCallback(() => {
		// Prevent closing the modal if an operation (create or duplicate report) is pending
		if (hasPendingOperation.current) return;

		pop();
		onCancel();
	}, [onCancel, pop]);

	useOnClickOutside(modalRef, attemptClose);

	const handleDuplicate = useCallback(() => {
		setIsButtonDisabled(true);
		(async () => {
			hasPendingOperation.current = true;
			await onDuplicateReport();
			hasPendingOperation.current = false;
			pop();
		})().catch(console.error);
	}, [onDuplicateReport]);

	const handleNew = useCallback(() => {
		setIsButtonDisabled(true);
		(async () => {
			hasPendingOperation.current = true;
			await onNewReport();
			hasPendingOperation.current = false;
			pop();
		})().catch(console.error);
	}, [onNewReport]);

	return (
		<>
			<DuplicateReportModalContainer
				ref={modalRef}
				flexDirection="column"
				justifyContent="space-around"
				alignItems="center"
			>
				<ModalHeader title={i18n.t('report.add_modal.title')} pop={attemptClose}/>

				<ModalButtonsContainer flexDirection="row" justifyContent="space-around" alignItems="center">
					<ModalButton disabled={isButtonDisabled} onClick={handleDuplicate}>
						<span>{i18n.t('report.add_modal.duplicate')}</span>
						<div>
							<Calendar color="currentColor" />
							<span>
								<>
									{getReportStatusText(report.status as ReportStatus)}{' '}
									{report.dateReport != null && (
										<>
											{i18n.t('onDay')} {intlFormat(new Date(report.dateReport), {}, { locale: navigator.language })}
										</>
									)}
								</>
							</span>
						</div>
					</ModalButton>

					<NewButton disabled={isButtonDisabled} onClick={handleNew}>{i18n.t('report.add_modal.new')}</NewButton>
				</ModalButtonsContainer>
			</DuplicateReportModalContainer>
		</>
	);
};

export default React.memo(DuplicateReportModal);
