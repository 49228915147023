import React, { useId } from 'react';

import { Flex } from 'styles';
import Button, { Type as ButtonType } from 'components/Button';
import { emptyFilterTranslationKey, useSearchAndFilter } from 'widgets/Report/provider/counters/sources/searchAndFilter';
import { useSearchAndFilterResources } from 'widgets/Report/provider/counters/resources/searchAndFilter';
import { ToggleableFilterType } from 'widgets/Report/provider/counters/filter';
import i18n from 'utils/lang';
import { FilterType } from 'utils/constants';
import CustomTooltip from 'components/Tooltip';

import Container, { FilterContainer, FilterTitle, Square, BoldText } from './style';

type FilterButtonProps = {
	filter: FilterType;
	selected: boolean;
	onClick: () => void;
};

const FilterButton: React.FC<FilterButtonProps> = ({ filter, selected, onClick }) => {
	const filterID = useId();

	return (
		<Button type={selected ? ButtonType.filterSelected : ButtonType.filter} onClick={onClick}>
			<Flex flexDirection="row">
				<Flex alignItems="center" data-tooltip-id={filterID}>
					<Square color={filter.color ?? 'red'} />
				</Flex>
				<CustomTooltip
					id={filterID}
					place="top"
					positionStrategy="fixed"
				>
					<FilterTitle>
						{Boolean(filter.name) ? filter.name : i18n.t(emptyFilterTranslationKey)}
					</FilterTitle>
				</CustomTooltip>
			</Flex>
		</Button>
	);
};

type FilterProps = {
	availableFilters: ToggleableFilterType[]
}

const Filter: React.FC<FilterProps> = ({ availableFilters }) => {
	return (
		<Container>
			<Flex alignItems="center">
				<BoldText>{i18n.t('filter')}</BoldText>
			</Flex>
			<FilterContainer>
				{availableFilters.map((fil, idx) => {
					return (
						<FilterButton
							key={`filter-button-${idx}-${fil?.name ?? ''}`}
							filter={fil}
							selected={fil.active}
							onClick={() => fil.toggle()}
						/>
					);
				})}
			</FilterContainer>
		</Container>
	);
};

type Props = {
	isResource?: boolean;
}

const FilterBuilder: React.FC<Props> = ({ isResource }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { availableFilters } = isResource ? useSearchAndFilterResources() : useSearchAndFilter(); 

	return <Filter availableFilters={availableFilters} />
}

export default FilterBuilder;
