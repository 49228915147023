import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CounterByResource } from 'api/types/counter';
import Fuse from 'fuse.js';
import { useDebounce } from 'usehooks-ts';

import { ToggleableFilterType, getFilterColor } from '../filter';
import { useReportDataResources } from '.';

type SearchAndFilterResourcesContextType = {
    filteredResources: CounterByResource[];
    availableFilters: ToggleableFilterType[];
    searchText: string;
    setSearchText: (s: string) => unknown;
};

export const emptyFilterTranslationKey = '__EMPTY_FILTER__';

const SearchAndFilterResourcesContext = createContext<SearchAndFilterResourcesContextType>({
    filteredResources: [],
    availableFilters: [],
    searchText: '',
    setSearchText: () => { },
});

export const SearchAndFilterResourcesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [availableFilters, setAvailableFilters] = useState<ToggleableFilterType[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const debouncedSearchText = useDebounce(searchText, 250);

    const { resources } = useReportDataResources();

    useEffect(() => {
        setAvailableFilters(() =>
            Array.from(new Set(resources.flatMap((s) => s.sources.map((c) => c.baseCounter.comeFrom) ?? [])))
                .map(
                    (name, idx): ToggleableFilterType => ({
                        idx,
                        name: name,
                        color: getFilterColor(name),
                        active: false,
                        toggle: () => {
                            setAvailableFilters((filters) => filters.map((f) => (f.idx !== idx ? f : { ...f, active: !f.active })));
                        },
                    }),
                )
                .sort((f1, f2) => (f1.name == null || f1.name === '' ? 1 : f1.name < (f2.name ?? '') ? 1 : -1)),
        );
    }, [resources]);

    const activeFilters = useMemo(() => availableFilters.filter((f) => f.active).map((f) => f.name), [availableFilters]);

    // Memoize generated fuses to avoid recreating them each time the search text changes
    const fuses = useMemo(
        () => new Fuse(resources, { keys: ['title', 'code', 'name'], threshold: 0.3 }),
        [resources],
    );

    const filteredResources = useMemo(() => {
        if (debouncedSearchText.trim() === '' && activeFilters.length === 0) return resources;

        const searchedResources =
            debouncedSearchText.trim() === ''
                ? resources
                : fuses
                    .search(debouncedSearchText.trim())
                    .map((i) => i.item);

        return activeFilters.length === 0
            ? searchedResources
            : searchedResources
                .map((s) => ({
                    ...s,
                    sources: s.sources.filter((c) => activeFilters.includes(c.baseCounter.comeFrom)) ?? [],
                }))
                .filter((s) => s.sources.length !== 0);
    }, [debouncedSearchText, resources, activeFilters]);

    return (
        <SearchAndFilterResourcesContext.Provider
            value={{
                filteredResources,
                availableFilters,
                searchText,
                setSearchText,
            }}
        >
            {children}
        </SearchAndFilterResourcesContext.Provider>
    );
};

export const useSearchAndFilterResources = (): SearchAndFilterResourcesContextType => useContext(SearchAndFilterResourcesContext);
