import React, { createContext, useEffect, useState } from 'react';
import { add, formatISO, isAfter, parseISO } from 'date-fns';

import { getRefByTask, getRefIDByTypeRef } from 'api/routes/referential';
import { useLocalStorageState } from 'utils/common';
import { ReferentialResponse } from 'api/types/ref';
import { CategoryType } from 'api/types/category';
import { EOTPCategory } from 'utils/constants';
import { useClient } from 'providers/client';
import { useCategory } from './category';

export type ReportContextType = {
	categories: Readonly<CategoryType[]>;
	labelsEOTP: Readonly<ReferentialResponse[]>;
	selectedCategory?: CategoryType;
	selectedTab: number;
	refresh: boolean;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
	setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReportContext = createContext<ReportContextType>({
	categories: [],
	labelsEOTP: [],
	selectedTab: -1,
	refresh: false,
	setSelectedTab: () => { },
	setRefresh: () => { },
});

type Props = {
	children: React.ReactNode;
};

export const ReportProvider: React.FC<Props> = ({ children }) => {
	const { clientHandler: { client, referentialClient }, keycloak } = useClient();
	const categories = useCategory();

	/// Not a fan but it help us to refresh the Content outside the ContentContext
	///
	/// We use it during the creation of resources & EOTP. The header doesn't have access to the data provider (outside of context)
	/// So the parent has this value to said to the content to rerfresh it's state and fetch the data again
	const [refresh, setRefresh] = useState<boolean>(false);
	const [selectedTab, setSelectedTab] = useState<number>(-1);
	const [labelsEOTP, setLabelsEOTP] = useLocalStorageState<ReferentialResponse[]>('labelsEOTP', [], keycloak);
	const [lastLabelsEOTPUpdate, setLastLabelsEOTPUpdate] = useLocalStorageState(
		'lastLabelsEOTPUpdate',
		formatISO(new Date()),
		keycloak,
	);

	useEffect(() => {
		if (categories.length !== 0) {
			setSelectedTab(categories[0]._id);
		}
		if (isAfter(new Date(), add(parseISO(lastLabelsEOTPUpdate), { days: 1 })) || labelsEOTP.length === 0) {
			void (async () => {
				const refID = await getRefIDByTypeRef(client, EOTPCategory.typeReference.toString());

				if (refID) {
					const data = await getRefByTask(referentialClient, refID);
					if (data) {
						setLabelsEOTP(data);
						setLastLabelsEOTPUpdate(formatISO(new Date()));
					}
				}
			})();
		}
	}, [categories.length]);

	return (
		<ReportContext.Provider
			value={{
				categories,
				labelsEOTP,
				selectedCategory:
					selectedTab !== EOTPCategory._id ? categories.find((e) => e._id === selectedTab) : EOTPCategory,
				selectedTab,
				refresh,
				setSelectedTab,
				setRefresh,
			}}
		>
			{children}
		</ReportContext.Provider>
	);
};
