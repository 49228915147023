type UnionProps = {
    color: string;
};

const Union: React.FC<UnionProps> = ({ color }: UnionProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.663 0V4.20513H12.7656V0H10.663ZM18.6227 11.9853C18.6227 15.6349 15.6642 18.5934 12.0147 18.5934C8.36512 18.5934 5.40659 15.6349 5.40659 11.9853C5.40659 8.33582 8.36512 5.37729 12.0147 5.37729C15.6642 5.37729 18.6227 8.33582 18.6227 11.9853ZM10.663 24V19.7949H12.7656V24H10.663ZM0 11.2344L4.20513 11.2344V13.337L0 13.337V11.2344ZM24 10.9982L19.7949 10.9982V13.5733H24V10.9982ZM3.74703 2.83174L6.72051 5.80521L5.23377 7.29195L2.26029 4.31847L3.74703 2.83174ZM19.2542 21.3123L20.7409 19.8256L17.7674 16.8521L16.2807 18.3389L19.2542 21.3123ZM22.0152 3.47149L19.0418 6.44497L17.555 4.95823L20.5285 1.98476L22.0152 3.47149ZM4.17441 21.3123L7.14788 18.3389L5.66115 16.8521L2.68767 19.8256L4.17441 21.3123Z" fill={color} />
        </svg>
    )
};

export default Union;
