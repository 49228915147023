import { AxiosInstance, AxiosResponse } from 'axios';

import { ReferentialApiResponse, ReferentialResponse, RefsIndexResponse } from 'api/types/ref';

import type { ApiResponse } from './type';


export const getRefs = async (client: AxiosInstance): Promise<RefsIndexResponse[]> => {
	const {
		data: { data },
	} = await client.get<{ data: RefsIndexResponse[] }>(`referential`);

	return data;
};

export const getRefByTask = async (client: AxiosInstance, refID: string): Promise<ReferentialResponse[]> => {
	const res: AxiosResponse<ApiResponse<ReferentialApiResponse[]>> = await client.get(
		`referential/${refID}/search`,
	);
	const filteredRef: ReferentialResponse[] | undefined = res.data?.data?.filter((e): e is ReferentialResponse => e.id !== null);


	return filteredRef?.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
};

export const getRefIDByTypeRef = async (client: AxiosInstance, typeReference: string): Promise<string | undefined> => {
	const { data }: AxiosResponse<ApiResponse<{ _id: string }[]>> = await client.get(
		`referential?typeReference=${typeReference}`,
	);

	if (data?.data && data?.data.length === 1) {
		const [first] = data.data;

		return first._id;
	}
};
