import React, { createContext, useContext, useEffect, useState } from 'react';

import i18n from 'utils/lang';
import Loader from 'components/Loader';
import { CounterByResource } from 'api/types/counter';
import { counterByResource } from 'api/routes/counter';
import { ReferentialResponse } from 'api/types/ref';
import { showError } from 'utils/error';
import { usePreferences } from 'providers/preferences';
import { EOTPCategory } from 'utils/constants';
import { useClient } from 'providers/client';

import { useReports } from '../../../../../providers/ReportsProvider';
import { ReportStatus } from '../../../../../api/types/report';
import { ReportContext } from '../..';


export type ColType = {
	rawLabel: string;
	label: string;
};

export type ReportDataResourcesContextType = {
	cols: ColType[];
	resources: CounterByResource[];
	fetchCounters: () => void;
};

export const ReportDataResourcesContext = createContext<ReportDataResourcesContextType>({
	cols: [],
	resources: [],
	fetchCounters: () => { },
});

type Props = {
	date: string | undefined;
	categoryID: number;
	labelsEOTP: Readonly<ReferentialResponse[]>;
	children: React.ReactNode;
};

export const ReportDataResourcesProvider: React.FC<Props> = ({ date, categoryID, labelsEOTP, children }) => {
	const { clientHandler: { client } } = useClient();
	const { currentReport } = useReports();
	const { refresh } = useContext(ReportContext);
	const { preferences } = usePreferences();

	const [resources, setResources] = useState<CounterByResource[] | undefined>(undefined);
	const [cols, setCols] = useState<ColType[] | undefined>(undefined);

	const fetchCounters = (): void => {
		if (!date) return;
		const prefsEOTPtaskIDs = preferences.tasks?.find((e) => e.categoryID === EOTPCategory._id)?.refs.map((r) => r.id);
		const reportStatus = currentReport?.status ?? ReportStatus.Invalidated;
		const codes = preferences.tasks?.find((t) => t.categoryID === categoryID)?.refs.map((r) => r.id) ?? [];

		counterByResource(client, new Date(date), {
			prefEOTP: reportStatus === ReportStatus.Invalidated ? prefsEOTPtaskIDs : undefined,
			prefCodes: reportStatus === ReportStatus.Invalidated ? codes : undefined,
			checked: reportStatus !== ReportStatus.Invalidated,
			reportId: currentReport?._id,
		})
			.then((data) => {
				if (!data || data.length === 0) {
					setResources([]);
					setCols([]);
					return;
				}

				setResources(
					data
						.map((counter) => {
							const filteredSources = counter.sources
								.filter((s) => s.CategoryId === categoryID)
								.map(
									(source) => ({
										sum: source.baseCounter.include ? source.tasks.reduce((acc, curr) => acc + curr.quantity, 0) : 0,
										...source
									})
								);
							const hasSameUnitData = filteredSources.every((s) => s.unit === filteredSources[0].unit);
							const sumByTask = filteredSources.length > 0
								? filteredSources[0].tasks.map((task) => {
									if (!hasSameUnitData) return { sum: 0, unit: '' };

									const filteredTasks = filteredSources.map((s) => s.baseCounter.include ? s.tasks.find((t) => t.task === task.task) : undefined);

									return {
										sum: filteredTasks.reduce((acc, curr) => acc + (curr?.quantity ?? 0), 0),
										unit: filteredSources[0].unit
									};
								})
								: [];
							const sum = filteredSources.length > 0 && hasSameUnitData
								? { sum: filteredSources.reduce((acc, curr) => acc + curr.sum, 0), unit: filteredSources[0].unit }
								: undefined

							return {
								...counter,
								sumByTask,
								sum,
								sources: filteredSources,
							}
						})
						.filter((counter) => counter.sources.length > 0)
				);
				try {
					if (data[0].sources.length > 0) {
						const taskCols = data[0].sources[0].tasks
							.filter((t) => t.task !== '')
							.map((t) => ({ rawLabel: t.task, label: labelsEOTP.find((e) => e.id === t.task)?.label || (i18n.t('unknown') ?? '?') }));


						setCols(taskCols);
					} else {
						setCols([]);
					}
				} catch (err) {
					console.error(err, data[0]);
				}
			})
			.catch(showError('Failed to fetch counter by resource'));
	};

	useEffect(() => {
		if (date) {
			setResources(undefined);
			setCols(undefined);
			fetchCounters();
		}
	}, [date, categoryID, refresh]);

	if (!resources || !cols) {
		return <Loader />;
	}

	return (
		<ReportDataResourcesContext.Provider
			value={{
				cols,
				resources,
				fetchCounters,
			}}
		>
			{children}
		</ReportDataResourcesContext.Provider>
	);
};

export const useReportDataResources = (): ReportDataResourcesContextType => useContext(ReportDataResourcesContext);
