import { AxiosInstance, AxiosResponse } from 'axios';

import { CategoryType } from '../types/category';
import type { ApiResponse } from './type';

export const getAllCategory = async (client: AxiosInstance): Promise<CategoryType[]> => {
	const {
		data: { data },
	}: AxiosResponse<ApiResponse<CategoryType[]>> = await client.get('category');

	return data;
};
