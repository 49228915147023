import React from 'react';

type Props = {
	color?: string;
}

const StatusLock: React.FC<Props> = ({ color }) => {
	return (
		<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.0675 6.08335H10.1364C10.1288 6.0828 10.1212 6.0828 10.1136 6.0828H10.1399V4.2743C10.1399 3.41088 9.77679 2.58221 9.13055 1.97203C8.48431 1.36128 7.19942 0.962952 6.28581 0.962952C5.3722 0.962952 4.14569 1.36128 3.49944 1.97203C2.8532 2.58277 2.49068 3.41088 2.49068 4.2743V6.0828H2.28636L2.28285 6.08335H1.56247C0.975778 6.08335 0.5 6.53299 0.5 7.08746V13.463C0.5 14.0174 0.975778 14.4671 1.56247 14.4671H11.0675C11.6542 14.4671 12.13 14.0174 12.13 13.463V7.08746C12.13 6.53299 11.6542 6.08335 11.0675 6.08335ZM4.4043 6.08335V4.2732C4.4043 3.75735 4.69443 3.28012 5.16729 3.02248C5.39964 2.89558 5.66409 2.73559 6.28581 2.73559C6.90753 2.73559 7.22452 2.89227 7.46737 3.02524C7.93731 3.28178 8.2257 3.7568 8.2257 4.26989V6.08335H4.4043Z"
				fill={color ?? "white"}
			/>
		</svg>
	);
};

export default StatusLock;
