import { WeatherFlag } from 'api/utils';
import Fog from 'assets/svg/weather/Fog';
import Sun from 'assets/svg/weather/Sun';
import Rain from 'assets/svg/weather/Rain';
import Wind from 'assets/svg/weather/Wind';
import Snow from 'assets/svg/weather/Snow';
import Frost from 'assets/svg/weather/Frost';

type WeatherComponentType = {
    component: React.FC<{ color?: string }>;
    value: WeatherFlag;
}

const WeatherComponent: WeatherComponentType[] = [
    {
        component: Sun,
        value: WeatherFlag.Sun
    },
    {
        component: Rain,
        value: WeatherFlag.Rain
    },
    {
        component: Wind,
        value: WeatherFlag.Wind,
    },
    {
        component: Snow,
        value: WeatherFlag.Snow,
    },
    {
        component: Frost,
        value: WeatherFlag.Frost,
    },
    {
        component: Fog,
        value: WeatherFlag.Fog,
    }
];

export default WeatherComponent;