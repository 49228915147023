import React, { createContext, useCallback, useContext, useState } from 'react';

import { Container } from './style';

export type ModalContextType = {
	content?: React.ReactNode;
	pop: () => void;
	push: (value: React.ReactNode) => void;
};

const ModalContext = createContext<ModalContextType>({
	pop: () => { },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	push: (_) => { },
});

type Props = {
	children: React.ReactNode;
};

export const ModalProvider: React.FC<Props> = ({ children }) => {
	const [content, setContent] = useState<React.ReactNode>();

	const pop = useCallback((): void => {
		setContent(undefined);
	}, []);

	const push = useCallback((value: React.ReactNode): void => {
		setContent(value);
	}, []);

	return (
		<ModalContext.Provider value={{ content, pop, push }}>
			{!!content && <Container>{content}</Container>}
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = (): ModalContextType => useContext(ModalContext);
