import React, { useEffect, useState } from 'react';

import SearchSVG from 'assets/svg/Search';
import i18n from 'utils/lang';
import Input, { InputProps } from 'components/Input';
import Button, { Type as ButtonType } from 'components/Button';
import Add from 'assets/svg/Add';

import Container, { IconContainer, CursorPointer } from './style';
import CrossSearch from 'assets/svg/CrossSearch';

export enum SearchType {
	Default,
	FullScreen,
	FullWidth,
	New,
	Report,
	ResourceModal,
	AddFreeResource,
	FilterCounter,
}

type Props = InputProps & {
	onValueChange?: (v: string) => unknown;
	type?: SearchType;
	onNewClicked?: () => void;
	clearText?: () => void;
};

const Search: React.FC<Props> = ({ type, onNewClicked, clearText, value, ...props }) => {
	const [hasChange, setHasChange] = useState(false);

	useEffect(() => {
		setHasChange(value !== undefined && value.toString().length > 0)
	}, [value])

	return (
		<Container type={type}>
			<Input {...props} value={value} placeholder={i18n.t(props.placeholder ?? 'name').toString()} />
			<IconContainer type={type}>
				{type && (type === SearchType.New || type === SearchType.AddFreeResource) ? (
					<Button type={ButtonType.primary} onClick={onNewClicked}>
						<Add />
					</Button>
				) : (
					hasChange ? (
						<CursorPointer onClick={clearText}>
							<CrossSearch />
						</CursorPointer>
					) : <SearchSVG />
				)}
			</IconContainer>
		</Container>
	);
};

export default Search;
