import React, { useContext, useEffect, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { showError } from 'utils/error';
import Checkbox, { State } from 'components/Checkbox';
import { SourceType } from 'api/types/source';
import ArrowRight from 'assets/svg/ArrowRight';
import ArrowDown from 'assets/svg/ArrowDown';
import { ReportDataSourceContext } from 'widgets/Report/provider/counters/sources';
import { updateCounter } from 'api/routes/counter';
import { Flex } from 'styles';
import { useReports } from 'providers/ReportsProvider';
import { formatNumber } from 'utils/formatNumber';
import { ReportStatus } from 'api/types/report';
import { updateCheckedCounter } from 'api/routes/checkedCounter';
import { AccordionState, useAccordion } from 'widgets/Report/provider/accordion';
import { useClient } from 'providers/client';
import { Col, ColNotSpecified, ColType, Cols, ScrollCols, TotalCol } from '../../style';
import Row from './Row';
import {
	AccordionContainer,
	LeftContainer,
	SectionContainer,
	Title,
	RowsContainer,
	CursorPointerFlex,
} from './style';

export const statefromCheckedValues = (includes: boolean[]): State => {
	if (includes.length === 0) return State.Unchecked;

	let allTrue = true;
	let allFalse = true;

	for (const include of includes) {
		if (include) {
			allFalse = false;
		} else {
			allTrue = false;
		}

		if (!allTrue && !allFalse) break;
	}
	if (allFalse && !allTrue) return State.Unchecked;
	if (!allFalse && allTrue) return State.Checked;

	return State.PartiallyChecked;
};

type Props = {
	source: SourceType;
	sourcesWithSameUnit: SourceType[];
	showEOTPs?: boolean;
};

const Accordion: React.FC<Props> = ({ source, sourcesWithSameUnit, showEOTPs = false }) => {
	const { accordionState } = useAccordion();
	const { clientHandler: { client } } = useClient();
	const [open, setOpen] = useState<boolean>(accordionState === AccordionState.AllOpen);
	const { fetchCounters } = useContext(ReportDataSourceContext);
	const state = statefromCheckedValues(source.counters?.map(({ include }) => include) ?? []);
	const [firstSum, ...sums] = source.sums ?? [];
	const { canEdit, currentReport } = useReports();

	const sumOfSum: number | undefined = source.counters
		?.map((e) => e.sum)
		.filter((e) => e !== undefined)
		.reduce((a = 0, b = 0) => a + b);

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;

	const checkAllChild = (): void => {
		const include: boolean = state === State.Checked;
		const tasksIds: number[] = source.counters
			? ([] as number[]).concat(
				...source.counters.map<number[]>((c) =>
					c.tasks.map<number>(({ _id }) => (_id != null ? _id : -1)).filter((e) => e !== -1),
				),
			)
			: [];

		Promise.all(tasksIds.map((taskID) => updateCounterFn(client, { include: !include }, taskID.toString()))).catch(
			showError('Failed to update counters'),
		);

		setTimeout(() => {
			fetchCounters();
		}, tasksIds.length * 200);
	};

	useEffect(() => {
		if (accordionState === AccordionState.AllOpen) {
			setOpen(true);
		} else if (accordionState === AccordionState.AllClosed) {
			setOpen(false);
		}
	}, [accordionState])

	return (
		<SectionContainer>
			<AccordionContainer>
				<Flex alignItems="center" minWidth="0" margin="0 0 0 10px">
					<Checkbox disabled={!canEdit} state={state} onClick={checkAllChild} />
					<LeftContainer onClick={() => setOpen(!open)} isCursorPointer>
						<Title style={{ flexGrow: 1 }}>
							{source.label} ({source.unit})
						</Title>
					</LeftContainer>
				</Flex>
				<CursorPointerFlex alignItems="center" justifyContent='center' onClick={() => setOpen(!open)}>
					{(open) ? <ArrowDown color="black" /> : <ArrowRight color="black" />}
				</CursorPointerFlex>
				<ColNotSpecified type={ColType.Accordion}>
					{formatNumber(firstSum)} {source.unit}
				</ColNotSpecified>
				<Cols>
					{showEOTPs && !!sums && sums.length > 0 && (
						<ScrollSyncPane group="horizontal">
							<ScrollCols type={ColType.Accordion}>
								{sums.map((e: number, idx: number) => (
									<Col key={`Accordion-Col-${idx}-${e}`} type={ColType.Accordion}>
										<span>
											{e !== 0 && `${formatNumber(e)} ${source.unit}`}
										</span>
									</Col>
								))}
							</ScrollCols>
						</ScrollSyncPane>
					)}
				</Cols>
				<TotalCol type={ColType.Accordion}>
					{formatNumber(sumOfSum ?? 0)} {source.unit}
				</TotalCol>
			</AccordionContainer>
			<RowsContainer>
				{(open) &&
					source.counters?.map((counter, idx) => (
						<Row
							key={`Accordion-Container-${idx}-${counter.code}`}
							counter={counter}
							unit={source.unit}
							sourceID={source._id.toString()}
							showEOTPs={showEOTPs}
							sourcesWithSameUnit={sourcesWithSameUnit}
						/>
					))}
			</RowsContainer>
		</SectionContainer>
	);
};

export default Accordion;
