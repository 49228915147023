import styled from 'styled-components';

import { Flex } from 'styles';

const Container = styled(Flex)`
	flex-direction: row;
	width: 100%;
`;

export const FilterTitle = styled.b`
	width: max-content;
	text-align: center;
`;

export const FilterContainer = styled(Flex)`
	flex-direction: row;
	overflow-x: auto;
	gap: 8px;
	margin-left: 8px;
`;

export type SquareProps = {
	color: string;
};

export const Square = styled.div<SquareProps>`
	background-color: ${({ color }) => color};
	width: 16px;
	height: 16px;
`;

export const BoldText = styled.b`
	color: #25465F;
`

export default Container;
