import { AxiosResponse, AxiosInstance } from 'axios';

import { CounterByResource, CounterByTask, CounterCreate, CounterResponse, CounterType, CounterUpdate } from '../types/counter';
import type { ApiResponse } from './type';
import { SourceType } from '../types/source';

type CounterByBody = {
	dateCounter: string;
	tasks?: string;
	codes?: string;
	checked?: string;
	ReportId?: number;
};

type CounterHasData = {
	date: string; // format: YYYY-MM-DD
	hasData: boolean;
}

export const counterBySource = async (
	client: AxiosInstance,
	date: Date,
	{ prefEOTP, prefCodes, checked, reportId }: Partial<{ prefEOTP: string[]; prefCodes: string[]; checked: boolean; reportId: number | undefined; }> = {},
): Promise<SourceType[]> => {
	const body : CounterByBody = {
		dateCounter: date.toISOString(),
	};

	if (!!prefEOTP && prefEOTP.length !== 0) {
		body.tasks = prefEOTP.join('|');
	}

	if (!!prefCodes && prefCodes.length !== 0) {
		body.codes = prefCodes.join('|');
	}

	if (checked != null) {
		body.checked = checked ? 'true' : 'false';
	}

	if (checked && !!reportId) {
		body.ReportId = reportId;
	}

	const res: AxiosResponse<ApiResponse<SourceType[]>> = await client.post('counterbysource', body);
	return res.data?.data?.sort((a, b) => a.order - b.order);
};

export const counterByTask = async (client: AxiosInstance, date: Date, prefEOTP?: string[], prefCodes?: string[], checked?: boolean, reportId?: number): Promise<CounterByTask[]> => {
	const body : CounterByBody = {
		dateCounter: date.toISOString(),
	};

	if (!!prefEOTP && prefEOTP.length !== 0) {
		body.tasks = prefEOTP.join('|');
	}

	if (!!prefCodes && prefCodes.length !== 0) {
		body.codes = prefCodes.join('|');
	}

	if (checked != null) {
		body.checked = checked ? 'true' : 'false';
	}

	if (checked && !!reportId) {
		body.ReportId = reportId;
	}

	const res: AxiosResponse<ApiResponse<CounterByTask[]>> = await client.post('counterbytask', body);

	return res.data?.data;
};

export const counterByResource = async (
	client: AxiosInstance,
	date: Date,
	{ prefEOTP, prefCodes, checked, reportId }: Partial<{ prefEOTP: string[]; prefCodes: string[]; checked: boolean; reportId: number | undefined; }> = {},
): Promise<CounterByResource[]> => {
	const body : CounterByBody = {
		dateCounter: date.toISOString(),
	};

	if (!!prefEOTP && prefEOTP.length !== 0) {
		body.tasks = prefEOTP.join('|');
	}

	if (!!prefCodes && prefCodes.length !== 0) {
		body.codes = prefCodes.join('|');
	}

	if (checked != null) {
		body.checked = checked ? 'true' : 'false';
	}

	if (checked && !!reportId) {
		body.ReportId = reportId;
	}

	const res: AxiosResponse<ApiResponse<CounterByResource[]>> = await client.post('counterbyresource', body);

	return res.data?.data;
};


export const updateCounter = async (client: AxiosInstance, data: CounterUpdate, task_id: string): Promise<CounterType> => {
	const res: AxiosResponse<ApiResponse<CounterType>> = await client.put(`counter/${task_id}`, data);

	return res.data?.data;
};

export const deleteCounter = async (client: AxiosInstance, task_id: string): Promise<void> => {
	return client.delete(`counter/${task_id}`);
};

export const createCounter = async (client: AxiosInstance, data: CounterCreate): Promise<CounterType> => {
	const res: AxiosResponse<ApiResponse<CounterType>> = await client.post(`counter`, data);

	return res.data?.data;
};

export const counterHasData = async (client: AxiosInstance, startDate: Date, endDate: Date): Promise<CounterHasData[]> => {
	const res: AxiosResponse<ApiResponse<CounterHasData[]>> = await client.get(`counter/hasdata?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}`);
	return res.data?.data;
};

export const counterListCalendar = async (client: AxiosInstance): Promise<CounterResponse[]> => {
	const res: AxiosResponse<ApiResponse<CounterResponse[]>> = await client.get('counter');

	return res.data?.data;
};
