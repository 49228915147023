import styled from "styled-components";

const Container = styled.div`
    display: grid;
    justify-content: center;
    height: 100%;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    a {
        padding: 5px 80px;
        width: 150px;
        max-height: 100px;
        cursor: pointer;
    }
`

export default Container;