import React from 'react';

const Move: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
			<path
				fill="#000"
				d="m20.5 10 .707.707.707-.707-.707-.707L20.5 10Zm-17 8a1 1 0 1 0 2 0h-2Zm12.707-2.293 5-5-1.414-1.414-5 5 1.414 1.414Zm5-6.414-5-5-1.414 1.414 5 5 1.414-1.414ZM20.5 9h-10v2h10V9Zm-17 7v2h2v-2h-2Zm7-7a7 7 0 0 0-7 7h2a5 5 0 0 1 5-5V9Z"
			/>
		</svg>
	);
};

export default Move;
