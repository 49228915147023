import React from 'react';

const Search: React.FC = () => {
	return (
		<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.0165 13.9999L8.2495 9.23394C6.09332 10.6563 3.21051 10.2163 1.57683 8.21549C-0.0568595 6.21464 0.0885937 3.30207 1.9135 1.47394C3.74131 -0.351663 6.65421 -0.497747 8.6555 1.13583C10.6568 2.7694 11.097 5.65254 9.6745 7.80894L14.4395 12.5769L13.0165 13.9999ZM5.4735 2.01394C4.03275 2.01303 2.79198 3.02996 2.50999 4.44285C2.22799 5.85573 2.98331 7.27106 4.31403 7.82329C5.64474 8.37553 7.18024 7.91086 7.98148 6.71345C8.78273 5.51605 8.62657 3.91941 7.6085 2.89994C7.04375 2.33129 6.27494 2.01224 5.4735 2.01394Z"
				fill="#25465F"
			/>
		</svg>
	);
};

export default Search;
