import React from 'react';

const Add: React.FC = () => {
	return (
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.03589 16H9.53589V9.25H16.2859V6.75H9.53589V0H7.03589V6.75H0.285889V9.25H7.03589V16Z" fill="white" />
		</svg>
	);
};

export default Add;
