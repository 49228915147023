import { toast } from 'react-toastify';

export const showError =
	(reason: string) =>
	(error: Error): void => {
		console.error(error);

		if (process.env.NODE_ENV === 'development') {
			toast.error(`Error: ${reason}`);
		}
	};
