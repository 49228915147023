import React from 'react';

const Copy: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
			<path
				stroke="#000"
				strokeWidth="2"
				d="M10 6.6c0-1.5 0-2.25.382-2.776.123-.17.273-.319.442-.442C11.35 3 12.1 3 13.6 3H16c1.886 0 2.828 0 3.414.586C20 4.172 20 5.114 20 7v5c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C18.398 15 17.932 15 17 15"
			/>
			<rect width="10" height="12" x="5" y="9" stroke="#000" strokeWidth="2" rx="2" />
		</svg>
	);
};

export default Copy;
