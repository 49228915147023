import React, { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import { SourceType } from 'api/types/source';
import { counterBySource } from 'api/routes/counter';
import { FeaturesFlag, hasFlags } from 'api/utils';
import Loader from 'components/Loader';
import { CounterType } from 'api/types/counter';
import { TaskType } from 'api/types/task';
import { ReferentialResponse } from 'api/types/ref';
import { usePreferences } from 'providers/preferences';
import { EOTPCategory } from 'utils/constants';
import { showError } from 'utils/error';
import { useReports } from 'providers/ReportsProvider';
import { ReportStatus } from 'api/types/report';
import i18n from 'utils/lang';
import { useClient } from 'providers/client';
import { ReportContext } from '../..';

export const sortTitles = ({ title: a }: { title: string }, { title: b }: { title: string }): number =>
	a < b ? -1 : a > b ? 1 : 0;

export const sumTasksRow = (tasks: TaskType[], hasInclude: boolean): number =>
	tasks.map((e) => (hasInclude ? e.quantity : 0)).reduce((a, b) => a + b);

export const sumTasksCol = (tasks: TaskType[], counters: CounterType[]): number[] =>
	tasks.map((_, idx) => counters.map((c) => (c.include ? c.tasks[idx]?.quantity : 0)).reduce((a, b) => a + b));

const formatSource =
	(hasLabelBefore: boolean) =>
	(f: SourceType): SourceType => {
		const counters = f.counters
			?.map((c) => ({
				...c,
				title: hasLabelBefore
					? [c.name, c.code].filter(Boolean).join(' - ')
					: [c.code, c.name].filter(Boolean).join(' - '),
				sum: sumTasksRow(c.tasks, c.include),
			}))
			.sort(sortTitles);

		return {
			...f,
			counters,
			sums: counters ? sumTasksCol(counters[0].tasks, counters) : undefined,
		};
	};

export type ColType = {
	rawLabel: string;
	label: string;
};

export type ReportDataSourceContextType = {
	cols: ColType[];
	sources: SourceType[];
	fetchCounters: () => void;
};

export const ReportDataSourceContext = createContext<ReportDataSourceContextType>({
	cols: [],
	sources: [],
	fetchCounters: () => {},
});

export const useReportDataSource = (): ReportDataSourceContextType => useContext(ReportDataSourceContext);

type Props = {
	date: string | undefined;
	selectedCategory: number;
	features?: number;
	labelsEOTP: Readonly<ReferentialResponse[]>;
};

export const ReportDataSourceProvider: React.FC<PropsWithChildren<Props>> = ({
	date,
	selectedCategory,
	features,
	labelsEOTP,
	children,
}) => {
	const { clientHandler: { client } } = useClient();
	const { currentReport } = useReports();
	const { refresh } = useContext(ReportContext);
	const { preferences } = usePreferences();

	const [sources, setSources] = useState<SourceType[] | undefined>(undefined);
	const [cols, setCols] = useState<ColType[] | undefined>(undefined);

	// Simple (redundant) tracker for current category
	// This allows function to check if the selectedCategory has changed during their execution
	const currentlyDisplayedCategory = useRef(selectedCategory);
	useEffect(() => {
		currentlyDisplayedCategory.current = selectedCategory;
	}, [selectedCategory]);

	const fetchCounters = (): void => {
		if (!(date && features && selectedCategory >= 0)) return;

		const prefsEOTPtaskIDs = preferences.tasks?.find((e) => e.categoryID === EOTPCategory._id)?.refs.map((r) => r.id);
		const hasLabelBefore = hasFlags(features, FeaturesFlag.LabelBefore);

		const reportStatus = currentReport?.status ?? ReportStatus.Invalidated;

		const codes = preferences.tasks?.find((t) => t.categoryID === selectedCategory)?.refs.map((r) => r.id) ?? [];
		counterBySource(client, new Date(date), {
			prefEOTP: reportStatus === ReportStatus.Invalidated ? prefsEOTPtaskIDs : undefined,
			prefCodes: reportStatus === ReportStatus.Invalidated ? codes : undefined,
			checked: reportStatus !== ReportStatus.Invalidated,
			reportId: currentReport?._id,
		})
			.then((data) => {
				// Check if selectedCategory has changed in the meantime to avoid useless operations that could result in bugs
				if (selectedCategory !== currentlyDisplayedCategory.current) return;

				if (!data || data.length === 0) {
					setSources([]);
					setCols([]);
					return;
				}

				const filteredData = data?.filter((e) => e.CategoryId === selectedCategory)?.map(formatSource(hasLabelBefore));
				setSources(filteredData);

				if (filteredData && filteredData.length >= 1 && filteredData[0].counters) {
					const res = filteredData[0].counters[0].tasks
						.filter((t) => t.task !== '')
						.map((t) => ({ rawLabel: t.task, label: labelsEOTP.find((e) => e.id === t.task)?.label || (i18n.t('unknown') ?? '?') }));

					setCols(res);
				} else {
					setCols([]);
				}
			})
			.catch(showError('Failed to fetch counter by sources'));
	};

	useEffect(() => {
		if (date && features && selectedCategory >= 0) {
			setSources(undefined);
			fetchCounters();
		}
	}, [selectedCategory, refresh]);

	if (!sources || !cols) {
		return <Loader />;
	}

	return (
		<ReportDataSourceContext.Provider
			value={{
				cols,
				sources,
				fetchCounters,
			}}
		>
			{children}
		</ReportDataSourceContext.Provider>
	);
};
