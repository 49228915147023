import React from 'react';

const ArrowLeft: React.FC = () => {
	return (
		<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.288086 6.99999L6.29809 13.01L7.71209 11.596L3.11209 6.99599L7.71209 2.39599L6.29809 0.98999L0.288086 6.99999Z"
				fill="white"
			/>
		</svg>
	);
};

export default ArrowLeft;
