import React, { useEffect, useState, useId } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { formatNumber } from 'utils/formatNumber';

import Checkbox, { State } from 'components/Checkbox';
import ArrowRight from 'assets/svg/ArrowRight';
import ArrowDown from 'assets/svg/ArrowDown';
import { Flex } from 'styles';
import { CounterByResource } from 'api/types/counter';
import { CategoryType } from 'api/types/category';
import CustomTooltip from 'components/Tooltip';
import { useReports } from 'providers/ReportsProvider';
import { AccordionState, useAccordion } from 'widgets/Report/provider/accordion';

import { Col, ColNotSpecified, ColType, Cols, ScrollCols, TotalCol } from '../../style';
import {
	AccordionContainer,
	SectionContainer,
	CursorPointerFlex,
	AccordionRowText,
	RowsContainer,
	LeftContainer,
} from './style';
import RowResource from './RowResource';
import { ReportStatus } from 'api/types/report';
import { updateCheckedCounter } from 'api/routes/checkedCounter';
import { updateCounter } from 'api/routes/counter';
import { useClient } from 'providers/client';
import { showError } from 'utils/error';
import { useReportDataResources } from 'widgets/Report/provider/counters/resources';

export const statefromCheckedValues = (includes: boolean[]): State => {
	if (includes.length === 0) return State.Unchecked;

	let allTrue = true;
	let allFalse = true;

	for (const include of includes) {
		if (include) {
			allFalse = false;
		} else {
			allTrue = false;
		}

		if (!allTrue && !allFalse) break;
	}
	if (allFalse && !allTrue) return State.Unchecked;
	if (!allFalse && allTrue) return State.Checked;

	return State.PartiallyChecked;
};

type Props = {
	resource: CounterByResource;
	category: CategoryType;
};

const AccordionResources: React.FC<Props> = ({ resource, category }) => {
	const { accordionState } = useAccordion();
	const [open, setOpen] = useState<boolean>(accordionState === AccordionState.AllOpen);
	const state = statefromCheckedValues(resource.sources?.map((source) => source.baseCounter.include) ?? []);
	const { canEdit, currentReport } = useReports();
	const { fetchCounters } = useReportDataResources();
	const { clientHandler: { client } } = useClient();
	const resourceTitleID = useId();
	const [firstSum, ...sums] = resource.sumByTask;

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;

	const checkAllChild = (): void => {
		const include: boolean = state === State.Checked;
		const tasksIds: number[] = resource.sources
			? ([] as number[]).concat(
				...resource.sources.map<number[]>((c) =>
					c.tasks.map<number>(({ _id }) => (_id != null ? _id : -1)).filter((e) => e !== -1),
				),
			)
			: [];

		Promise.all(tasksIds.map((taskID) => updateCounterFn(client, { include: !include }, taskID.toString()))).catch(
			showError('Failed to update counters'),
		);

		setTimeout(() => {
			fetchCounters();
		}, tasksIds.length * 200);
	};


	useEffect(() => {
		if (accordionState === AccordionState.AllOpen) {
			setOpen(true);
		} else if (accordionState === AccordionState.AllClosed) {
			setOpen(false);
		}
	}, [accordionState])

	return (
		<SectionContainer>
			<AccordionContainer>
				<Flex
					alignItems="center"
					width="100%"
					backgroundColor="var(--title-column-bg-color, unset)"
					borderRadius="8px"
					minWidth="0"
					margin="0 0 0 10px"
				>
					<Checkbox disabled={!canEdit} state={state} onClick={checkAllChild} />
					<LeftContainer onClick={() => setOpen(!open)} isCursorPointer>
						<AccordionRowText isResource>
							<b data-tooltip-id={resourceTitleID}>{resource.name && `${resource.name} - `}{resource.code}</b>
							<CustomTooltip
								id={resourceTitleID}
								place='top-start'
							>
								{resource.name && `${resource.name} - `}{resource.code}
							</CustomTooltip>
						</AccordionRowText>
					</LeftContainer>
				</Flex>
				<CursorPointerFlex alignItems="center" justifyContent='center' onClick={() => setOpen(!open)}>
					{(open) ? <ArrowDown color="black" /> : <ArrowRight color="black" />}
				</CursorPointerFlex>
				<ColNotSpecified type={ColType.Accordion}>
					{firstSum.sum !== 0 && `${formatNumber(firstSum.sum)} ${firstSum.unit}`}
				</ColNotSpecified>
				<Cols>
					{!!sums && sums.length > 0 && (
						<ScrollSyncPane group="horizontal">
							<ScrollCols type={ColType.Accordion}>
								{sums.map((e, idx) => (
									<Col key={`Accordion-Col-${idx}-${e.sum}`} type={ColType.Accordion}>
										<span>
											{e.sum !== 0 && `${formatNumber(e.sum)} ${e.unit}`}
										</span>
									</Col>
								))}
							</ScrollCols>
						</ScrollSyncPane>
					)}
				</Cols>
				<TotalCol type={ColType.Accordion}>
					{resource.sum && `${resource.sum.sum} ${resource.sum.unit}`}
				</TotalCol>
			</AccordionContainer>
			<RowsContainer>
				{open && resource.sources.map((source, idx) => (
					<RowResource
						key={`Accordion-Container-${idx}-${source._id}`}
						currentResource={resource}
						category={category}
						source={source}
					/>
				))}
			</RowsContainer>
		</SectionContainer>
	);
};

export default AccordionResources;
