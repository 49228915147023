import React, { ReactElement, useEffect, useId, useMemo, useRef, useState } from 'react';

import Tick from 'assets/svg/Tick';
import Lock from 'assets/svg/Lock';
import i18n from 'utils/lang';
import { Flex } from 'styles';
import { ContentPadding } from 'widgets/Calendar/style';
import { useUser } from 'providers/user';
import StatusDoubleCheck from 'assets/svg/StatusDoubleCheck';
import CustomTooltip from 'components/Tooltip';

import Container, { IconContainer, NameContainer, ReportAuthorName, ReportName } from './style';

export enum UserStatus {
	None = 0,
	Done,
	Checked,
	Locked,
}

export const numIntoUserStatus = (value: number): UserStatus => {
	switch (value) {
		case 1:
			return UserStatus.Done;
		case 2:
			return UserStatus.Checked;
		case 3:
			return UserStatus.Locked;
		case 0:
		default:
			return UserStatus.None;
	}
};

export type UserCardProps = {
	username?: string;
	reportname?: string;
	createdBy?: string;
	status: UserStatus;
	onClick: () => unknown;
};

const buildIconByStatus = (status: UserStatus): ReactElement => {
	switch (status) {
		case UserStatus.Done:
			return <Tick />;
		case UserStatus.Checked:
			return <StatusDoubleCheck color="#4D9CBF" />;
		case UserStatus.Locked:
			return <Lock />;
		default:
			return <></>;
	}
};

const UserCard: React.FC<UserCardProps> = ({ username, reportname, createdBy, status, onClick }) => {
	const reportNameIsUsername = reportname === username;
	const [needTooltip, setNeedTooltip] = useState<boolean>(false);
	const userInfos = useUser();
	const reportRef = useRef<HTMLElement>(null);
	const reportNameId = useId();

	const canSee = useMemo(() => {
		// Any report in status Done, Checked or Locked can be seen by everyone
		if (status === UserStatus.Done || status === UserStatus.Checked || status === UserStatus.Locked) return true;

		// Only the editor of a not validated report can see it
		return status === UserStatus.None && userInfos?.infos?.preferred_username === createdBy;
	}, [status, userInfos]);

	useEffect(
		() => {
			if (reportRef.current) {
				setNeedTooltip(reportRef.current.scrollHeight > reportRef.current.clientHeight)
			}
		}, [reportRef]
	)

	return (
		<ContentPadding onClick={onClick}>
			<Container data-disabled={!canSee}>
				<NameContainer>
					<Flex flexGrow="1" width="100%">
						<ReportName ref={reportRef} data-tooltip-id={reportNameId}>
							{reportname}
						</ReportName>
						{
							needTooltip && (
								<CustomTooltip id={reportNameId} place="top" width='11vw'>
									{reportname}
								</CustomTooltip>
							)
						}
					</Flex>
					<IconContainer>{buildIconByStatus(status)}</IconContainer>
				</NameContainer>
				{
					!reportNameIsUsername && (
						<div>
							<ReportAuthorName>{username || i18n.t('undefined')}</ReportAuthorName>
						</div>
					)
				}
			</Container>
		</ContentPadding>
	);
};

export default UserCard;
