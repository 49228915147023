import Fuse from 'fuse.js';
import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { SelectableReferential } from 'widgets/Report/components/Modal/type';

type UseSearchType<T> = {
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
	handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	filteredItems: T[];
};

export function useSearch<T>(itemsToFilter: T[], fieldsToFilterOn: string[], preciseSearchOnID?: boolean): UseSearchType<T> {
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebounce(searchValue, 250);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setSearchValue(e.target.value);
	}, []);

	const fuse = useMemo(
		() => {
			// Only search on the label field for approximative search
			if (!!preciseSearchOnID) {
				return new Fuse(itemsToFilter, { keys: ['label'], threshold: 0.3 });
			}
			return new Fuse(itemsToFilter, { keys: fieldsToFilterOn, threshold: 0.3 });
		},
		[itemsToFilter, fieldsToFilterOn],
	);

	const filteredItems = useMemo(
		() => {
			if (debouncedSearchValue === '') return itemsToFilter;
			if (preciseSearchOnID) {
				const itemsToFilterArray = itemsToFilter as SelectableReferential[];
				const approximativeSearchResult = fuse.search(debouncedSearchValue)
					.map((result) => result.item) as SelectableReferential[];
				// Return items that match the search value exactly for the title or the approximative search result for label
				return itemsToFilterArray.filter(item =>
					item?.id?.toString().toLowerCase().includes(debouncedSearchValue.toLowerCase())
					|| approximativeSearchResult.
						findIndex(approximativeSearchItem => approximativeSearchItem.id === item.id) !== -1) as T[];
			}
			return fuse.search(debouncedSearchValue).map((result) => result.item);
		},
		[fuse, debouncedSearchValue],
	);

	return {
		searchValue,
		setSearchValue,
		handleSearchChange,
		filteredItems,
	};
}
