import styled from 'styled-components';
import { Flex } from 'styles';

const Container = styled(Flex)`
	height: 0;
	flex-grow: 1;

	flex-direction: column;
`;

export default Container;
