import React, { useContext, useMemo } from "react";

import { FeaturesFlag, hasFlags } from "api/utils";
import { ReportContext } from "widgets/Report/provider";
import Switch from "components/Switch";
import i18n from "utils/lang";

import Container from "./style";
import { usePreferences } from "providers/preferences";

const SwitchView: React.FC = () => {
    const { selectedCategory } = useContext(ReportContext);
    const { viewRessource, setViewRessource } = usePreferences();
    const hasResourceView = useMemo(() => !!selectedCategory && hasFlags(selectedCategory.features, FeaturesFlag.ResourceView), [selectedCategory]);

    return (
        <Container display={hasResourceView ? 'flex' : 'none'}>
            <p>{i18n.t('switch_view')}</p>
            <Switch value={viewRessource} onChange={() => setViewRessource(!viewRessource)} onColor="#25465F" />
        </Container>
    )
}

export default SwitchView;