import { useCallback, useRef, useState } from 'react';
import i18n from 'i18next';
import { useEffectOnce } from 'usehooks-ts';
import * as R from 'ramda';
import { EOTP_TYPE_REFERENCE, PRODUCTION_TYPE_REFERENCE } from 'utils/constants';
import { getRefByTask, getRefs } from 'api/routes/referential';
import { getAllCategory } from 'api/routes/category';
import {
	addPreparedCounterItem,
	checkPreparedCounterItem,
	getPreparedCounters,
	uncheckPreparedCounterItem,
} from 'api/routes/preparedcounter';
import { PreparedCounterResponse } from 'api/types/preparedCounter';
import { ReferentialResponse } from 'api/types/ref';
import { useClient } from 'providers/client';
import { fetchSource } from '../../api/routes/source';

export type PreparedCounterItem = { groups: string[]; id: number; label: string; sourceId: number; unit: string };
export type PreparedCounterWithItems = PreparedCounterResponse & {
	items: (PreparedCounterItem & {
		checked: boolean;
		counter: PreparedCounterResponse | null;
		toggle: (task: string, params: { isChecked: boolean }) => Promise<void>;
	})[];
	groups: string[];
	activeGroup: string;
};

type UsePreparedCounterType = {
	preparedCounters: PreparedCounterWithItems[];
	toggleGroup: (task: string, group: string) => void;
	addEOTP: (taskId: string, taskName: string) => void;
};

export function usePreparedCounters(): UsePreparedCounterType {
	const { clientHandler: { client, referentialClient } } = useClient();

	const cachedProdItems = useRef<PreparedCounterItem[]>([]);
	const [preparedCounters, setPreparedCounters] = useState<PreparedCounterWithItems[]>([]);
	let eotpTasks: ReferentialResponse[] = [];

	useEffectOnce(() => {
		(async () => {
			const refs = await getRefs(client);
			if (!refs?.length) return console.error(`Unable to get refs`);

			const eotpRefs = refs.find((r) => r.typeReference === EOTP_TYPE_REFERENCE);
			const prodRefs = refs.find((r) => r.typeReference === PRODUCTION_TYPE_REFERENCE);

			eotpTasks = eotpRefs == null ? [] : await getRefByTask(referentialClient, eotpRefs._id);

			const categories = await getAllCategory(client);
			const prodCategory = categories.find((c) => c.typeReference === PRODUCTION_TYPE_REFERENCE);

			const sources = await fetchSource(client);

			cachedProdItems.current =
				prodRefs == null
					? []
					: (await getRefByTask(referentialClient, prodRefs._id)).map((i) => {
							const [label, groups, sourceId] = i.label?.split('|') as [string, string, string];
							return {
								id: +i.id,
								label,
								groups: groups.split('¤'),
								sourceId: +sourceId,
								unit: sources.find((s) => s._id === +sourceId)?.unit ?? '',
							};
					  });

			const prepCounters = await getPreparedCounters(client, prodCategory?._id ?? -1);
			const preparedCountersWithTask = prepCounters
				.filter((c) => Boolean(c.task))
				.sort((a, b) => (!a.task ? -1 : a.task.localeCompare(b?.task ?? '')));

			const countersGroupedByTask = R.groupBy(R.prop<string>('task'), preparedCountersWithTask);

			const groupedCounters = R.values(
				R.map((counters) => {
					if (!counters) return null; // Simple safety

					const checkedCodes = counters.map((c) => +c.code) ?? [];
					const eotpTask = eotpTasks.find((t) => t.id === counters[0].task);

					const firstCounter = counters[0];

					const items = cachedProdItems.current
						.map((prodItem) => {
							const checked = checkedCodes.includes(prodItem.id);
							let counter: PreparedCounterResponse | null =
								counters.find((c) => +c.code === prodItem.id && c._id) ?? null;

							async function toggle(task: string, { isChecked }: { isChecked: boolean }): Promise<void> {
								if (isChecked && counter) {
									await uncheckPreparedCounterItem(client, counter);
								} else {
									const baseCounter: Omit<PreparedCounterResponse, '_id'> = {
										task,
										code: prodItem.id.toString(),
										name: prodItem.label,
										label: eotpTasks.find(ta => ta.id === task)?.label ?? '',
										SourceId: prodItem.sourceId,
										assignedTo: null,
										work: 'Production',
										cost: null,
										quantity: 0,
										include: true,
										tags: null,
										comeFrom: null,
									};

									counter = await addPreparedCounterItem(client, baseCounter);
								}

								setPreparedCounters((old) => {
									if (!eotpTask?.id && eotpTask?.label) return old; // To avoid checking it everytime

									return old.map((pc) => {
										if (eotpTask && pc.task !== eotpTask?.id) return pc;

										const newItems = pc.items.map((item) =>
											item.id !== prodItem.id
												? item
												: {
														...item,
														counter: !isChecked ? (counter as PreparedCounterResponse) : null,
														checked: !isChecked,
												  },
										);

										return {
											...pc,
											activeGroup:
												pc.groups.find((group) =>
													newItems.reduce((acc, item) => {
														if (!acc) return false;

														return item.groups.indexOf(group) !== -1 ? item.checked : !item.checked;
													}, true),
												) ?? i18n.t('custom'),
											items: newItems,
										};
									});
								});
							}

							if (!counter) {
								return {
									...prodItem,
									toggle,
									checked: false,
									counter: null,
								};
							}

							return {
								...prodItem,
								checked,
								toggle,
								counter: checked ? counter : null,
							};
						})
						.filter(Boolean) as (PreparedCounterItem & {
						checked: boolean;
						counter: PreparedCounterResponse | null;
						toggle: () => Promise<void>;
					})[];

					const groupSet = new Set<string>();
					for (const item of cachedProdItems.current) {
						for (const group of item.groups) {
							groupSet.add(group);
						}
					}
					const groups = [i18n.t('custom'), ...Array.from(groupSet).sort((a, b) => a.localeCompare(b))];

					return {
						...firstCounter, // Keep everything just in case
						items,
						groups,
						task: eotpTask?.id ?? firstCounter.task ?? '',
						label: eotpTask?.label ?? '',
						activeGroup:
							groups.find((group) =>
								items.reduce((acc, item) => {
									if (!acc) return false;

									return item.groups.indexOf(group) !== -1 ? item.checked : !item.checked;
								}, true),
							) ?? i18n.t('custom'),
					} as PreparedCounterWithItems;
				}, countersGroupedByTask),
			).filter(Boolean) as PreparedCounterWithItems[];

			setPreparedCounters(groupedCounters);
		})().catch(console.error);
	});

	const toggleGroup = useCallback(
		(task: string, group: string): void => {
			if (group === i18n.t('custom')) return;

			const counter = preparedCounters.find((pc) => pc.task === task);
			if (!counter) return;

			for (const item of counter.items) {
				if (
					(item.groups.indexOf(group) !== -1 && item.checked) ||
					(item.groups.indexOf(group) === -1 && !item.checked)
				) {
					continue;
				}

				void item.toggle(task, { isChecked: item.checked });
			}
		},
		[preparedCounters],
	);

	const addEOTP = (taskId: string, taskName: string, preselectedGroup: string | null = null): void => {
		if (preparedCounters.some((pc) => pc.task === taskId)) return;

		setPreparedCounters((counters) => {
			const groupSet = new Set<string>();
			for (const item of cachedProdItems.current) {
				for (const group of item.groups) {
					groupSet.add(group);
				}
			}
			const newCounterWithoutItems = {
				_id: -1 * Math.random() * 1_000_000,
				tags: null,
				assignedTo: null,
				work: 'Production',
				comeFrom: null,
				quantity: 0,
				include: true,
				code: '-1',
				cost: null,
				name: '',
				activeGroup: i18n.t('custom'),
				task: taskId,
				label: taskName,
				SourceId: -1,
				groups: [i18n.t('custom'), ...Array.from(groupSet)],
			};

			const items = cachedProdItems.current.map((prodItem) => {
				let counter: PreparedCounterResponse | null = counters.find((c) => c.task === taskId) ?? null;

				async function toggle(task: string, { isChecked }: { isChecked: boolean }): Promise<void> {
					if (isChecked) {
						if (counter) {
							await uncheckPreparedCounterItem(client, counter);
						} else {
							// If this somehow happens, we create a counter that will result in
							// a 409 conflict response from the API so that we know the id of
							// the counter to delete.
							// I found no other way to know which counter we are working with
							// in that specific scenario.
							// Is this clean? No. But does this work? Yes.
							counter = await addPreparedCounterItem(client, {
								task,
								code: prodItem.id.toString(),
								SourceId: prodItem.sourceId,
								name: prodItem.label,
								label: eotpTasks.find(ta => ta.id === task)?.label ?? '',
								assignedTo: null,
								work: 'Production',
								cost: null,
								quantity: 0,
								include: true,
								tags: null,
								comeFrom: null,
							});
							await uncheckPreparedCounterItem(client, counter as PreparedCounterResponse);
							counter = null;
						}
					} else {
						if (counter) {
							console.error(`A newly added EOTP has a counter which should not be possible`, { counter, task });
							counter = await checkPreparedCounterItem(client, counter);
						} else {
							counter = await addPreparedCounterItem(client, {
								task,
								code: prodItem.id.toString(),
								SourceId: prodItem.sourceId,
								name: prodItem.label,
								label: eotpTasks.find(ta => ta.id === task)?.label ?? '',
								assignedTo: null,
								work: 'Production',
								cost: null,
								quantity: 0,
								include: true,
								tags: null,
								comeFrom: null,
							});
						}
					}

					setPreparedCounters((old) =>
						old.map((pc) => {
							if (pc.task !== task) return pc;

							const newItems = pc.items.map((item) =>
								item.id !== prodItem.id
									? item
									: {
											...item,
											toggle,
											counter: counter as PreparedCounterResponse,
											checked: !isChecked,
									  },
							);

							return {
								...pc,
								...counter,
								activeGroup:
									pc.groups.find((group) =>
										newItems.reduce((acc, item) => {
											if (!acc) return false;

											return item.groups.indexOf(group) !== -1 ? item.checked : !item.checked;
										}, true),
									) ?? i18n.t('custom'),
								items: newItems,
							};
						}),
					);
				}

				if (preselectedGroup && prodItem.groups.includes(preselectedGroup)) {
					toggle(taskId, { isChecked: false }).catch(console.error);
				}

				return {
					...prodItem,
					toggle,
					checked: false,
					counter: newCounterWithoutItems,
				};
			});

			return [
				...counters,
				{
					...newCounterWithoutItems,
					items,
				},
			];
		});
	};

	return { preparedCounters, toggleGroup, addEOTP };
}
