import React from 'react';

export type Props = {
	color?: string;
};

const Data: React.FC<Props> = ({ color }) => {
	return (
		<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.1431 16H15.1431V6H18.1431V16ZM13.1431 16H10.1431V3H13.1431V16ZM8.14307 16H5.14307V0H8.14307V16ZM3.14307 16H0.143066V8H3.14307V16Z"
				fill={color || 'black'}
			/>
		</svg>
	);
};

export default Data;
