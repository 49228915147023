import React, { useRef } from 'react';
import { ModalActions, ModalBody, ModalContent, ModalHeader } from './style';
import { useModal } from 'components/Modal';
import { useOnClickOutside } from 'usehooks-ts';
import Cross from 'assets/svg/Cross';
import i18n from 'i18next';

type ConfirmDeletionModalProps = {
	handleDeletion: () => unknown;
};

const ConfirmDeletionModal: React.FC<ConfirmDeletionModalProps> = ({ handleDeletion }) => {
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	useOnClickOutside(modalRef, pop);

	return (
		<ModalBody ref={modalRef}>
			<ModalHeader>
				<div onClick={pop}>
					<Cross />
				</div>
			</ModalHeader>
			<ModalContent>
				<p className="content">{i18n.t('confirm_report_deletion')}</p>
				<ModalActions>
					<button className="cancel" onClick={pop}>{i18n.t('confirm_negative')}</button>
					<button className="confirm" onClick={handleDeletion}>
						{i18n.t('confirm_affirmative')}
					</button>
				</ModalActions>
			</ModalContent>
		</ModalBody>
	);
};

export default React.memo(ConfirmDeletionModal);
