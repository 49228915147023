import React, { useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import * as R from 'ramda';

import { createCounter } from 'api/routes/counter';
import { CategoryType } from 'api/types/category';
import Button, { Type as ButtonType } from 'components/Button';
import Checkbox, { State } from 'components/Checkbox';
import { useModal } from 'components/Modal';
import Search, { SearchType } from 'components/Search';
import Switch from 'components/Switch';
import { Flex, Margin } from 'styles';
import { showError } from 'utils/error';
import i18n from 'utils/lang';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import { useSearch } from 'components/Search/useSearch';
import { useClient } from 'providers/client';
import { statefromCheckedValues } from '../Accordion';
import { ContentContainerType, FavType } from './Enums';
import {
	AddRessourceContainer,
	BoldSpan,
	ButtonContainer,
	ContentContainer,
	FavContainer,
	SelectionContainer,
	SourceContainer,
	SourcesListContainer,
	UnitTitle,
} from './style';
import { RefsSelectionProps, SelectableReferential, SelectableSource } from './type';
import useData from './useData';
type FavsProps = {
	fav: boolean;
	setFav: React.Dispatch<React.SetStateAction<boolean>>;
	type?: FavType;
};

export const Favs: React.FC<FavsProps> = ({ fav, setFav, type }) => {
	return (
		<FavContainer type={type}>
			<BoldSpan className="fs-14">{i18n.t('apply_fav')}</BoldSpan>
			<Margin left={24}>
				<Switch
					onColor="#4D9CBF"
					value={fav}
					onChange={() => {
						setFav(!fav);
					}}
				/>
			</Margin>
		</FavContainer>
	);
};

type FreeResourcesProps = {
	pushNewResource: (value: string) => () => void;
};

const FreeResources: React.FC<FreeResourcesProps> = ({ pushNewResource }) => {
	const [value, setValue] = useState<string>();

	return (
		<AddRessourceContainer flexDirection="row" justifyContent="start" alignItems="center">
			<BoldSpan className="fs-14">{i18n.t('add_free_line')}</BoldSpan>
			<Search
				value={value ?? ''}
				clearText={() => setValue('')}
				placeholder="name"
				onChange={(evt) => setValue(evt.target.value.substring(0, 31))}
				type={SearchType.AddFreeResource}
				onNewClicked={() => {
					if (value) {
						pushNewResource(value)();
						setValue(undefined);
					}
				}}
			/>
		</AddRessourceContainer>
	);
};

type InputUnitsProps = {
	sources: SelectableSource[];
	setSources: React.Dispatch<React.SetStateAction<SelectableSource[]>>;
};

const InputUnits: React.FC<InputUnitsProps> = ({ sources, setSources }) => {
	return (
		<ContentContainer flexDirection="column" type={ContentContainerType.InputUnits}>
			<Flex margin="8px" flexDirection='column' gap="8px">
				<UnitTitle>{i18n.t('input_units')}</UnitTitle>
				<SourcesListContainer flexDirection="row">
					{sources &&
						sources.map((e, idx) => (
							<SourceContainer right={24} key={`report-modal-unit-${e._id}-${idx}`}>
								<Flex flexDirection="row" alignItems="center">
									<Checkbox
										state={e.isSelected === true ? State.Checked : State.Unchecked}
										onClick={() => {
											setSources(sources.map((s) => (s._id === e._id ? { ...s, isSelected: !e.isSelected } : s)));
										}}
									/>
									<Margin left={16}>
										<BoldSpan>
											{e.label} ({e.unit})
										</BoldSpan>
									</Margin>
								</Flex>
							</SourceContainer>
						))}
				</SourcesListContainer>
			</Flex>
		</ContentContainer>
	);
};

const RefItem: React.FC<{
	referential: SelectableReferential; onClick: () => unknown
}> = ({
	referential,
	onClick,
}) => {
		return (
			<Flex className="report-modal" flexDirection="row">
				<Checkbox state={referential.isSelected === true ? State.Checked : State.Unchecked} onClick={onClick} />
				<Margin left={8} centerContent>
					<BoldSpan>{referential.title}</BoldSpan>
				</Margin>
			</Flex>
		);
	};



export const RefsSelection: React.FC<RefsSelectionProps> = ({ refs, setRefs, filteredRefs }) => {
	const displayedRefs = useMemo(() => {
		return R.sortWith(
			[R.descend(R.prop<boolean>('isSelected')), R.ascend(R.prop<string>('label'))],
			filteredRefs ?? refs,
		);
	}, [refs, filteredRefs]);

	const state = statefromCheckedValues(displayedRefs.map((ref) => ref.isSelected ?? false));
	const checkAllChild = (): void => {
		const allChecked: boolean = state === State.Checked;

		setRefs(refs.map((e) => (displayedRefs.some((r) => r.id === e.id) ? { ...e, isSelected: !allChecked } : e)));
	};
	return (
		<ContentContainer flexDirection="column">
			<Margin bottom={12} top={8} left={8} right={8}>
				<Flex flexDirection="row">
					<Checkbox state={state} onClick={checkAllChild} />
					<Margin left={8} centerContent>
						<BoldSpan className="fs-14">{i18n.t('select_all')}</BoldSpan>
					</Margin>
				</Flex>
			</Margin>
			<SelectionContainer flexDirection="column">
				{displayedRefs.map((referential, idx) => (
					<RefItem
						key={`report-modal-selection-${referential.id}-${idx}`}
						referential={referential}
						onClick={() =>
							setRefs(refs.map((r) => (r.id === referential.id ? { ...r, isSelected: !r.isSelected } : r)))
						}
					/>
				))}
			</SelectionContainer>
		</ContentContainer >
	);
};

type Props = {
	title: string;
	category?: CategoryType;
	date: string;
	refresh: boolean;
	setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
	createCounterFn: typeof createCounter;
};

const Modal: React.FC<Props> = ({ category, date, refresh, setRefresh, createCounterFn }) => {
	const { clientHandler: { client } } = useClient();
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	useOnClickOutside(modalRef, pop);

	const { fav, setFav, refs, setRefs, sources, setSources } = useData(true, category);

	const create = (): void => {
		const selectedSources = sources.filter((e) => e.isSelected === true);
		const selectedRefs = refs.filter((e) => e.isSelected === true);

		Promise.all(
			selectedSources.map((src) =>
				selectedRefs.map((ref) =>
					createCounterFn(client, {
						code: ref.id,
						name: ref.label,
						CategoryId: src.CategoryId.toString(),
						SourceId: src._id.toString(),
						dateCounter: date,
						include: true,
						task: null,
						quantity: 0,
					}),
				),
			),
		).catch(showError('Failed to create counters'));
		setTimeout(() => {
			setRefresh(!refresh);
		}, 500);
		pop();
	};

	const pushNewResource = (value: string) => () => {
		setRefs([{ id: value, title: value, checkable: true, label: null, isSelected: true, isFromFreeResource: true }, ...refs]);
	};

	const {
		searchValue,
		handleSearchChange,
		setSearchValue,
		filteredItems: filteredRefs,
	} = useSearch<SelectableReferential>(refs, ['id', 'label', 'title'], true);

	const isDisabled = useMemo(
		() => !sources.some((s) => s.isSelected) || !refs.some((r) => r.isSelected),
		[sources, refs],
	);

	const selectedAndFilteredRefs = useMemo(() => {
		const selectedRefs = refs.filter((ref) => ref.isSelected);
		return R.uniq([...filteredRefs, ...selectedRefs]);
	}, [refs, filteredRefs]);

	return (
		<ModalContainer ref={modalRef}>
			<ModalHeader title={i18n.t('add_lines')} pop={pop} />
			<Flex flexDirection='row' justifyContent='space-between' gap="24px">
				<Search type={SearchType.ResourceModal} value={searchValue} onChange={handleSearchChange} clearText={() => setSearchValue('')} />
				<Favs fav={fav} setFav={setFav} type={FavType.ResourceModal} />
				<FreeResources pushNewResource={pushNewResource} />
			</Flex>
			<InputUnits sources={sources} setSources={setSources} />
			<RefsSelection refs={refs} filteredRefs={selectedAndFilteredRefs} setRefs={setRefs} />
			<ButtonContainer>
				<Button disabled={isDisabled} type={ButtonType.modalValidator} onClick={create}>
					{i18n.t('validate')}
				</Button>
			</ButtonContainer>
		</ModalContainer>
	);
};

export default Modal;
