import React, { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { TailSpin } from 'react-loader-spinner';

import BouyguesConstructionLogo from 'assets/svg/BouyguesConstructionLogo';
import { getPendingRequests } from 'utils/pendingRequests';

import Container from './style';

const Footer: React.FC = () => {
	const [pendingRequests, setPendingRequests] = useState(0);

	useInterval(() => {
		const currentlyPendingRequests = getPendingRequests();
		if (currentlyPendingRequests !== pendingRequests) {
			setPendingRequests(currentlyPendingRequests);
		}
	}, 100);

	return (
		<Container>
			<BouyguesConstructionLogo />
			<TailSpin height="2.5em" width="2.5em" color="#ffffff" radius="1" visible={pendingRequests > 0} />
		</Container>
	);
};

export default Footer;
