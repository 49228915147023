import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { ReportStatus, ReportType } from '../api/types/report';
import { reportResponseToReportType } from '../utils/common';
import { useUser } from './user';
import { reportDelete, reportList, reportUpdate } from '../api/routes/report';
import { showError } from '../utils/error';
import { ReportListPayload } from 'api/types';
import { useClient } from './client';
import { format } from 'date-fns';

type CurrentReportContextType = {
	reports: ReportType[];
	currentReport: ReportType | null;
	canEdit: boolean;
	setCurrentReport: (r: ReportType) => void;
	deleteCurrentReport: () => void;
	updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>;
	refreshAllReports: (d: ReportListPayload) => void;
	fetchNewReports: (d: ReportListPayload) => Promise<ReportType[]>;
	loading: boolean;
};

const CurrentReportContext = createContext<CurrentReportContextType>({
	reports: [],
	currentReport: null,
	canEdit: false,
	setCurrentReport: () => {},
	deleteCurrentReport: () => {},
	updateReport: async () => {},
	refreshAllReports: () => { },
	fetchNewReports: async () => Promise.all([]),
	loading: true,
});

export const ReportsProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { clientHandler: { client } } = useClient();
	const [loading, setLoading] = useState(true);
	const [reports, setReports] = useState<ReportType[]>([]);

	const refreshAllReports = useCallback(({ startDate, endDate, createdBy }: ReportListPayload) => {
		(async () => {
			setLoading(true);
			const reportResponses = await reportList(client, { startDate: new Date(format(startDate, 'yyyy-MM-dd')), endDate: new Date(format(endDate, 'yyyy-MM-dd')), createdBy });
			setReports(
				reportResponses.map((reportResponse) => reportResponseToReportType(reportResponse) as ReportType) || [],
			);
			setLoading(false);
		})().catch(() => {
			showError(`Failed to refresh reports`);
			setLoading(false);
		});
	}, []);

	const fetchNewReports = async ({ startDate, endDate, createdBy }: ReportListPayload): Promise<ReportType[]> => {
		const reportResponses = await reportList(client, { startDate, endDate, createdBy });

		return reportResponses.map((reportResponse) => reportResponseToReportType(reportResponse) as ReportType) || [];
	}


	const user = useUser();
	const [currentReport, setCurrentReport] = useState<ReportType | null>(null);

	const updateCurrentReport = (report: ReportType): void => {
		setCurrentReport(report);
		setReports((baseReports) => baseReports.map((r) => (r._id !== report._id ? r : report)));
	};

	const deleteCurrentReport = (): void => {
		if (!currentReport?._id) return;

		(async () => {
			await reportDelete(client, currentReport?._id?.toString() ?? '');
			setReports((baseReports) => baseReports.filter((r) => r._id !== currentReport?._id));
			setCurrentReport(null);
		})().catch(showError(`Error while deleting report`));
	};

	const canEdit =
		(currentReport?.status === ReportStatus.Validated && (user?.isChecker ?? false)) ||
		(currentReport?.status === ReportStatus.Invalidated && (user?.isWriter ?? false));

	async function updateReport<K extends keyof ReportType>(key: keyof ReportType, newValue: ReportType[K]): Promise<void> {
		if (!currentReport || !canEdit) return;
		const newData: ReportType = {
			...currentReport,
			[key]: newValue,
		};

		await reportUpdate(client, newData);
		updateCurrentReport(newData);
	}

	return (
		<CurrentReportContext.Provider
			value={{
				currentReport,
				reports,
				canEdit,
				setCurrentReport: updateCurrentReport,
				deleteCurrentReport,
				updateReport,
				refreshAllReports,
				fetchNewReports,
				loading,
			}}
		>
			{children}
		</CurrentReportContext.Provider>
	);
};

export const useReports = (): CurrentReportContextType => useContext(CurrentReportContext);
