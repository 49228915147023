import styled from 'styled-components';
import { Flex } from 'styles';

const Container = styled.section`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;
`;

export const Title = styled.div`
	margin-bottom: 4px;
`;

type TextAreaProps = {
	isModal?: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
	resize: none;
	overflow-y: hidden;

	height: 100%;

	width: ${({ isModal }) => isModal ? "100%" : "95%"};
	min-width: 150px;
	min-height: 40px;

	border-radius: 6px;

	color: #00000080;
	background: #ffffff;

	font-weight: bold;

	cursor: text;

	&[disabled] {
		cursor: not-allowed;
	}
`;

export const TextAreaContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	background-color: white;
	border-radius: 6px;
	padding: 0.75em;
`;

export const ViewAll = styled(Flex)`
	cursor: pointer;
	color: #25465F;
	font-weight: bold;
	padding-left: 5px;
	width: 50px;
`

export const TitleCommentType = styled.span`
	font-size: large;
	font-weight: bold;
	height: 30px;
	width: 200px;
`;

export const SelectCommentType = styled.select`
	border-radius: 5px;
	font-size: large;
	font-weight: bold;
	width: 200px;
	background-color: #fff;
	height: 30px;
`

export const CommentTypeContainer = styled(Flex)`
	min-height: 100px;
`;

export const CommentTypeListContainer = styled(Flex)`
	overflow-y: auto;
`;

export const TaskTextAreaContainer = styled(TextAreaContainer)`
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	width: 100%;
	margin: 10px;
`

export default Container;
