import { ThemeSelection } from 'providers/preferences';
import styled from 'styled-components';

import { Flex } from 'styles';

export const PageContainer = styled.div`
	padding: 32px 24px 32px 24px;
	display: flex;
	flex-direction: row;
	justify-content: start;
	height: 100%;
	overflow: hidden;
	gap: 20px;

	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
`;

export const LeftOptionPanel = styled(Flex)`
	width: 11%;
	min-width: 250px;
`;

const Container = styled(Flex)`
	width: 100%;
`;

export const Bold = styled.span`
	font-weight: bold;
`;

export const MainTitle = styled(Bold)`
	font-size: 28px;
`;

export const Title = styled(Bold)`
	font-size: 20px;
`;

type SpanThemeButtonProps = {
	color: string;
};

export const SpanThemeButton = styled(Bold) <SpanThemeButtonProps>`
	color: ${({ color }) => color};
`;

export const LanguageInput = styled.select`
	padding: 8px 5px;
	background: #ffffff;
	border-radius: 6px;
	width: 100%;
	text-align: center;
`;

export const EOTPsContainer = styled(Flex)`
	gap: 20px;
	width: 100%;
	height: 100%;
`;

export const EOTPsRowContainer = styled(Flex)`
	gap: 20px;
	height: 50%;
`

type CardProps = {
	alignItems?: string;
};

export const Card = styled(Flex) <CardProps>`
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
	gap: 16px;
	flex-direction: column;
	align-items: ${({ alignItems }) => alignItems ?? 'center'};
	overflow-y: hidden;
	padding: 20px;
	width: 100%;
`;

const ThemeButton = styled.button`
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	gap: 8px;
	padding: 12px;
	align-items: center;
	width: 100%;
	justify-content: center;
`;

type ThemeButtonProps = {
	theme: ThemeSelection
}

export const LightThemeButton = styled(ThemeButton) <ThemeButtonProps>`
	background-color: ${({ theme }) => theme === ThemeSelection.Light ? '#4D9CBF' : '#FFFFFF'};
	cursor: ${({ theme }) => theme === ThemeSelection.Light ? 'default' : 'pointer'};
`

export const DarkThemeButton = styled(ThemeButton) <ThemeButtonProps>`
	background-color: ${({ theme }) => theme === ThemeSelection.Dark ? '#25465F' : '#FFFFFF'};
	cursor: ${({ theme }) => theme === ThemeSelection.Dark ? 'default' : 'pointer'};
`

export default Container;
