import React from 'react';

type Props = {
	color?: string;
};

const ExportChart: React.FC<Props> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path
			fill={color ?? '#000'}
			d="M21 21H4a1 1 0 0 1-1-1V3h2v16h16v2ZM8.373 16 7 14.656 11.856 9.9a.985.985 0 0 1 1.373 0l2.227 2.181L19.627 8 21 9.344 16.144 14.1a.985.985 0 0 1-1.373 0l-2.228-2.182L8.374 16h-.001Z"
		/>
	</svg>
);

export default React.memo(ExportChart);
