import React from 'react';

type Props = {
	outlined?: boolean;
	color?: string;
};

const StatusDoubleCheck: React.FC<Props> = ({ outlined, color }) => {
	if (!!outlined) {
		return (
			<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M21.54 8.99L20.61 8.06V8.05L15.11 13.55L14.58 14.08L14.05 13.55L13.21 12.71L12.29 13.64L14.59 15.94L21.54 8.99ZM14.58 13.02L20.6 7L22.59 8.99L14.58 17L11.22 13.64L13.21 11.65L14.58 13.02ZM13.66 8.99001L12.73 8.06001L7.24 13.55L6.71 14.08L6.18 13.55L3.49 10.86L2.56 11.79L6.71 15.94L13.66 8.99001ZM6.7 13.03L12.73 7.00001L14.72 8.99001L6.71 17.01L1.5 11.8L3.49 9.81001L6.7 13.03Z"
					stroke={color ?? '#FFF'}
				/>
			</svg>
		);
	}

	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.16508 17L1.95508 11.79L3.94508 9.8L7.16508 13.02L13.1851 7L15.1751 8.99L7.16508 17Z"
				fill={color ?? '#FFF'}
			/>
			<path
				d="M15.0351 17L11.6751 13.64L13.6651 11.65L15.0351 13.02L21.0651 7L23.0551 8.99L15.0451 17H15.0351Z"
				fill={color ?? '#FFF'}
			/>
		</svg>
	);
};

export default StatusDoubleCheck;
