import React from 'react';

export type Props = {
	color?: string;
};

const PartialChecked: React.FC<Props> = ({ color }) => {
	return (
		<svg version="1.1" id="PartialCheckedSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
			<circle cx="12.5" cy="12.5" r="6" fill={color ?? 'black'} />
		</svg>
	);
};

export default PartialChecked;
