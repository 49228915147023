import React, { useContext } from 'react';

import { ReportDataTasksContext } from 'widgets/Report/provider/counters/tasks';
import { useSearchAndFilterResources } from 'widgets/Report/provider/counters/resources/searchAndFilter';
import { TaskCommentProvider } from 'widgets/ReportHeader/components/Comment/taskProvider';
import { CategoryType } from 'api/types/category';
import { FeaturesFlag, hasFlags } from 'api/utils';

import Accordion from '../Accordion';
import AccordionTask from '../Accordion/Task';
import { useSearchAndFilter } from '../../provider/counters/sources/searchAndFilter';
import useData from '../Modal/useData';
import AccordionResources from '../Accordion/Resources';
import Container from './style';

type Props = {
	category: CategoryType;
};

export const ResourceContent: React.FC<Props> = ({ category }) => {
	const { filteredResources } = useSearchAndFilterResources();

	return (
		<Container>
			{filteredResources.map((resource, idx) => 
				<AccordionResources key={`AccordionResources-${idx}-${resource.name}`} category={category} resource={resource} />
			)}
		</Container>
	)
}

export const TaskContent: React.FC<Props> = ({ category }) => {
	const { tasks } = useContext(ReportDataTasksContext);

	return (
		<Container>
			<TaskCommentProvider>
			{tasks.map((task, idx) => (
				<AccordionTask key={`TaskContent-Accordion-${idx}-${task.task ?? ''}`} category={category} task={task} />
			))}
			</TaskCommentProvider>
		</Container>
	);
};

const Content: React.FC<{ category?: CategoryType }> = ({ category }) => {
	const { sources } = useData(false, category);
	const { filteredSources } = useSearchAndFilter();
	const showEOTPs = !!category && hasFlags(category.features, FeaturesFlag.AddEOTP);

	return (
		<Container data-type="Content">
			{filteredSources.map((source, idx) => {
				const sourcesWithSameUnit = sources.filter((s) => s.unit === source.unit && s._id !== source._id);

				return (
					<Accordion
						key={`Content-Accordion-${idx}-${source._id}`}
						source={source}
						sourcesWithSameUnit={sourcesWithSameUnit}
						showEOTPs={showEOTPs}
					/>
				);
			})}
		</Container>
	);
};

export default Content;
